import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import cat_breed from '../../../../static/cat_breed.svg';
import dog_breed from '../../../../static/dog_breed.svg';
import theme, { blue } from '../../../../theme';
import ButtonCustom from '../../../atoms/Button';
import Input from '../animalBreed/Input';
import { DOG } from '../constants';
import { selectAnimals } from '../pricingSlice';

const useStyles = makeStyles((theme) => ({
  img: {
    marginTop: 15,
    maxHeight: 70,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 60,
    },
  },
  typo1: {
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
      color: blue,
    },
  },
  typo2: {
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
      color: blue,
    },
  },
  paper: {
    background: 'inherit',
    width: 150,
    height: 150,
    [theme.breakpoints.down('lg')]: {
      width: 140,
      height: 140,
    },
    [theme.breakpoints.down('md')]: {
      width: 130,
      height: 130,
    },
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 120,
    },
  },
  select: {
    color: theme.palette.primary.main,
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
    '&:before': {
      borderColor: theme.palette.primary.main,
    },
    '&:after': {
      borderColor: theme.palette.primary.main,
    },
  },
  firstInput: {
    marginTop: 20,
  },
  secondInput: {
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      marginTop: 35,
    },
  },
  animalImg: {
    maxWidth: '230px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '280px',
    },
  },
  tooltip: {
    fontSize: '1.1em',
  },
}));

export default function SingleAnimal({
  handleNextStep,
  isLoading,
  breedList,
}) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalBreed',
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const { animals } = useSelector((state) => state.pricing);
  const animal = _.first(animals) || {};

  const setBreedId = (breedId) => {
    dispatch(selectAnimals([{ ...animal, breed: breedId }]));
  };

  const onChange = (value) => {
    setBreedId(value);
    setTimeout(() => {
      if (window.innerWidth < 768)
        window.scrollTo({ top: 1500, behavior: 'smooth' });
    }, 500);
  };

  const onSubmit = (newAnimal = animal) => {
    handleNextStep([newAnimal]);
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <img
          alt="svg"
          src={animal.species === DOG ? dog_breed : cat_breed}
          className={classes.animalImg}
        />
        <Input
          classes={classes}
          breedList={breedList}
          breed={animal.breed}
          species={animal.species}
          onChange={onChange}
          onCrossClick={(breedId) =>
            onSubmit({ ...animal, breed: breedId })
          }
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          marginBottom: '20px',
          marginTop: { xs: '30px', md: '50px' },
        }}
      >
        <ButtonCustom
          onClick={() => onSubmit()}
          width={122}
          height={47}
          disabled={!animal?.breed}
        >
          {!isLoading ? (
            t('nextButton')
          ) : (
            <CircularProgress
              sx={{
                color: theme.palette.secondary.main,
                display: 'block',
              }}
              thickness={5.0}
              size="25px"
            />
          )}
        </ButtonCustom>
      </Grid>
    </>
  );
}
