import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { displayErrorMessage } from '../../../providers/snackbar';
import { getErrorTranslation } from '../../../utils/language';
import axiosAPI from '../../axiosApi';

const initialState = {
  status_docs: 'idle',
  contract: 'idle',
  url: null,
};

export const validatePayment = createAsyncThunk(
  'signing/validate',
  async (data) => {
    try {
      const response = await axiosAPI.post('validatePayment/', data);
      return response.data;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const updateQuote = createAsyncThunk(
  'signing/updateQuote',
  async (data) => {
    try {
      const response = await axiosAPI.post('updateQuote/', data);
      return response.data;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const signingSlice = createSlice({
  name: 'signing',
  initialState,
  reducers: {
    reset: (state, action) => {
      state.status_docs = action.payload;
      state.contract = action.payload;
    },
  },
  extraReducers: {
    [updateQuote.pending]: (state) => {
      state.status_docs = 'loading';
    },
    [updateQuote.rejected]: (state) => {
      state.status_docs = 'failed';
    },
    [updateQuote.fulfilled]: (state, { payload }) => {
      if (payload === 'already') {
        state.status_docs = 'already';
      } else {
        state.status_docs = 'succeeded';
      }
    },
    [validatePayment.pending]: (state) => {
      state.contract = 'loading';
    },
    [validatePayment.rejected]: (state) => {
      state.contract = 'failed';
    },
    [validatePayment.fulfilled]: (state, { payload }) => {
      state.contract = 'succeeded';
    },
  },
});

export const { reset } = signingSlice.actions;

export default signingSlice.reducer;
