import { Box, Button, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'use-hooks';

import Footer from '../components/templates/home/Footer';
import Navbar from '../components/templates/home/Navbar';
import logoEleveursOnline from '../static/logo-eleveurs-online.png';
import { blue_light } from '../theme';
import theme from '../theme/home';
import { makeStaticPageStyles } from './styles';

const refSize = 1920;
const minZoomMobile = 400;
const minZoomDesktop = 1000;

const useStyles = makeStyles((theme) => makeStaticPageStyles(theme));

const PartnerButton = ({ onClick }) => (
  <Button style={{ textTransform: 'none' }} onClick={onClick}>
    <img
      src={logoEleveursOnline}
      alt="eleveurs-online"
      style={{
        width: '80%',
        height: '80%',
        cursor: 'pointer',
      }}
    />
  </Button>
);

export default function PartnersLayout(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'layouts.partnersLayout',
  });
  const { width } = useWindowSize();
  const zoom =
    width < minZoomDesktop
      ? 1 + (width - minZoomMobile) / 900
      : width / refSize;
  const classes = useStyles({ zoom });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Navbar {...props} />
      <Box className={classes.zoomHome}>
        <section
          style={{
            background: blue_light,
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              paddingTop: '40px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography color="primary" variant="h2">
              {t('title')}
            </Typography>
          </div>
          <section className={classes.htmlContent}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6}>
                <PartnerButton
                  onClick={() => {
                    window.open(
                      'https://www.eleveurs-online.com',
                      'eleveurs-online',
                      'noopener',
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.typo}
                  style={{ marginTop: 30 }}
                >
                  {t('subtitle')}
                </Typography>
              </Grid>
            </Grid>
          </section>
          <div style={{ width: '100%', paddingBottom: '50px' }}>
            <Footer />
          </div>
        </section>
      </Box>
    </ThemeProvider>
  );
}
