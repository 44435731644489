const refSize = 1920;
const minZoomMobile = 400;
const minZoomDesktop = 1000;
const midZoomDesktop = 1500;
const maxZoomDesktop = 2100;
const zoomDesktop = midZoomDesktop / refSize;

export const makeStaticPageStyles = (theme) => ({
  zoomHome: {
    height: '100%',
    [theme.breakpoints.between(minZoomMobile, minZoomDesktop)]: {
      zoom: (props) => props.zoom,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': (props) => `scale(${props.zoom})`,
        '-moz-transform-origin': '0 0',
        width: (props) => `${100 * (1 / props.zoom)}vw`,
      },
    },
    [theme.breakpoints.between(minZoomDesktop, midZoomDesktop)]: {
      zoom: (props) => props.zoom,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': (props) => `scale(${props.zoom})`,
        '-moz-transform-origin': '0 0',
        width: (props) => `${100 * (1 / props.zoom)}vw`,
      },
    },
    [theme.breakpoints.between(midZoomDesktop, maxZoomDesktop)]: {
      zoom: zoomDesktop,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': `scale(${zoomDesktop})`,
        '-moz-transform-origin': '0 0',
        width: `${100 * (1 / zoomDesktop)}vw`,
      },
    },
  },
  htmlContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '1500px',
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '150px',
    marginRight: '150px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '50px',
      marginRight: '50px',
    },
  },
  typo: {
    zIndex: 100,
    fontSize: 24,
    color: theme.palette.primary.main,
    zoom: 1,
    [theme.breakpoints.down('md')]: {
      zoom: 0.5,
    },
  },
});
