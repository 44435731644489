import { Alert, Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import pricing_asset_mobile_5 from '../../../../static/pricing/pricing-asset-mobile-5.svg';
import theme from '../../../../theme';
import { getErrorTranslation } from '../../../../utils/language';
import ButtonCustom from '../../../atoms/Button';
import axiosAPI from '../../../axiosApi';
import { GUARD, MULTIPLE } from '../constants';
import { selectManualAdress } from '../pricingSlice';
import MapGoogle from './MapGoogle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: 80,
    backgroundColor: '#6A92A8',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
      backgroundColor: 'inherit',
    },
  },
  section: {
    width: '100%',
    height: '100%',
    backgroundColor: '#6A92A8',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'inherit',
    },
  },
  title_text: {
    fontSize: '2.1rem',
    color: 'white',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: theme.palette.primary.main,
    },
  },
  title_container: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  address: {
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      height: '320px',
      backgroundImage: `url(${pricing_asset_mobile_5})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginTop: 30,
      paddingTop: 50,
    },
  },
}));

export default function Pricing({
  handleNext,
  step,
  isLoading,
  setIsLoading,
}) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.address',
  });

  const classes = useStyles();
  const id = useSelector((state) => state.pricing.formId);
  const firstName = useSelector((state) => state.pricing.firstName);
  const animalType = useSelector((state) => state.pricing.animalType);
  const animals = useSelector((state) => state.pricing.animals);
  const address = useSelector((state) => state.pricing.address);
  const [error, setError] = useState(null);
  const [next, setNext] = useState(false);
  const [isManualAddress, setIsManualAddress] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (next) {
      setTimeout(() => {
        if (window.innerWidth < 768)
          window.scrollTo({ top: 1500, behavior: 'smooth' });
      }, 500);
    }
  }, [next]);
  const onSubmit = () => {
    setIsLoading(true);
    setError(null);
    axiosAPI
      .post('checkPostalCode/', {
        address,
        isManualAddress,
      })
      .then(() => {
        setIsLoading(false);
        handleNext({ step, id, address, isManualAddress });
      })
      .catch((error) => {
        setError(getErrorTranslation(error?.response?.data));
        setIsLoading(false);
      });
  };

  const handleChangeManualAddress = (e) => {
    setIsManualAddress(e.target.checked);
    dispatch(selectManualAdress(e.target.checked));
  };

  return (
    <section className={classes.section}>
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12} className={classes.title_container}>
          <Typography
            variant={'h4'}
            className={classes.title_text}
            dangerouslySetInnerHTML={{
              __html: `${
                (animalType === MULTIPLE || animalType === GUARD) &&
                animals?.length > 1
                  ? t('multiple.title1')
                  : t('title1', { firstName })
              }
                                    <br/>
                                ${
                                  (animalType === MULTIPLE ||
                                    animalType === GUARD) &&
                                  animals?.length > 1
                                    ? t('multiple.title2', {
                                        firstName,
                                      })
                                    : t('title2')
                                }`,
            }}
          ></Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.address}>
            <MapGoogle
              setNext={setNext}
              isManualAddress={isManualAddress}
              t={t}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.address}>
          <Typography
            color={'secondary'}
            sx={{
              marginRight: { xs: '5px', ss: '16px' },
              fontSize: '15.7px',
            }}
          >
            {t('addressNotFound')}
          </Typography>
          <Checkbox
            checked={isManualAddress}
            onChange={handleChangeManualAddress}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginBottom: '20px' }}
          sx={{ marginTop: { xs: '30px', md: '50px' } }}
        >
          {error !== null && (
            <Alert
              severity="error"
              sx={{ mb: { xs: '30px', md: '50px' } }}
            >
              {error}
            </Alert>
          )}
          <ButtonCustom
            disabled={!next}
            width={122}
            height={47}
            onClick={() => onSubmit()}
          >
            {!isLoading ? (
              t('nextButton')
            ) : (
              <CircularProgress
                sx={{
                  color: theme.palette.secondary.main,
                  display: 'block',
                }}
                thickness={5.0}
                size="25px"
              />
            )}
          </ButtonCustom>
        </Grid>
      </Grid>
    </section>
  );
}
