export const DOG = 'dog';
export const CAT = 'cat';
export const MULTIPLE = 'multiple';
export const GUARD = 'guard';

export const GUARD_TYPE_PREVOYANCE = 'guard';
export const GUARD_TYPE_PREVENTION = 'prev';
export const MALE = 'male';
export const FEMALE = 'female';

export const PURE = 'pure';
export const CROSS = 'cross';

export const CROSSED_BREED = 'Croisé';
export const CROSSED_MASTIFF_BREED = 'Croisé de type molossoïde';

export const MIN_ANIMAL_BIRTHDAY_MONTHS = 3;
export const MAX_CAT_BIRTHDAY_MONTHS = 119;
export const MAX_DOG_BIRTHDAY_1_MONTHS = 71;
export const MAX_DOG_BIRTHDAY_2_MONTHS = 83;
export const MAX_DOG_BIRTHDAY_3_MONTHS = 95;

export const DOGS_BREEDS_1 = [
  'American Bully',
  "Berger d'Asie Centrale",
  'Bouledogue Américain',
  'Bouledogue Francais',
  'Bouvier Bernois',
  'Boxer',
  'Bullmastiff',
  'Cane Corso',
  'Carlin',
  'Dobermann',
  'Dogue Allemand',
  'English Bulldog',
  'lévrier Irlandais',
  'Rottweiler',
  'Shar pei',
  'ShiTzu',
];

export const DOGS_BREEDS_2 = [
  'American Pit Bull Terrier',
  'Basset hound',
  "Berger d'Anatolie",
  "Berger de l'Atlas",
  'Boerbull',
  'Boston Terrier',
  'Bulldog',
  'Bull Terrier',
  'Bull Terrier miniature',
  'Chien de sang',
  'Continental Bulldog',
  'Dogo Canario',
  'Dogue de Bordeaux',
  'Dogue du Tibet',
  'Fila de Sao Miguel',
  'Leonberg',
  'Mastiff',
  'Mâtin de Naples',
  'Mâtin Espagnol',
  'Petit chien Russe',
  'Plott hound',
  'Saint-Bernard',
  'Saint-Hubert',
  'Sarplaninac',
  'Schnauzer géant',
  'Terre Neuve',
  'Tosa',
];

export const DOGS_BREEDS_3 = [
  'Affenpinscher',
  'Airedale',
  'Akita Inu',
  'Alaskan Malamute',
  'Altdeutscher Schaferhunde',
  'American Hairless Terrier',
  'American Staffordshire Terrier',
  'Anglo-Français de Petite Vènerie',
  'Anglo-Français Tricolore',
  'Anglo-Russe gontjaja',
  'Ariégeois',
  'Azawakh',
  'Bangkaew de Thaïlande',
  'Barbet',
  'Barbu tchèque',
  'Barzoï',
  'Basenji',
  'Basset Artésien Normand',
  'Basset Bleu de Gascogne',
  'Basset de Westphalie',
  'Basset des Alpes',
  'Basset Fauve de Bretagne',
  'Basset Suédois',
  'Beagle',
  'Beagle-harrier',
  'Beauceron',
  'Bedlington terrier',
  'Bergamasque',
  'Berger Allemand',
  'Berger Américain miniature',
  'Berger Australien',
  'Berger Belge Groenendal',
  'Berger Belge Laekenois',
  'Berger Belge Malinois',
  'Berger Belge Tervueren',
  'Berger Blanc Suisse',
  'Berger Créole',
  'Berger Croate',
  'Berger de Bohème',
  'Berger de Bosnie-Herzegovine et de Croatie',
  'Berger de Crau',
  'Berger de Maremme et Abruzzes',
  'Berger de Picardie',
  'Berger de Russie Méridionale',
  'Berger de Savoie',
  'Berger des Pyrénées',
  'Berger des Pyrénées, Korth',
  'Berger du Caucase',
  'Berger du Karst',
  'Berger Finnois de Laponie',
  'Berger Hollandais',
  'Berger Islandais',
  'Berger Polonais',
  'Bichon à poil frisé',
  'Bichon Havanais',
  'Bichon Maltais',
  'Billy',
  'Bodeguero',
  'Bolognese',
  'Border collie',
  'Border-Terrier',
  'Botte estonienne',
  'Bouvier Australien',
  "Bouvier de l'Appenzell",
  "Bouvier de L'Entlebuch",
  'Bouvier des Ardennes',
  'Bouvier des Flandres',
  'Brachet Allemand',
  'Brachet de Styrie à poil dur',
  'Brachet noir et feu',
  'Brachet Polonais',
  'Brachet Tyrolien',
  'Braque Allemand',
  "Braque d'Auvergne",
  'Braque de Burgos',
  "Braque de l'Ariège",
  'Braque de Weimar',
  'Braque du Bourbonnais',
  'Braque Dupuy',
  'Braque Français, type Gascogne',
  'Braque Français, type Pyrénées',
  'Braque Hongrois à poil court',
  'Braque Hongrois à poil dur',
  'Braque Italien',
  'Braque Saint-Germain',
  'Braque Slovaque à poil dur',
  'Briard',
  'Briquet de Provence',
  'Briquet Griffon Vendéen',
  'Broholmer',
  'Bruno du Jura',
  'Buhund Norvégien',
  'Ca de Bou',
  'Cairn Terrier',
  'Caniche',
  'Cao da serra de aires',
  'Cavalier King Charles',
  'Cavapoo',
  'Chart Polski',
  'Chien Chinois',
  'Chien courant de Bosnie',
  'Chien courant de Halden',
  'Chien courant de Hamilton',
  'Chien courant de Hygen',
  'Chien courant de la vallée de la Save',
  'Chien courant de montagne du Montenegro',
  'Chien courant de Schiller',
  'Chien courant de Schwyz',
  'Chien courant de Transylvanie',
  "Chien courant d'istrie a poil dur",
  "Chien courant d'Istrie a poil ras",
  'Chien courant du Smaland',
  'Chien courant Espagnol',
  'Chien courant Finlandais',
  'Chien courant Grec',
  'Chien courant Italien',
  'Chien courant Italien à poil ras',
  'Chien courant Norvégien',
  'Chien courant Polonais',
  'Chien courant Serbe',
  'Chien courant Slovaque',
  'Chien courant tricolore Serbe',
  "Chien d'arrêt Allemand à poil dur",
  "Chien d'arrêt Allemand à poil raide",
  "Chien d'arrêt Danois Ancestral",
  "Chien d'arrêt Portugais",
  "Chien d'Artois",
  'Chien de berger',
  'Chien de berger Anglais Ancestral',
  'Chien de berger Catalan',
  'Chien de berger de Majorque',
  'Chien de berger des Shetland',
  'Chien de berger des Tatras',
  'Chien de berger Roumain de Mioritza',
  'Chien de berger Roumain des Carpathes',
  'Chien de Canaan',
  'Chien de Castro Laboreiro',
  'Chien de ferme Dano-Suédois',
  'Chien de garenne Portugais',
  'Chien de la Serra da Estrela',
  'Chien de perdrix de Drente',
  'Chien de recherche au sang de la montagne Bavaroise',
  'Chien de réserve Australien / Kelpi de travail',
  'Chien de rouge du Hanovre',
  'Chien de Taïwan',
  "Chien d'eau Américain",
  "Chien d'eau Espagnol",
  "Chien d'eau frison",
  "Chien d'eau Portugais",
  "Chien d'élan Norvégien gris",
  "Chien d'élan Suédois",
  'Chien des Canaries',
  'Chien des pharaons',
  "Chien d'ours de Carélie",
  "Chien d'oysel Allemand",
  'Chien Finnois de Laponie',
  'Chien leopard catahoula',
  'Chien Loup de Saarloos',
  'Chien Loup Tchécoslovaque',
  'Chien Norvégien de Macareux',
  'Chien nu du Pérou',
  'Chien nu Méxicain',
  'Chien Thaïlandais à crête dorsale',
  'Chihuahua',
  'Chow Chow',
  'Cimarron Uruguayen',
  "Cirneco de l'Etna",
  'Clumber Spaniel',
  'Cockapoo',
  'Cocker Américain',
  'Cocker Spaniel Anglais',
  'Cocker Spaniel',
  'Colley à poil court',
  'Colley à poil long',
  'Colley barbu',
  'Coonhound noir et feu',
  'Coton de Tulear',
  'Cursinu',
  'Dalmatien',
  'Dandie Dinmont Terrier',
  'Danois',
  'Dogue Argentin',
  'Drahthaar',
  'English coonhound',
  'English Springer Spaniel',
  'Epagneul bleu de Picardie',
  'Epagneul Breton',
  'Epagneul de Pont-Audemer',
  'Epagneul de Saint-Usuge',
  "Epagneul d'eau Irlandais",
  'Epagneul du Tibet',
  'Epagneul Français',
  'Epagneul Japonais',
  'Epagneul King Charles',
  'Epagneul nain Phalène',
  'Epagneul Picard',
  'Esquimau du Canada',
  'Eurasier',
  'Field Spaniel',
  'Fila Brasileiro',
  'Foxhound Américain',
  'Fox-Hound Anglais',
  'Fox-Terrier à poil dur',
  'Fox-Terrier à poil lisse',
  'Francais blanc et noir',
  'Francais blanc et orange',
  'Francais tricolore',
  'Gascon Saintongeais',
  'Golden Retriever',
  'Gotland Hound',
  'Grand Anglo-Français Blanc et Noir',
  'Grand Anglo-Français Blanc et Orange',
  'Grand Basset Griffon Vendéen',
  'Grand Bleu de Gascogne',
  'Grand Bouvier Suisse',
  'Grand Danois de Bordeaux',
  'Grand épagneul de Münster',
  'Greyhound',
  'Griffon a poil laineux',
  'Griffon Belge',
  'Griffon Bleu de Gascogne',
  'Griffon Bruxellois',
  'Griffon Fauve de Bretagne',
  'Griffon Korthals',
  'Griffon Nivernais',
  'Griffon Vendéen',
  'Groenlandais',
  'Hälleforshund',
  'Harrier',
  'Heeler de Lancashire',
  'Hokkaido',
  'Hovawart',
  'Husky de Sibérie',
  'Irish Water Spaniel',
  'Jack-Russel-Terrier',
  'Jämthund',
  'Jindo Coréen',
  'Kai',
  'Kelpie',
  'Kishu',
  'Komondor',
  'Kooikerhondje',
  'Kromfohrländer',
  'Kuvasz',
  'Kyi leo',
  'Labradoodle',
  'Lagotto Romagnolo',
  'Laïka de Sibérie Occidentale',
  'Laïka de Sibérie Orientale',
  'Laïka Russo-Européen',
  'Lakeland-Terrier',
  'Landseer',
  'Langhaar',
  'Lapphund Suédois',
  'Lévrier',
  'Lévrier Afghan',
  'Lévrier Ecossais',
  'Lévrier Espagnol',
  'Lévrier Polonais',
  'Lhassa Apso',
  'Loulou de Poméranie',
  'Magyar Agar',
  "Mâtin de l'Alentejo",
  'Matin des Pyrénées',
  'Montagne des Pyrénées',
  'Mudi',
  'Norfolk-Terrier',
  'Norwich-Terrier',
  'Otterhound',
  'Papillon',
  'Parson Jack Russell',
  'Patterdale-Terrier',
  'Pékinois',
  'Petit Basset Griffon Vendéen',
  'Petit Bleu de Gascogne',
  'Petit Brabançon',
  'Petit chien courant du Jura',
  'Petit chien courant Suisse',
  'Petit chien lion',
  'Petit épagneul de Münster',
  'Petit levrier Italien',
  'Pinscher Allemand',
  'Pinscher autrichien',
  'Pinscher moyen',
  'Pinscher nain',
  'Podenco',
  'Podenco Canario',
  "Podenco d'Ibiza",
  'Podengo Portugais',
  'Pointer Anglais',
  'Poitevin',
  'Pomchi',
  'Porcelaine',
  'Prazsky Krysarik',
  'Pudelpointer',
  'Puli',
  'Pumi',
  'Ratier Pragois',
  'Redbone coonhound',
  'Retriever à poil bouclé',
  'Retriever à poil plat',
  'Retriever de la Baie de Cheasapeake',
  'Retriever de la Nouvelle-Ecosse',
  'Retriever du Labrador',
  'Rhodesian Ridgeback',
  'Royal Bourbon',
  'Russkaya Tsvetnaya Bolonka',
  'Saluki',
  'Samoyede',
  'Sanshu',
  'Schapendoes',
  'Schipperke',
  'Schnauzer moyen',
  'Schnauzer nain',
  'Sealyham-Terrier',
  'Setter Anglais',
  'Setter Gordon',
  'Setter Irlandais rouge',
  'Setter Irlandais rouge et blanc',
  'Shiba Inu',
  'Shikoku',
  'Silken Windhound',
  'Silky Terrier',
  'Skye',
  'Sloughi',
  'Smoushond Néerlandais',
  'Spinone',
  'Spitz Allemand',
  'Spitz de Norrbotten',
  'Spitz des Wisigoths',
  'Spitz Finlandais',
  'Spitz Japonais',
  'Spitz moyen',
  'Spitz nain',
  'Spitz-Loup',
  'Springer Anglais',
  'Stabyhoun',
  'Staffordshire Bull Terrier',
  'Sussex-Spaniel',
  'Tchouvatch Slovaque',
  'Teckel',
  'Teckel nain à poil dur',
  'Teckel nain à poil long',
  'Teckel nain à poil ras',
  'Telomian',
  'Terrier Australien',
  'Terrier Brésilien',
  "Terrier d'agrément anglais noir et feu",
  'Terrier de chasse Allemand',
  'Terrier de Manchester',
  'Terrier de Tenterfield',
  'Terrier du Tibet',
  'Terrier Ecossais',
  'Terrier Gallois',
  'Terrier Irlandais',
  'Terrier Irlandais à poil doux',
  'Terrier Irlandais Glen of Imaal',
  'Terrier Japonais',
  'Terrier Kerry Blue',
  'Terrier noir Russe',
  'Terrier Tchèque',
  'Volpino Italien',
  'Welsh Corgi Cardigan',
  'Welsh Corgi Pembroke',
  'Welsh Springer Spaniel',
  'West Highland White Terrier',
  'Whippet',
  'Yorkshire Terrier',
];
[...DOGS_BREEDS_1, ...DOGS_BREEDS_2, ...DOGS_BREEDS_3].sort();
[
  'Abyssin',
  'American Bobtail à poil court',
  'American Bobtail à poil long',
  'American Curl à poil court',
  'American Curl à poil long',
  'American Shorthair',
  'American Whitehair',
  'Angora Turc',
  'Balinais',
  'Bengal',
  'Bleu Russe',
  'Bobtail des Kouriles',
  'Bombay',
  'British Longhair',
  'British Shorthair',
  'Burmese Americain',
  'Burmese Anglais',
  'Burmilla',
  'California Spangled',
  'Californian Rex',
  'Ceylan',
  'Chartreux',
  'Chat Chinois',
  'Chat de Cafrerie',
  'Chat des Forets Norvegiennes',
  'Chat Haret',
  'Chat Jaune',
  'Chat nu',
  "Chat Sauvage d'Europe",
  'Chat Tigre',
  'Chat Turc Van',
  'Chausie',
  'Cornish Rex',
  'Cymric',
  'Devon Rex',
  'Donskoy',
  'Européen',
  'Exotic Shorthair',
  'German Rex',
  'Havana Brown',
  'Highland',
  'Japanese Bobtail à poil court',
  'Japanese Bobtail à poil long',
  'Javanais',
  'Khao Manee',
  'Korat',
  'Laperm à poil court',
  'Laperm à poil long',
  'Lynx Domestique',
  'Maine Coon',
  'Mandarin',
  'Manul',
  'Manx',
  'Margay',
  'Mau Arabe',
  'Mau Egyptien',
  'Munchkin à poil court',
  'Munchkin à poil long',
  'Nebelung',
  'Ocicat',
  'Ojos Azules',
  'Oriental',
  'Persan',
  'Peterbald',
  'Pixie Bob à poil court',
  'Pixie Bob à poil long',
  'Ragamuffin',
  'Ragdoll',
  'Sacre de Birmanie',
  'Savannah',
  'Scottish Fold',
  'Scottish Fold Long Hair',
  'Selkrirk Rex',
  'Siamois',
  'Sibérien',
  'Singapura',
  'Skogkatt',
  'Snowshoe',
  'Sokoke',
  'Somali',
  'Sphynx',
  'Thai',
  'Tiffany',
  'Tonkinois à poil court',
  'Tonkinois à poil long',
  'Turc du lac de Van',
  'York Chocolate',
].sort();
