import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { useTranslation } from 'react-i18next';

import { Context } from '../../../layouts/Account';
import { LanguageContext } from '../../../providers/context/language';
import { displayErrorMessage } from '../../../providers/snackbar';
import { blue } from '../../../theme';
import { getCalendlyUrl } from '../../../utils/calendly';
import {
  getErrorTranslation,
  isAlcampo,
} from '../../../utils/language';
import Button from '../../atoms/Button';
import Widget from '../../atoms/Widget';
import axiosAPI from '../../axiosApi';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Supply',
    color: blue,
    textAlign: 'center',
    fontSize: 'clamp(1.2rem, -0.279rem + 2.69vw, 3rem)',
  },
  form: {
    marginTop: '125px',
    height: 'calc(100% - 125px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },
  root: {
    display: 'flex',
    minHeight: 'fit-content ',
    height: '90%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      overflow: 'auto',
    },
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '5%',
    },
  },
  content: {
    padding: '0 50px',
    width: '100%',
    minHeight: '455px',
    maxHeight: '550px',
    overflowY: 'hidden',
  },
  btn: {
    width: '150px !important',
    margin: '10px 0',
  },
  gridHeightMax: {
    '& > *': {
      height: '100%',
    },
  },
  select: {
    whiteSpace: 'pre-wrap',
  },
}));

export default function Conseiller() {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.myAdvisor',
  });
  const { data } = useContext(Context);
  const { language } = useContext(LanguageContext);

  const classes = useStyles();
  const advisor_firstName =
    process.env.REACT_APP_LANGUAGE === 'es'
      ? 'Carmen'
      : data?.advisor
          .split('@')[0]
          .replace(
            /^./,
            data?.advisor.split('@')[0][0].toUpperCase(),
          );
  const [value, setValue] = React.useState('rdv');

  const [subject, setSubject] = React.useState('info');
  const [message, setMessage] = React.useState(
    language === 'fr'
      ? `Bonjour ${advisor_firstName}\n\n`
      : `Hola ${advisor_firstName}\n\n`,
  );
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubject = (event) => {
    setSubject(event.target.value);
  };

  const handleMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setSubmitted('pending');
    await axiosAPI
      .post('contactForm/', { subject: subject, msg: message })
      .then((response) => {
        setSubmitted(true);
      })
      .catch((error) => {
        displayErrorMessage(
          getErrorTranslation(error?.response?.data),
        );
        setTimeout(() => {
          setSubmitted(false);
        }, 1000);
      });
  };
  const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';

  const calendly = (
    <InlineWidget
      url={getCalendlyUrl(language)}
      styles={{
        marginTop: isMobileApp ? '30px' : 'unset',
        marginDown: isMobileApp ? '30px' : 'unset',
      }}
      pageSettings={{
        backgroundColor: 'ffffff',
        hideEventTypeDetails: true,
        hideLandingPageDetails: true,
        primaryColor: '00a2ff',
        textColor: '4d5055',
      }}
      prefill={{
        email: data?.email,
        name: data?.first_name + ' ' + data?.last_name,
      }}
    />
  );

  return (
    <Widget
      color="white"
      mobileHeight={'calc(100% - (46px + env(safe-area-inset-top)))'}
    >
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{ height: '100%' }}
      >
        <Grid item md={6} xs={12}>
          <div className={classes.aside}>
            <h2 className={classes.title}>
              {t('title')} {advisor_firstName}
            </h2>

            <div style={{ margin: 'auto' }}>
              <Typography
                style={{ fontSize: 16, fontFamily: 'Supply' }}
                variant={'h4'}
                color={'primary'}
              >
                {t('subtitle1')}
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="rdv"
                    control={<Radio />}
                    label={
                      <Typography
                        style={{ fontSize: 16, fontFamily: 'Supply' }}
                        variant={'h4'}
                        color={'primary'}
                      >
                        {t('labelRdv')}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="mail"
                    control={<Radio />}
                    label={
                      <Typography
                        style={{ fontSize: 16, fontFamily: 'Supply' }}
                        variant={'h4'}
                        color={'primary'}
                      >
                        {t('labelEmail')}
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <br />{' '}
              <Typography
                style={{ fontSize: 16, fontFamily: 'Supply' }}
                variant={'h4'}
                color={'primary'}
              >
                {t('urgency.text1')}
                <br /> {t('urgency.alcampoContact')}
                <a
                  style={{
                    color: blue,
                    fontWeight: 'bold',
                    textDecoration: isMobileApp
                      ? 'underline'
                      : 'none',
                    fontFamily: 'Supply',
                  }}
                  href={`mailto:${process.env.REACT_APP_ALCAMPO_EMAIL}`}
                >
                  {process.env.REACT_APP_ALCAMPO_EMAIL}
                </a>
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
          className={classes.gridHeightMax}
          sx={{ height: value === 'rdv' ? '90%' : 'unset' }}
        >
          {value === 'rdv' ? (
            calendly
          ) : (
            <>
              {!submitted ? (
                <form onSubmit={handleFormSubmit}>
                  <FormControl
                    fullWidth
                    sx={{
                      width: { xs: '93vw', md: '100%' },
                      height: '100%',
                      marginTop: '10px',
                    }}
                  >
                    <Grid item>
                      <InputLabel style={{ marginLeft: '35px' }}>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontFamily: 'Supply',
                          }}
                          variant={'h4'}
                          color={'primary'}
                        >
                          {t('calendly.subject')}
                        </Typography>
                      </InputLabel>
                      <Select
                        value={subject}
                        label={
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Supply',
                            }}
                            variant={'h4'}
                            color={'primary'}
                          >
                            {t('calendly.subject')}
                          </Typography>
                        }
                        onChange={handleSubject}
                        inputProps={{
                          style: { fontFamily: 'Supply' },
                        }}
                        style={{ width: '90%' }}
                      >
                        <MenuItem
                          className={classes.select}
                          value="info"
                          defaultValue
                        >
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Supply',
                            }}
                            variant={'h4'}
                            color={'primary'}
                          >
                            {t('calendly.askInfo')}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          className={classes.select}
                          value="claim"
                        >
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Supply',
                            }}
                            variant={'h4'}
                            color={'primary'}
                          >
                            {t('calendly.anyQuestion')}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          className={classes.select}
                          value="account"
                        >
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Supply',
                            }}
                            variant={'h4'}
                            color={'primary'}
                          >
                            {isAlcampo()
                              ? t('calendly.alcampo.dashboardProblem')
                              : t('calendly.dashboardProblem')}
                          </Typography>
                        </MenuItem>
                      </Select>
                    </Grid>

                    <Grid
                      item
                      xs={true}
                      style={{ marginTop: '30px' }}
                    >
                      <TextField
                        id="outlined-multiline-static"
                        label={
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Supply',
                            }}
                            variant={'h4'}
                            color={'primary'}
                          >
                            {t('calendly.message')}
                          </Typography>
                        }
                        onChange={handleMessage}
                        multiline
                        inputProps={{
                          style: {
                            fontFamily: 'Supply',
                            color: blue,
                          },
                        }}
                        rows={10}
                        style={{
                          height: '100%',
                          width: '90%',
                          fontFamily: 'Supply',
                        }}
                        defaultValue={message}
                      />
                    </Grid>

                    <Grid item>
                      <Button className={classes.btn} type="submit">
                        {t('calendly.submitButton')}
                      </Button>
                    </Grid>
                  </FormControl>
                </form>
              ) : (
                <section
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {'boolean' !== typeof submitted ? (
                    <>
                      <Typography
                        style={{ fontFamily: 'Supply' }}
                        variant={'h5'}
                      >
                        {t('calendly.pending')}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        style={{ fontFamily: 'Supply' }}
                        variant={'h5'}
                      >
                        {t('calendly.thanks')}
                      </Typography>
                      <Typography
                        style={{ fontFamily: 'Supply' }}
                        variant={'h5'}
                      >
                        {t('calendly.contactSoon')}
                      </Typography>
                    </>
                  )}
                </section>
              )}
            </>
          )}
        </Grid>
        {/*<Grid item xl={12} xs={12}>
          <Typography
            style={{ fontSize: 16, fontFamily: 'Supply' }}
            variant={'h4'}
            color={'primary'}
          >
            Tous nos conseillers sont localisés en France{' '}
            <Emoji symbol="🇫🇷" />
          </Typography>
        </Grid>*/}
      </Grid>
    </Widget>
  );
}
