import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import logoAlcampo from '../../../static/alcampo/logo.svg';
import instagram_logo from '../../../static/home/instagram_logo.svg';
import tiktok_logo from '../../../static/home/tiktok_logo.svg';
import logoDrooky from '../../../static/logo.svg';
import account from '../../../static/sidebar/contracts.svg';
import { isAlcampo } from '../../../utils/language';

const useStyles = makeStyles((theme) => ({
  wrapperImgLogo: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  imgLogo: {
    width: isAlcampo() ? '100%' : 50,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  img: {
    width: 24,
    height: 24,
  },
  list: {
    width: 265,
    height: '100%',
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    width: 30,
    height: 30,
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 25,
    },
    [theme.breakpoints.down('ss')]: {
      width: 23,
      height: 23,
    },
  },
}));

export default function TemporaryDrawer() {
  const { t } = useTranslation(null, { keyPrefix: 'drawer' });
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const logo = isAlcampo() ? logoAlcampo : logoDrooky;

  const toggleDrawer = (status) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(status);
  };
  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={classes.wrapperImgLogo}>
        <Link
          style={{ textDecoration: 'none' }}
          component={RouterLink}
          to="/"
          color="inherit"
        >
          <img className={classes.imgLogo} src={logo} alt={'logo'} />
          {!isAlcampo() && (
            <Typography>
              {isAlcampo() ? 'Alcampo Mascotas' : 'Drooky'}
            </Typography>
          )}
        </Link>
      </div>
      <Divider />
      <List>
        <Link
          style={{ textDecoration: 'none' }}
          component={RouterLink}
          to="/account/"
          color="inherit"
        >
          <ListItem button key="home">
            <ListItemIcon>
              <img
                className={classes.img}
                src={account}
                alt={'account'}
              />
            </ListItemIcon>
            <ListItemText
              primary={`${t('accountButton')} ${
                isAlcampo() ? 'Alcampo Mascotas' : 'Drooky'
              }`}
            />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List
        sx={{
          display: { xs: isAlcampo() ? 'none' : 'block', md: 'none' },
        }}
      >
        <ListItem
          button
          key="tiktok"
          onClick={() => {
            window.open(
              'https://www.tiktok.com/@drooky_family?lang=fr',
              'tiktok',
              'noopener',
            );
          }}
        >
          <ListItemIcon>
            <img
              src={tiktok_logo}
              className={classes.logo}
              alt="tiktok"
            />
          </ListItemIcon>
          <ListItemText primary="Tiktok" />
        </ListItem>
        <ListItem
          button
          key="instagram"
          onClick={() => {
            window.open(
              'https://www.instagram.com/drooky_family/',
              'instagram',
              'noopener',
            );
          }}
        >
          <ListItemIcon>
            <img
              src={instagram_logo}
              className={classes.logo}
              alt="instagram"
            />
          </ListItemIcon>
          <ListItemText primary="Instagram" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        edge="start"
        aria-label="menu"
        style={{ padding: 8 }}
        onClick={toggleDrawer(true)}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </React.Fragment>
  );
}
