import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { isPhoneNumberValid } from '@operatech1/validators';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { Context } from '../../../layouts/Account';
import ange from '../../../static/ange.svg';
import { blue } from '../../../theme';
import Snackbar from '../../atoms/Snackbar';
import Widget from '../../atoms/Widget';
import axiosAPI from '../../axiosApi';
import { getAccount } from '../login/userSlice';

const useStyles = makeStyles((theme) => ({
  title: {
    color: blue,
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
    },
  },
  subtitle: {
    color: 'rgb(179, 179, 179)',
    margin: 0,
  },
  check: {
    fontSize: '20rem',
    margin: '0 auto',
  },
  text_field_label_error: {
    color: theme.palette.error.main + ' !important',
  },
  text_field_error: {
    color: theme.palette.error.main + ' !important',
    '&:before': {
      borderColor: theme.palette.error.main + ' !important',
    },
    '&:after': {
      borderColor: theme.palette.error.main + ' !important',
    },
  },
  img: {
    maxWidth: 'unset',
    [theme.breakpoints.down('md')]: {
      maxWidth: '280px',
    },
  },
}));

export default function Guardian() {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.guardian',
  });
  const classes = useStyles();
  const [, setStatus] = React.useState();
  const { data } = useContext(Context);

  const schema = yup.object().shape({
    phone: yup
      .string()
      .test(
        'isPhoneNumberValid',
        '',
        (val) =>
          isPhoneNumberValid(val, 'FR') ||
          isPhoneNumberValid(val, 'ES'),
      ),
  });

  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: data?.guard?.['first_name'],
      last_name: data?.guard?.['last_name'],
      phone: data?.guard?.['phone'],
    },
  });
  const dispatch = useDispatch();

  const onSubmit = async (data, e) => {
    axiosAPI
      .post('updateGuard/', data)
      .then((response) => {
        setSnackbar(t('snackbar.success'), 'success');
        dispatch(getAccount());
      })
      .catch((e) => {
        const errorMessage = t('snackbar.error');
        setSnackbar(errorMessage, 'error');
      })
      .finally(() =>
        reset({
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
        }),
      );
    setStatus('done');
  };

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState(null);
  const [snackbarMessage, setSnackbarMessage] = React.useState(null);

  const setSnackbar = (message, status) => {
    setSnackbarMessage(message);
    setAlertStatus(status);
    setOpenSnackbar(true);
  };

  return (
    <Widget
      color="white"
      mobileHeight={'calc(100% - (46px + env(safe-area-inset-top)))'}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        style={{ height: '100%' }}
      >
        <Grid
          container
          direction="row"
          spacing={2}
          sx={{ height: '100%' }}
        >
          <Grid item xl={12} xs={12}>
            <h1 className={classes.title}>{t('title')}</h1>
          </Grid>

          <Grid item sm={6} xs={12}>
            <img className={classes.img} src={ange} alt="Gardien" />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Grid
              container
              direction="row"
              spacing={2}
              sx={{
                textAlign: { xs: 'center', sm: 'left' },
                height: '100%',
                padding: { xs: 'unset', sm: '10% 0' },
              }}
            >
              <Grid item xl={12} xs={12}>
                <TextField
                  {...register('first_name')}
                  label={t('labelFirstName')}
                  variant="standard"
                />
              </Grid>

              <Grid item xl={12} xs={12}>
                <TextField
                  {...register('last_name')}
                  label={t('labelLastName')}
                  variant="standard"
                />
              </Grid>

              <Grid item xl={12} xs={12}>
                <TextField
                  control={control}
                  {...register('phone')}
                  label={t('labelPhone')}
                  variant="standard"
                  InputLabelProps={{
                    className: errors?.phone
                      ? classes.text_field_label_error
                      : classes.text_field_label,
                  }}
                  InputProps={{
                    className: errors?.phone
                      ? classes.text_field_error
                      : classes.text_field,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} xs={12}>
            <Button
              variant={'contained'}
              sx={{
                width: { xs: 'unset', md: '337px' },
                margin: 'auto',
                marginBottom: '10px',
                height: { xs: 'unset', md: '62px' },
                padding: { xs: '8px 16px', md: 'unset' },
                clipPath:
                  'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
                borderRadius: 0,
              }}
              disabled={!isDirty || !isValid}
              type="submit"
            >
              {isSubmitting ? <CircularProgress /> : t('saveChanges')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={() => setOpenSnackbar(false)}
        alertStatus={alertStatus}
        snackbarMessage={snackbarMessage}
      />
    </Widget>
  );
}
