import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse, Grid, Paper, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from '@react-google-maps/api';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import googleMarker from '../../../../static/googleMarker.png';
import pricing_asset_4_es from '../../../../static/pricing/pricing-asset-4-es.svg';
import pricing_asset_4 from '../../../../static/pricing/pricing-asset-4.svg';
import { isAlcampo } from '../../../../utils/language';
import {
  selectAddress,
  selectFormattedAddress,
} from '../pricingSlice';
import styleMapCusto from './style.json';

const styleMap = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  styles: styleMapCusto,
};

const libraries = ['places'];
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'none',
    background: 'inherit',
  },
  containerStyleClass: {
    width: '600px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    [theme.breakpoints.down('lg')]: {
      width: '500px',
    },
    [theme.breakpoints.down('md')]: {
      width: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '150px',
      marginLeft: 10,
      width: '300px',
      // width: '300px',
      // marginLeft: 20,
      // marginRight: 20,
      // paddingLeft: 20,
      // paddingRight: 20,
    },
  },
  input: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
  },
  image_map: {
    width: 200,
    height: 200,
    marginRight: 60,
    [theme.breakpoints.down('lg')]: {
      width: 175,
      height: 175,
      marginRight: 40,
    },
    [theme.breakpoints.down('md')]: {
      width: 150,
      height: 150,
      marginRight: 20,
    },
  },
  text_field_label: {
    color: 'white !important',
  },
  text_field: {
    color: 'white !important',
    '&:before': {
      borderColor: 'white !important',
    },
    '&:after': {
      borderColor: 'white !important',
    },
  },
}));

const checkManualPostalCode = (postalCode) =>
  !postalCode ||
  (!_.isNaN(parseInt(postalCode)) && postalCode?.length === 5);

const schema = yup.object().shape({
  street: yup.string().optional().nullable(),
  city: yup.string().optional().nullable(),
  postal_code: yup
    .string()
    .test('checkPostalCode', '', (val) => checkManualPostalCode(val))
    .optional()
    .nullable(),
});

function MyComponent({ setNext, isManualAddress, t }) {
  const pricing = useSelector((state) => state.pricing);
  const { formattedAddress } = pricing;
  const classes = useStyles();
  const [autoComplete, setAutoComplete] = useState(null);

  const [checked, setChecked] = React.useState(false);
  const [center, setcenter] = useState({
    lat: 48.8720368,
    lng: 2.3389052,
  });

  const { control, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const dispatch = useDispatch();

  const onLoad = (autoComplete) => {
    setAutoComplete(autoComplete);
  };
  const onPlacesChanged = () => {
    const place = autoComplete.getPlace();
    const hasPostalCode =
      !!place &&
      !!place?.address_components?.find((a) =>
        a.types.includes('postal_code'),
      );
    if (!hasPostalCode) return;
    if (!checked) {
      setChecked(true);
    }
    if (place.geometry) {
      setcenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
    dispatch(selectAddress(place.address_components));
    dispatch(selectFormattedAddress(place.formatted_address));

    setNext(true);
  };

  const onChangeInput = (e) => {
    setNext(false);
  };

  const streetValue = watch('street');
  const postalCodeValue = watch('postal_code');
  const cityValue = watch('city');
  useEffect(() => {
    const isValidAddress =
      (!isManualAddress && !!formattedAddress) ||
      (isManualAddress &&
        !_.isEmpty(streetValue) &&
        !_.isEmpty(postalCodeValue) &&
        checkManualPostalCode(postalCodeValue) &&
        !_.isEmpty(cityValue));
    if (isManualAddress && isValidAddress) {
      dispatch(
        selectAddress({
          street: streetValue,
          city: cityValue,
          postal_code: postalCodeValue,
          ...(isAlcampo() && { country: 'ES' }),
        }),
      );
      dispatch(selectFormattedAddress(null));
    }
    setNext(isValidAddress);
  }, [
    isManualAddress,
    formattedAddress,
    streetValue,
    postalCodeValue,
    cityValue,
    setNext,
    dispatch,
  ]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      <Collapse
        in={checked}
        sx={{ display: isManualAddress ? 'none' : 'flex' }}
      >
        <GoogleMap
          mapContainerClassName={classes.containerStyleClass}
          center={center}
          zoom={15}
          options={styleMap}
        >
          <Marker position={center} icon={googleMarker} />
        </GoogleMap>
      </Collapse>
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        sx={{ display: isManualAddress ? 'none' : 'flex' }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ marginBottom: { xs: '20px', sm: 0 } }}
          style={{
            display: checked ? 'none' : 'block',
            height: '150px',
          }}
        >
          <img
            alt="img_map"
            src={isAlcampo() ? pricing_asset_4_es : pricing_asset_4}
            className={classes.image_map}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={checked ? 12 : 8}
          display="flex"
          justifyContent="center"
          style={{ marginTop: checked ? '20px' : 0 }}
        >
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlacesChanged}
            options={{
              types: ['street_address', 'route'],
              componentRestrictions: {
                country: isAlcampo()
                  ? ['es']
                  : ['fr', 'gp', 'mf', 're', 'mq'],
              },
            }}
          >
            <Paper className={classes.paper}>
              <TextField
                className={classes.input}
                variant="standard"
                id="adress"
                label={t('mapGoogle.addressField')}
                defaultValue={formattedAddress}
                placeholder={t('mapGoogle.placeholderAddress')}
                onChange={onChangeInput}
                InputLabelProps={{
                  className: classes.text_field_label,
                }}
                InputProps={{ className: classes.text_field }}
              />
            </Paper>
          </Autocomplete>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        sx={{ display: isManualAddress ? 'flex' : 'none' }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ marginBottom: { xs: '20px', sm: 0 } }}
          style={{
            display: checked ? 'none' : 'block',
            height: '150px',
          }}
        >
          <img
            alt="img_map"
            src={isAlcampo() ? pricing_asset_4_es : pricing_asset_4}
            className={classes.image_map}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={checked ? 12 : 8}
          display="flex"
          flexDirection={'row'}
          justifyContent="center"
          style={{ marginTop: checked ? '20px' : 0 }}
        >
          <Controller
            control={control}
            name="street"
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                id="street"
                label={t('mapGoogle.streetNumber')}
                variant="standard"
                InputLabelProps={{
                  className: classes.text_field_label,
                }}
                InputProps={{ className: classes.text_field }}
                error={!!fieldState.error}
                className={classes.manual_input_street}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="postal_code"
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                id="postal_code"
                label={t('mapGoogle.postalCode')}
                variant="standard"
                InputLabelProps={{
                  className: classes.text_field_label,
                }}
                InputProps={{ className: classes.text_field }}
                error={!!fieldState.error}
                className={classes.manual_input_postal_code}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="city"
            defaultValue={''}
            render={({ field, fieldState }) => (
              <TextField
                id="city"
                label={t('mapGoogle.city')}
                variant="standard"
                InputLabelProps={{
                  className: classes.text_field_label,
                }}
                InputProps={{ className: classes.text_field }}
                error={!!fieldState.error}
                className={classes.manual_input_city}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </LoadScript>
  );
}

export default React.memo(MyComponent);
