import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isAlcampo } from '../../../../utils/language';
import FabPrice from '../../../atoms/FabPrice';
import SelectStepper from '../../../atoms/SelectStepper';
import Popup from '../../../molecules/Dialog';
import {
  clearState,
  fetchPrices,
  updateContracts,
  validateUpdateContracts,
} from './garantiesSlice';
import PaymentSchedule from './PaymentSchedule';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    '& h3': {
      color: theme.palette.primary.main,
    },
    marginBottom: '40px',
  },
  check: {
    fontSize: '10rem',
    display: 'block',
    margin: 'auto',
  },
  price: {
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.93)',
      fontSize: '13.9px',
    },
  },
}));

const Taux = (props) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.guarantees.rate',
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    if (status_update === 'failed' || status_update === 'succeeded')
      props.close();
  };

  const contract = props.contracts;

  const wakam = contract.provider === 'wakam';

  const { status, status_calcul, status_update, prices, update } =
    useSelector((state) => state.garanties);

  function getAllKeys(prices) {
    const result = {};

    if (prices) {
      prices.items.forEach((item) => {
        item.levels.forEach((level) => {
          Object.entries(level.rates).forEach(([rate, value]) => {
            if (!result[rate]) {
              result[rate] = [];
            }
            if (!result[rate].includes(value)) {
              result[rate].push(value);
            }
          });
        });
      });
    }
    for (const key in result) {
      if (key === 'excess' && !isAlcampo()) {
        result[key].sort((a, b) => b - a);
      } else {
        result[key].sort((a, b) => a - b);
      }
    }

    return result;
  }

  const prevoyance = contract.guardPrice !== 0;
  const health = contract.healthPrice !== 0;
  const prevention = contract.preventionPrice !== 0;

  useEffect(() => {
    dispatch(clearState());
    dispatch(fetchPrices());
  }, [dispatch]);

  const [taux, setTaux] = useState(contract.refund);
  const [franchise, setFranchise] = useState(contract.excess);
  const [ceil, setCeil] = useState(contract.ceil);
  const [rente, setRente] = useState(contract.annuity);
  const [prev, setPrev] = useState(
    contract.prev !== null ? contract.prev : 0,
  );

  const packName = useMemo(
    () => [t('light'), t('confort'), t('premium')],
    [t],
  );

  const [pack, setPack] = useState(packName[0]);

  const price = useMemo(() => {
    let totalPrice = 0;
    const rates = {
      refund: taux,
      excess: franchise,
      ceil: ceil,
      prev: prev,
      annuity: rente,
    };

    if (prices) {
      prices.items.forEach((item) => {
        item.levels.forEach((level) => {
          let found = true;
          Object.entries(level.rates).forEach(([rate, value]) => {
            if (rates[rate] !== value) {
              found = false;
            }
          });
          if (found) return (totalPrice += level.price);
        });
      });
    }
    return totalPrice;
  }, [ceil, franchise, prev, rente, taux, prices]);

  const excess_values = prices ? getAllKeys(prices).excess : [];
  const init_excess = excess_values.indexOf(contract.excess);

  const index = packName.indexOf(pack);

  useEffect(() => {
    if (init_excess === -1 ? 0 : init_excess) {
      setPack(packName[init_excess]);
    }
  }, [init_excess, packName]);

  const recursivity = contract.recursivity;

  const diff =
    franchise !== contract.excess ||
    taux !== contract.refund ||
    rente !== contract.annuity ||
    ceil !== contract.ceil ||
    prev !== contract.prev;

  const diff_down =
    (((isAlcampo()
      ? franchise < contract.excess
      : franchise > contract.excess) ||
      taux < contract.refund ||
      ceil < contract.ceil) &&
      contract.isHealthLocked) ||
    (rente < contract.annuity && contract.isGuardLocked) ||
    (prev < contract.prev && contract.isHealthLocked);

  const handleClick = () => {
    dispatch(
      updateContracts({
        recursivity: contract.recursivity,
        refund: taux,
        excess: franchise,
        annuity: rente,
        ceil: ceil,
        prev: prev,
      }),
    );
    window.history.pushState(null, '', window.location.href);
    if (status === 'succeeded') setOpen(true);
  };

  if (prices) {
    const values = getAllKeys(prices).refund;
    const steps = values.map((value) => value * 100);

    const rente_values = getAllKeys(prices).annuity;
    const ceil_values = getAllKeys(prices).ceil;
    const prev_values = getAllKeys(prices).prev;

    const init_refund = values?.indexOf(contract.refund);
    const init_rente = rente_values?.indexOf(contract.annuity);
    const init_ceil = ceil_values?.indexOf(contract.ceil);
    const init_prev = prev_values?.indexOf(contract.prev);
    const init_pack = excess_values?.indexOf(contract.excess);

    return (
      <div className={classes.content}>
        <Popup
          open={status_calcul === 'succeeded' && open}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          title={t('popup.title')}
          action={() => dispatch(validateUpdateContracts())}
          status={status_update}
          done={status_update === 'succeeded'}
        >
          {status_update === 'succeeded' ? (
            <>
              <CheckCircleOutlineIcon
                color="success"
                className={classes.check}
              />
              <Typography sx={{ mt: 2, mb: 1, ml: 2, mr: 2 }}>
                {t('popup.successText')}
              </Typography>
            </>
          ) : status_update === 'failed' ? (
            <>
              <ErrorOutlineIcon
                color="error"
                className={classes.check}
              />
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('popup.errorText')}
              </Typography>
            </>
          ) : (
            <Typography gutterBottom>
              <h3>{t('popup.confirmText.text1')}</h3>
              <h5>{t('popup.confirmText.text2')}</h5>
              <h5>
                {t('popup.confirmText.text3')}&nbsp;
                {update?.cost?.toFixed(2)} €.
              </h5>
              <h5>
                <span style={{ color: 'red' }}>
                  {t('popup.confirmText.text4')}&nbsp;
                  {update?.first_cost?.toFixed(2)} €
                </span>
                {t('popup.confirmText.text5')}
              </h5>
              <h5>{t('popup.confirmText.text6')}</h5>
              <ul>
                {update?.change?.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
              <h5>{t('popup.confirmText.text7')}</h5>
              <h5>{t('popup.confirmText.text8')}</h5>
              <PaymentSchedule schedule={update?.schedule} />
            </Typography>
          )}
        </Popup>

        <Grid container direction="column" spacing={3}>
          {isAlcampo() && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mx: { xs: '10%', md: '20%' },
                '& *': {
                  fontSize: '14px !important',
                },
              }}
            >
              <SelectStepper
                disabled={contract.isHealthLocked || isAlcampo()}
                init_value={init_pack}
                tooltip={t('tooltipCeil')}
                setter={(e) => {
                  setPack(e);
                  setTaux(values[packName.indexOf(e)]);
                  setCeil(ceil_values[packName.indexOf(e)]);
                  setFranchise(excess_values[packName.indexOf(e)]);
                  setPrev(prev_values[packName.indexOf(e)]);
                }}
                index={index}
                value={pack}
                steps={packName}
                values={packName}
                displayFormulaAccount
                name="AlcampoStepper"
              />
            </Grid>
          )}
          <Grid container item spacing={4}>
            {health && (
              <Grid item xs={12} md={6}>
                <h3>{t('rateRefund')}</h3>
                <SelectStepper
                  disabled={contract.isHealthLocked || isAlcampo()}
                  tooltip={t('tooltipCeil')}
                  init_value={init_refund}
                  setter={(e) => {
                    setTaux(e);
                  }}
                  index={index}
                  value={taux}
                  steps={steps}
                  values={values}
                  symbol={'%'}
                />
              </Grid>
            )}

            {health && !wakam && (
              <Grid item xs={12} md={6}>
                <h3>{t('franchise')}</h3>
                <SelectStepper
                  disabled={contract.isHealthLocked || isAlcampo()}
                  tooltip={t('tooltipCeil')}
                  init_value={init_excess}
                  setter={(e) => {
                    setFranchise(e);
                  }}
                  index={index}
                  value={franchise}
                  steps={excess_values}
                  values={excess_values}
                  symbol={'€'}
                />
              </Grid>
            )}

            {health && !wakam && (
              <Grid item xs={12} md={6}>
                <h3>{t('ceiling')}</h3>
                <SelectStepper
                  disabled={contract.isHealthLocked || isAlcampo()}
                  tooltip={t('tooltipCeil')}
                  init_value={init_ceil}
                  setter={(e) => {
                    setCeil(e);
                  }}
                  index={index}
                  value={ceil}
                  steps={ceil_values}
                  values={ceil_values}
                  symbol={'€'}
                />
              </Grid>
            )}

            {prevoyance && (
              <Grid item xs={12} md={health && !wakam ? 6 : 12}>
                <h3>{t('pension')}</h3>
                <SelectStepper
                  disabled={contract.isGuardLocked || isAlcampo()}
                  tooltip={t('tooltipCeil')}
                  init_value={init_rente}
                  setter={setRente}
                  index={index}
                  value={rente}
                  steps={rente_values}
                  values={rente_values}
                  symbol={'€'}
                />
              </Grid>
            )}

            {(prevention || isAlcampo()) && (
              <Grid item xs={12} md={health && !wakam ? 6 : 12}>
                <h3>{t('prevPackage')}</h3>
                <SelectStepper
                  disabled={contract.isHealthLocked || isAlcampo()}
                  tooltip={t('tooltipCeil')}
                  init_value={init_prev}
                  setter={(e) => {
                    setPrev(e);
                  }}
                  index={index}
                  value={prev}
                  steps={prev_values}
                  values={prev_values}
                  symbol={'€'}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={
              (wakam && prevoyance) ||
              !wakam & (prevoyance ^ prevention)
                ? 12
                : 6
            }
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {diff ? (
                <FabPrice
                  className={classes.price}
                  oldPrice={contract.price?.toFixed(2)}
                  price={price}
                  an={recursivity}
                  symbol="€"
                  size="100px"
                />
              ) : (
                <FabPrice
                  className={classes.price}
                  price={contract.price}
                  an={recursivity}
                  symbol="€"
                  size="100px"
                />
              )}

              {status !== 'loading' && (
                <Tooltip
                  title={
                    contract.isTrialing
                      ? t('tooltipStatus.text1')
                      : diff_down
                      ? t('tooltipStatus.text2')
                      : t('tooltipStatus.text3')
                  }
                  placement="bottom"
                >
                  <Button
                    variant={'contained'}
                    sx={{
                      width: '100%',
                      maxWidth: { xs: '106px', sm: '127px' },
                      height: { xs: '41px', sm: '45px' },
                      clipPath:
                        'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
                      borderRadius: 0,
                    }}
                    disabled={
                      !diff ||
                      status === 'loading' ||
                      status_calcul === 'loading' ||
                      diff_down ||
                      contract.isTrialing
                    }
                    onClick={() => handleClick()}
                  >
                    {status_calcul === 'loading' ? (
                      <CircularProgress thickness={5.0} size="28px" />
                    ) : (
                      t('modifyButton')
                    )}
                  </Button>
                </Tooltip>
              )}
            </Stack>
          </Grid>
          <Grid container item xs={true}>
            {status === 'loading' ? (
              <Grid item xs={12} sx={{ margin: 'auto' }}>
                <CircularProgress size="50px" />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <h3 style={{ display: 'inline-flex' }}>
                    {t('nextPayment')}
                  </h3>
                  {/*<Popover>
                                        <UpdatingContracts contracts={contractsUpdating}/>
                                    </Popover>*/}
                </Grid>
                <Grid item xs={12}>
                  <PaymentSchedule />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }
};

export default Taux;
