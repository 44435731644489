import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { blue } from '../../theme';
import { isAlcampo } from '../../utils/language';
import FabPrice from './FabPrice';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#9ebecc',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#eaeaf0',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 10,
    // width: 'calc(100% + 160px)',
    zIndex: 1,
    // transform: 'translateX(-90px)',
    border: 0,
    backgroundColor: '#9ebecc',
    borderRadius: 8,
  },
}));

const ColorlibStepIconRoot = styled('div')(
  ({ theme, ownerState, disabled, reduction }) => ({
    padding: 0,
    zIndex: 1,
    '& button': {
      backgroundColor: disabled ? 'darkgrey' : '#9ebecc',
      opacity: isAlcampo() && disabled ? 0.9 : 1,
      '& p': {
        color: blue,
      },
    },
    '& button:hover': {
      backgroundColor: disabled
        ? 'darkgrey'
        : reduction
        ? '#9ebecc'
        : blue,
      '& p': {
        color: reduction ? blue : theme.palette.secondary.main,
      },
    },
    margin: '0 -35px',
    ...(ownerState.active && {
      transform: 'scale(1.3)',
      '& button': {
        backgroundColor: blue,
      },
      '& button:hover': {
        backgroundColor: blue,
      },
    }),
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.8)',
      '& p': {
        fontSize: '18px !important',
      },
      ...(ownerState.active && {
        transform: 'scale(1)',
      }),
    },
  }),
);

const useStyles = makeStyles({
  tooltip: {
    fontSize: '1em',
  },
});

const renderFabPrice = (props, label, index) => {
  const { active, disabled, symbol, displayFormulaAccount } = props;
  if (displayFormulaAccount) {
    return (
      <Button
        className={`${label}_${index}`}
        disabled={active}
        key={`${label}_${index}`}
        sx={{
          background: active ? `${blue} !important` : '#9ebecc',
        }}
        backgroundColor={active ? blue : '#9ebecc'}
      >
        <Typography
          variant="h3"
          className={`${label}_${index}`}
          sx={{
            fontSize: { xs: '16px', md: '32px' },
            color: active
              ? '#f8e873ff !important'
              : '#014c7eff !important',
          }}
        >
          {label}
        </Typography>
      </Button>
    );
  }
  return (
    <FabPrice
      disabled={
        isAlcampo() && !props?.name
          ? disabled
          : disabled && index < props.init_value
      }
      key={label}
      price={label}
      symbol={symbol}
      size="70px"
    />
  );
};

function ColorlibStepIcon(props) {
  const { active, steps, disabled, tooltip } = props;
  const classes = useStyles();

  const icons = Object.assign(
    {},
    ...steps.map((label, index) => ({
      [index + 1]: renderFabPrice(props, label, index),
    })),
  );
  if (
    isAlcampo() && !props?.name
      ? disabled
      : props.icon < props.init_value + 1 && disabled
  ) {
    return (
      <Tooltip
        enterTouchDelay={0}
        classes={{ tooltip: classes.tooltip }}
        title={tooltip}
        followCursor
      >
        <ColorlibStepIconRoot
          disabled={true}
          ownerState={{ active }}
          reduction={true}
        >
          {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
      </Tooltip>
    );
  }

  return (
    <ColorlibStepIconRoot disabled={false} ownerState={{ active }}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function SelectStepper(props) {
  const [activeStep, setActiveStep] = React.useState(
    props.values.indexOf(props.value),
  );
  const init_step = props.init_value;
  const { steps } = props;

  const handleStep = (step) => () => {
    if (!props.disabled || (props.disabled && step >= init_step)) {
      setActiveStep(step);
      props.setter(props.values[step]);
    }
  };

  return (
    <Box sx={{ width: '70%', margin: 'auto' }}>
      <Stepper
        nonLinear
        activeStep={isAlcampo() ? props.index : activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              StepIconProps={props}
              onClick={
                ((isAlcampo() && props?.name) || !isAlcampo()) &&
                handleStep(index)
              }
            ></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
