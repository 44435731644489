import { enqueueSnackbar } from 'notistack';

export const displayErrorMessage = (message) =>
  enqueueSnackbar(message, {
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  });
export const displaySuccessMessage = (message) =>
  enqueueSnackbar(message, {
    variant: 'success',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  });

const methods = {
  displayErrorMessage,
  displaySuccessMessage,
};
export default methods;
