import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';

import { Context } from '../../../../layouts/Account';
import Widget from '../../../atoms/Widget';
import MobileTable from './MobileTable';
import Table from './Table';

export const ERROR_COLOR = '#D32F2F';
export const SUCCESS_COLOR = '#2E7D32';

export function createData(item) {
  const montantP = item.cost;
  const montantR = item.refund;
  const status = item.status;
  const dateS = new Date(item.datePayment);
  const reste = montantR !== '-' ? montantP - montantR : '-';
  let statusLabel = '';
  switch (status) {
    case 'paid':
      statusLabel = `${t('myRefundsOrQuotes.paid')} ${new Date(
        item.dateRefund,
      ).toLocaleDateString('fr', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })}`;
      break;
    case 'refused':
      statusLabel = t('myRefundsOrQuotes.refused');
      break;
    default:
      statusLabel = t('myRefundsOrQuotes.waiting');
  }
  return {
    createdAt: new Date(item.created_at),
    dateS,
    ref: item.ref,
    animal: item.animal_name,
    montantP: montantP > 0 ? montantP?.toFixed(2) : 0,
    montantR: status === 'paid' ? montantR?.toFixed(2) : 0,
    reste: reste > 0 ? reste.toFixed(2) : 0,
    status,
    statusLabel,
  };
}

export default function EnhancedTable(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const { data } = useContext(Context);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;

  return (
    <Widget
      color="white"
      contentMargin={isMobile ? '0' : null}
      mobileHeight={'calc(100% - (46px + env(safe-area-inset-top)))'}
    >
      {isMobile ? (
        <MobileTable data={data} {...props} />
      ) : (
        <Table data={data} {...props} />
      )}
    </Widget>
  );
}
