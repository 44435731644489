import Backdrop from '@mui/material//Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Modal from '../components/molecules/Modal';
import Navbar from '../components/templates/account/Navbar';
import {
  getAccount,
  userSelector,
} from '../components/templates/login/userSlice';
import { LanguageContext } from '../providers/context/language';
import theme from '../theme/home';

export const Context = createContext();

export default function AccountLayout(props) {
  const [open, setOpen] = useState(false);
  const [activeObject, setActiveObject] = useState(null);
  const [data, setData] = useState(null);
  const [status, setStatus] = useState('idle');
  const { changeLanguage } = useContext(LanguageContext);

  const neutralizeBack = (callback) => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
      callback();
    };
  };

  const revivalBack = () => {
    window.onpopstate = undefined;
    window.history.back();
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      revivalBack();
    }
  };

  const openModal = (component) => {
    setActiveObject(component);
    setOpen(true);
    neutralizeBack(handleClose);
  };

  const { account, errorMessage } = useSelector(userSelector);

  useEffect(() => {
    setData(account);
    changeLanguage(account?.language);
    // eslint-disable-next-line
  }, [account]);

  const dispatch = useDispatch();

  const refreshAccount = useCallback(() => {
    setStatus('loading');
    dispatch(getAccount()).then((r) => {
      if (r.error) setStatus('error');
      else setStatus('success');
    });
  }, [dispatch]);

  useEffect(() => {
    refreshAccount();
  }, [refreshAccount]);

  return (
    <Context.Provider
      theme={theme}
      value={{
        openModal,
        data,
        handleClose,
        refreshAccount,
        setStatus,
      }}
    >
      {!data ? (
        <div
          style={{
            display: 'flex',
            minHeight: '100vh',
            background: 'white',
          }}
        >
          <CircularProgress sx={{ margin: 'auto' }} />
        </div>
      ) : (
        <Navbar {...props} disabled={status === 'loading'}>
          {status === 'error' ? (
            <p>{errorMessage}</p>
          ) : status === 'loading' ? (
            <section
              style={{
                height: '90%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress sx={{ margin: 'auto' }} />
            </section>
          ) : (
            <Outlet />
          )}
          <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            {activeObject}
          </Modal>
        </Navbar>
      )}
    </Context.Provider>
  );
}
