import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { displayErrorMessage } from '../../../../providers/snackbar';
import { getErrorTranslation } from '../../../../utils/language';
import axiosAPI from '../../../axiosApi';

const initialState = {
  status: 'idle',
  status_update: 'idle',
  status_calcul: 'idle',
  prices: '',
  update: null,
  next_payment: null,
};

export const fetchPrices = createAsyncThunk('prices', async () => {
  try {
    const response = await axiosAPI.post('contractsTotalPrice/');
    return response.data;
  } catch (e) {
    displayErrorMessage(getErrorTranslation(e?.response?.data));
    return getErrorTranslation(e?.response?.data);
  }
});

export const updateContracts = createAsyncThunk(
  'update',
  async (arg) => {
    try {
      const response = await axiosAPI.post('updateContracts/', arg);
      return response.data;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const validateUpdateContracts = createAsyncThunk(
  'validateUpdate',
  async (arg, thunkAPI) => {
    try {
      const response = await axiosAPI.post(
        'validateUpdateContracts/',
        arg,
      );
      return response.data;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return thunkAPI.rejectWithValue(
        getErrorTranslation(e?.response?.data),
      );
    }
  },
);

export const getNextPayments = createAsyncThunk(
  'schedule',
  async (arg) => {
    try {
      const response = await axiosAPI.post('getNextPayments/');
      return response.data;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const garantiesSlice = createSlice({
  name: 'garantie',
  initialState,
  reducers: {
    update: (state, action) => {
      state.updating = action.payload;
    },
    clearState: (state) => initialState,
  },
  extraReducers: {
    [fetchPrices.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchPrices.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded';
      state.prices = payload;
    },
    [fetchPrices.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
    //////////////////////////////////////
    [updateContracts.pending]: (state) => {
      state.status_calcul = 'loading';
    },
    [updateContracts.fulfilled]: (state, { payload }) => {
      state.status_calcul = 'succeeded';
      state.update = payload;
    },
    [updateContracts.rejected]: (state, { payload }) => {
      state.status_calcul = 'failed';
    },
    //////////////////////////////////////
    [validateUpdateContracts.pending]: (state) => {
      state.status_update = 'loading';
    },
    [validateUpdateContracts.fulfilled]: (state, { payload }) => {
      state.status_update = 'succeeded';
    },
    [validateUpdateContracts.rejected]: (state, { payload }) => {
      state.status_update = 'failed';
    },
    //////////////////////////////////////
    [getNextPayments.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded';
      state.next_payment = payload;
    },
    [getNextPayments.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
  },
});

// Action creators are generated for each case reducer function
export const { update, clearState } = garantiesSlice.actions;
export default garantiesSlice.reducer;
