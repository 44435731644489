import { Grid, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function Pricing({
  classes,
  switchValues,
  setSwitchValues,
  t,
}) {
  return (
    <Grid
      container
      sx={{
        maxWidth: { xs: '410px', sm: 'unset' },
        marginTop: { xs: '20px', sm: 'unset' },
      }}
    >
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={{
          marginBottom: {
            xs: '0px',
            sm: '10px',
            md: '15px',
            lg: '20px',
          },
        }}
      >
        <Typography
          className={classes.typo}
          style={{ marginRight: '20px' }}
        >
          {t('input.vaccineToggle1')}
          <span style={{ display: 'inline-block' }}>
            {t('input.vaccineToggle2')}
          </span>
        </Typography>
        <Switch
          onChange={() => {
            setSwitchValues({
              ...switchValues,
              VACCINES: !switchValues.VACCINES,
            });
          }}
          checked={switchValues.VACCINES}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={{
          marginBottom: {
            xs: '0px',
            sm: '10px',
            md: '15px',
            lg: '20px',
          },
        }}
      >
        <Typography
          className={classes.typo}
          style={{ marginRight: '20px', textAlign: 'end' }}
        >
          {t('input.illnessToggle1')}
          <span style={{ display: 'inline-block' }}>
            {t('input.illnessToggle2')}
          </span>
        </Typography>
        <Switch
          onChange={() => {
            setSwitchValues({
              ...switchValues,
              NO_ILLNESS: !switchValues.NO_ILLNESS,
            });
          }}
          checked={switchValues.NO_ILLNESS}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="end"
      >
        <Typography
          className={classes.typo}
          style={{ marginRight: '20px' }}
        >
          {t('input.puceToggle1')}
          <span style={{ display: 'inline-block' }}>
            {t('input.puceToggle2')}
          </span>
        </Typography>
        <Switch
          onChange={() => {
            setSwitchValues({
              ...switchValues,
              CHIP: !switchValues.CHIP,
            });
          }}
          checked={switchValues.CHIP}
        />
      </Grid>
    </Grid>
  );
}
