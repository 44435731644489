import _ from 'lodash';

import {
  CAT,
  CROSSED_MASTIFF_BREED,
  DOGS_BREEDS_1,
  DOGS_BREEDS_2,
  MAX_CAT_BIRTHDAY_MONTHS,
  MAX_DOG_BIRTHDAY_1_MONTHS,
  MAX_DOG_BIRTHDAY_2_MONTHS,
  MAX_DOG_BIRTHDAY_3_MONTHS,
} from '../constants';

export const calculateMaxAnimalAge = (animal) => {
  if (!animal) return 0;
  const { species, breed } = animal;
  if (species === CAT) return MAX_CAT_BIRTHDAY_MONTHS;
  if (_.includes(DOGS_BREEDS_1, breed))
    return MAX_DOG_BIRTHDAY_1_MONTHS;
  else if (
    _.includes([...DOGS_BREEDS_2, CROSSED_MASTIFF_BREED], breed)
  )
    return MAX_DOG_BIRTHDAY_2_MONTHS;
  else return MAX_DOG_BIRTHDAY_3_MONTHS;
};
