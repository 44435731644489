import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Context } from '../../../layouts/Account';
import { LanguageContext } from '../../../providers/context/language';
import subdAlcampo from '../../../static/alcampo/tuile_devis_desktop.svg';
import subdmAlcampo from '../../../static/alcampo/tuile_devis_mobile.svg';
import subfAlcampo from '../../../static/alcampo/tuile_facture_desktop.svg';
import subfmAlcampo from '../../../static/alcampo/tuile_facture_mobile.svg';
import subgAlcampo from '../../../static/alcampo/tuile_garde_desktop.svg';
import subgmAlcampo from '../../../static/alcampo/tuile_garde_mobile.svg';
import subsAlcampo from '../../../static/alcampo/tuile_sante_desktop.svg';
import subsmAlcampo from '../../../static/alcampo/tuile_sante_mobile.svg';
import subdDrooky from '../../../static/tuile_devis_desktop.svg';
import subdmDrooky from '../../../static/tuile_devis_mobile.svg';
import subfDrooky from '../../../static/tuile_facture_desktop.svg';
import subfmDrooky from '../../../static/tuile_facture_mobile.svg';
import subgDrooky from '../../../static/tuile_garde_desktop.svg';
import subgmDrooky from '../../../static/tuile_garde_mobile.svg';
import subsDrooky from '../../../static/tuile_sante_desktop.svg';
import subsmDrooky from '../../../static/tuile_sante_mobile.svg';
import { isAlcampo } from '../../../utils/language';
import Widget from '../../atoms/Widget';

export default function Dashboard() {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.dashboard',
  });
  const { language } = useContext(LanguageContext);
  const { data } = useContext(Context);
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const subg = language === 'es' ? subgAlcampo : subgDrooky;
  const subs = language === 'es' ? subsAlcampo : subsDrooky;
  const subd = language === 'es' ? subdAlcampo : subdDrooky;
  const subf = language === 'es' ? subfAlcampo : subfDrooky;
  const subgm = language === 'es' ? subgmAlcampo : subgmDrooky;
  const subsm = language === 'es' ? subsmAlcampo : subsmDrooky;
  const subdm = language === 'es' ? subdmAlcampo : subdmDrooky;
  const subfm = language === 'es' ? subfmAlcampo : subfmDrooky;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width < 1000;
  const isSmallDevice = width < 600;

  const redirect = (path) => {
    if (path) navigate(`/account/${path}`);
  };

  if (data?.default_contract?.paymentDue) {
    return (
      <Stack
        justifyContent="center"
        sx={{ height: '100%', width: '80%', margin: 'auto' }}
      >
        <Typography variant="h3">{t('paymentDue')}</Typography>
      </Stack>
    );
  }

  return (
    <Grid container direction="row">
      <Grid item md={6} xs={12}>
        <Widget
          disabled={data?.default_contract?.paymentDue}
          tile
          contentMargin={isSmallDevice ? '8px' : null}
          color="transparent"
          onClick={() => redirect('refund')}
        >
          <img src={isMobile ? subfm : subf} alt={t('addRefund')} />
        </Widget>
      </Grid>

      <Grid item md={6} xs={12}>
        <Widget
          disabled={data?.default_contract?.paymentDue}
          tile
          contentMargin={isSmallDevice ? '8px' : null}
          color="transparent"
          onClick={() => redirect('quote')}
        >
          <img src={isMobile ? subdm : subd} alt={t('askQuote')} />
        </Widget>
      </Grid>
      <Grid item md={6} xs={12}>
        <Widget
          disabled={data?.default_contract?.paymentDue}
          tile
          contentMargin={isSmallDevice ? '8px' : null}
          color="transparent"
          onClick={() =>
            redirect(isAlcampo() ? 'garanties' : 'addGuard')
          }
        >
          <img src={isMobile ? subgm : subg} alt={t('addGuard')} />
        </Widget>
      </Grid>
      <Grid item md={6} xs={12}>
        <Widget
          disabled={data?.default_contract?.paymentDue}
          tile
          contentMargin={isSmallDevice ? '8px' : null}
          color="transparent"
          onClick={() => redirect('addAnimal')}
        >
          <img src={isMobile ? subsm : subs} alt={t('addAnimal')} />
        </Widget>
      </Grid>
    </Grid>
  );
}
