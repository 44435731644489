import React from 'react';

const Emoji = (props) => (
  <span
    aria-label={props.label ? props.label : ''}
    aria-hidden={props.label ? 'false' : 'true'}
    style={{ display: 'inline-block', width: '20px' }}
  >
    {props.symbol}
  </span>
);
export default Emoji;
