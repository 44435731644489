import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import cat from '../../../../static/cat.svg';
import cat_and_dog from '../../../../static/cat_and_dog.svg';
import dog from '../../../../static/dog.svg';
import guard from '../../../../static/guard.svg';
import theme from '../../../../theme';
import { isAlcampo } from '../../../../utils/language';
import { CAT, DOG, GUARD, MULTIPLE } from '../constants';

const useStyles = makeStyles((theme) => ({
  img: {
    maxHeight: 80,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 70,
    },
  },
  container: {
    margin: '20px',
    [theme.breakpoints.down('md')]: {
      margin: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '15px',
    },
  },
  typo: {
    color: theme.palette.primary.main,
    fontSize: '1.7rem',
    marginTop: '6px',
    maxWidth: 260,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 210,
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 180,
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
      fontSize: '1.1rem',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: '#C0D8E2',
    },
  },
  marginButton: {
    marginBottom: 30,
    minWidth: 400,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
    },
  },
}));

const SelectAnimals = ({ OnChange, isAccount, isLoading, t }) => {
  const classes = useStyles();
  const [selection, setSelection] = React.useState(null);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      // style={{ minWidth: isAccount ? 400 : undefined }}
      alignItems="stretch"
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        className={classes.marginButton}
      >
        <Grid
          item
          xs={6}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div className={classes.container}>
            <Button
              className={classes.button}
              onClick={() => {
                setSelection(DOG);
                OnChange(DOG);
              }}
            >
              <CircularProgress
                thickness={5.0}
                size="35px"
                sx={{
                  color: theme.palette.primary.main,
                  position: 'absolute',
                  display:
                    selection === DOG && isLoading ? 'flex' : 'none',
                }}
              />
              <img
                src={dog}
                style={{
                  visibility:
                    selection === DOG && isLoading
                      ? 'hidden'
                      : 'visible',
                }}
                alt={DOG}
                className={classes.img}
              />
            </Button>
            <Typography variant={'h4'} className={classes.typo}>
              {t('selectAnimal.dog')}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div className={classes.container}>
            <Button
              className={classes.button}
              onClick={() => {
                setSelection(CAT);
                OnChange(CAT);
              }}
            >
              <CircularProgress
                thickness={5.0}
                size="35px"
                sx={{
                  color: theme.palette.primary.main,
                  position: 'absolute',
                  display:
                    selection === CAT && isLoading ? 'block' : 'none',
                }}
              />
              <img
                src={cat}
                style={{
                  visibility:
                    selection === CAT && isLoading
                      ? 'hidden'
                      : 'visible',
                }}
                alt={CAT}
                className={classes.img}
              />
            </Button>
            <Typography variant={'h4'} className={classes.typo}>
              {t('selectAnimal.cat')}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {isAccount ? (
        <></>
      ) : (
        <Grid
          container
          item
          direction="row"
          justifyContent={isAlcampo() ? 'center' : 'space-between'}
          alignItems="stretch"
          style={{ overflow: 'block' }}
        >
          <Grid
            item
            xs={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div className={classes.container}>
              <Button
                className={classes.button}
                onClick={() => {
                  setSelection(MULTIPLE);
                  OnChange(MULTIPLE);
                }}
              >
                <CircularProgress
                  thickness={5.0}
                  size="35px"
                  sx={{
                    color: theme.palette.primary.main,
                    position: 'absolute',
                    display:
                      selection === MULTIPLE && isLoading
                        ? 'flex'
                        : 'none',
                  }}
                />
                <img
                  src={cat_and_dog}
                  style={{
                    visibility:
                      selection === MULTIPLE && isLoading
                        ? 'hidden'
                        : 'visible',
                  }}
                  alt={MULTIPLE}
                  className={classes.img}
                />
              </Button>
              <Typography variant={'h4'} className={classes.typo}>
                {t('selectAnimal.multiple')}
              </Typography>
            </div>
          </Grid>
          {!isAlcampo() && (
            <Grid
              item
              xs={6}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.container}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setSelection(GUARD);
                    OnChange(GUARD);
                  }}
                >
                  <CircularProgress
                    thickness={5.0}
                    size="35px"
                    sx={{
                      color: theme.palette.primary.main,
                      position: 'absolute',
                      display:
                        selection === GUARD && isLoading
                          ? 'flex'
                          : 'none',
                    }}
                  />
                  <img
                    src={guard}
                    style={{
                      visibility:
                        selection === GUARD && isLoading
                          ? 'hidden'
                          : 'visible',
                    }}
                    alt={GUARD}
                    className={classes.img}
                  />
                </Button>
                <Typography variant={'h4'} className={classes.typo}>
                  {t('selectAnimal.meAndMyPet')}
                </Typography>
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SelectAnimals;
