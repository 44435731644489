import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import IconCat from '../../../../static/cat.svg';
import IconMultiple from '../../../../static/cat_and_dog.svg';
import IconDog from '../../../../static/dog.svg';
import IconGuard from '../../../../static/guard.svg';
import { CAT, DOG, GUARD, MULTIPLE } from '../constants';
import { selectAnimals } from '../pricingSlice';
import MultipleAnimal from './MultipleAnimal';
import SingleAnimal from './SingleAnimal';

export const animalsConfig = {
  [DOG]: {
    title:
      "Comment s'appelle votre <span style=display:inline-block;>compagnon ?</span>",
    placeholder: 'Nom de votre chien',
    icon: IconDog,
    component: SingleAnimal,
  },
  [CAT]: {
    title:
      "Comment s'appelle votre <span style=display:inline-block;>compagnon ?</span>",
    placeholder: 'Nom de votre chat',
    icon: IconCat,
    component: SingleAnimal,
  },
  [MULTIPLE]: {
    title:
      "Comment s'appellent vos <span style=display:inline-block;>compagnons ?</span>",
    placeholder: 'Nom de votre 1er animal',
    icon: IconMultiple,
    component: MultipleAnimal,
  },
  [GUARD]: {
    title:
      "Comment s'appelle votre <span style=display:inline-block;>compagnon ?</span>",
    placeholder: 'Nom de votre 1er animal',
    icon: IconGuard,
    component: MultipleAnimal,
  },
};

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: (props) => (props.isAccount ? 0 : 50),
    // paddingRight: 10,
    // paddingLeft: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: (props) => (props.isAccount ? 0 : 10),
    },
  },
}));

function AnimalName({ handleNext, step, isAccount, isLoading }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalName',
  });
  const pricing = useSelector((state) => state.pricing);
  const { formId, animalType, animals } = pricing;
  const dispatch = useDispatch();
  const classes = useStyles({ isAccount });

  const onSubmit = (animals) => {
    if (isLoading) return;
    dispatch(selectAnimals(animals));
    handleNext({ step, id: formId, animals });
  };

  return (
    <section className={classes.section}>
      {animalType === MULTIPLE || animalType === GUARD ? (
        <MultipleAnimal
          onSubmit={onSubmit}
          isLoading={isLoading}
          animalsConfig={animalsConfig}
          animalType={animalType}
          animalsInit={animals}
          t={t}
        />
      ) : (
        <SingleAnimal
          onSubmit={onSubmit}
          isLoading={isLoading}
          animalConfig={animalsConfig[animalType]}
          animalType={animalType}
          animalsInit={animals}
          t={t}
        />
      )}
    </section>
  );
}

AnimalName.componentName = 'stepsLabel.name';

export default AnimalName;
