import { Navigate, useRoutes } from 'react-router-dom';

import { isAuthenticated } from './components/axiosApi';
import AddContract from './components/templates/account/addContract';
import Admin from './components/templates/account/admin/admin';
import Payment from './components/templates/account/admin/payment';
import MyContracts from './components/templates/account/contracts/contracts';
import Dashboard from './components/templates/account/Dashboard';
import MyGaranties from './components/templates/account/garanties/garanties';
import MyGuardian from './components/templates/account/guardian';
import MyAdvisor from './components/templates/account/myAdvisor';
import QuoteAccount from './components/templates/account/quotes/quotes';
import MyRefundsOrQuotes from './components/templates/account/refunds/myRefundsOrQuotes';
import Refund from './components/templates/account/refunds/refunds';
import Feedback from './components/templates/feedback';
import HomePage from './components/templates/home';
import DogCatHomePage from './components/templates/home/dogCat';
import Login from './components/templates/login/signin';
import NotFoundPage from './components/templates/notFound';
import Pricing from './components/templates/pricing';
import Quote from './components/templates/quote';
import AboutUsLayout from './layouts/AboutUs';
import AccountLayout from './layouts/Account';
import FeedbackLayout from './layouts/FeedbackLayout';
// layouts
import HomePageLayout from './layouts/HomePage';
import LegalNoticeLayout from './layouts/LegalNotice';
import PartnersLayout from './layouts/PartnersLayout';
import PricingLayout from './layouts/Pricing';
import PrivacyPolicyLayout from './layouts/PrivacyPolicy';
import QuoteLayout from './layouts/Quote';
import SigningLayout from './layouts/Signing';

// ----------------------------------------------------------------------

export default function Router() {
  const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';

  return useRoutes([
    {
      path: '/',
      children: [
        {
          element: isMobileApp ? (
            <Navigate to="/login" />
          ) : (
            <HomePageLayout wait={500} homePage={true} />
          ),
          children: [
            { path: '/', element: <HomePage /> },
            { path: '/404', element: <NotFoundPage /> },
          ],
        },
        {
          element: <HomePageLayout wait={500} dogCat={true} />,
          children: [
            {
              path: '/dog',
              element: <DogCatHomePage isDog={true} />,
            },
            { path: '/cat', element: <DogCatHomePage /> },
          ],
        },
      ],
    },
    {
      path: '/login',
      element: !isAuthenticated() ? (
        <SigningLayout />
      ) : (
        <Navigate to="/account/" />
      ),
      children: [{ path: '/login', element: <Login /> }],
    },
    {
      path: '/pricing',
      element: <PricingLayout />,
      children: [
        { path: '/pricing/:step', element: <Pricing /> },
        {
          path: '/pricing',
          element: <Navigate to="/pricing/0" replace />,
        },
      ],
    },
    {
      path: '/quote',
      element: <QuoteLayout homePage={true} />,
      children: [{ path: '/quote', element: <Quote /> }],
    },
    {
      path: '/account',
      element: isAuthenticated() ? (
        <AccountLayout />
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        { path: '/account', element: <Dashboard /> },
        { path: 'refund', element: <Refund /> },
        { path: 'addGuard', element: <AddContract guard={true} /> },
        { path: 'addAnimal', element: <AddContract guard={false} /> },
        { path: 'quote', element: <QuoteAccount /> },
        {
          path: 'myrefunds',
          element: <MyRefundsOrQuotes isRefunds={true} />,
        },
        {
          path: 'myquotes',
          element: <MyRefundsOrQuotes isRefunds={false} />,
        },
        { path: 'advisor', element: <MyAdvisor /> },
        { path: 'garanties', element: <MyGaranties /> },
        { path: 'contracts', element: <MyContracts /> },
        { path: 'myguardian', element: <MyGuardian /> },
        { path: 'payment', element: <Payment /> },
        { path: 'personnalinfo', element: <Admin /> },
      ],
    },
    {
      path: '/legalNotice',
      element: <LegalNoticeLayout />,
    },
    {
      path: '/privacyPolicy',
      element: <PrivacyPolicyLayout />,
    },
    {
      path: '/aboutUs',
      element: <AboutUsLayout />,
    },
    {
      path: '/partners',
      element: <PartnersLayout />,
    },
    {
      path: '/feedback',
      element: <FeedbackLayout />,
      children: [{ path: '/feedback', element: <Feedback /> }],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
