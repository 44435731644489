import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { displayErrorMessage } from '../../../providers/snackbar';
import { getErrorTranslation } from '../../../utils/language';
import axiosAPI from '../../axiosApi';

const initialState = {
  status: 'idle',
  contractID: '',
  typeCare: '',
  isSurgery: '',
  typeClaim: '',
  context: '',
  date: new Date().toISOString().slice(0, 10),
  cost: 0,
};

export const refundSend = createAsyncThunk(
  'refund',
  async (arg, { getState }) => {
    const state = getState().refund;
    const formData = new FormData();
    if (arg)
      Array.from(arg).forEach((file) =>
        formData.append('files', file),
      );
    Object.keys(state).forEach((key) =>
      formData.append(key, state[key]),
    );
    try {
      const response = await axiosAPI.post('refund/', formData);
      return response.data;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const updateAnimal = createAsyncThunk(
  'refund',
  async (arg) => {
    try {
      const response = await axiosAPI.post('updateAnimalPuce/', arg);
      return response.data;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const refundSlice = createSlice({
  name: 'refund',
  initialState,
  reducers: {
    clearState: (state) => initialState,
    clearStatus: (state) => {
      state.status = 'idle';
    },
    selectType: (state, action) => {
      state.typeClaim = action.payload;
    },
    selectAnimal: (state, action) => {
      state.contractID = action.payload;
    },
    selectContext: (state, action) => {
      state.context = action.payload;
    },
    selectCare: (state, action) => {
      state.typeCare = action.payload;
    },
    selectIsSurgery: (state, action) => {
      state.isSurgery = action.payload;
    },
    selectDate: (state, action) => {
      state.date = action.payload;
    },
    selectCost: (state, action) => {
      state.cost = action.payload;
    },
    validateForm: (state, action) => {
      state.date = action.payload.date;
      state.cost = action.payload.cost;
    },
  },
  extraReducers: {
    [refundSend.pending]: (state) => {
      state.status = 'loading';
    },
    [refundSend.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded';
    },
    [refundSend.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
    //////////////////////////////////////
    [updateAnimal.pending]: (state) => {
      state.status = 'loading';
    },
    [updateAnimal.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded';
    },
    [updateAnimal.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  selectType,
  selectAnimal,
  selectCare,
  selectIsSurgery,
  selectCost,
  selectDate,
  selectContext,
  validateForm,
  clearState,
  clearStatus,
} = refundSlice.actions;
export default refundSlice.reducer;
