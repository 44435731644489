import { Box, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'use-hooks';

import Footer from '../components/templates/home/Footer';
import Navbar from '../components/templates/home/Navbar';
import { blue_light } from '../theme';
import theme from '../theme/home';
import { isAlcampo } from '../utils/language';
import { makeStaticPageStyles } from './styles';

const refSize = 1920;
const minZoomMobile = 400;
const minZoomDesktop = 1000;

const useStyles = makeStyles((theme) => makeStaticPageStyles(theme));

const TextSection = ({ titleKey, textKey, isAlcampo }) => (
  <>
    <Typography
      variant="h3"
      sx={{ marginTop: { xs: '50px', md: '100px' } }}
    >
      {titleKey}
    </Typography>
    <Typography
      className={useStyles().typo}
      style={{ marginTop: 30 }}
    >
      {isAlcampo() ? textKey.alcampo : textKey.default}
      <br />
      <br />
    </Typography>
  </>
);

export default function PrivacyPolicyLayout(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'layouts.legalNotice',
  });
  const { width } = useWindowSize();
  const zoom =
    width < minZoomDesktop
      ? 1 + (width - minZoomMobile) / 900
      : width / refSize;
  const classes = useStyles({ zoom });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Navbar {...props} />
      <Box className={classes.zoomHome}>
        <section
          style={{
            background: blue_light,
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              paddingTop: '40px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography color="primary" variant="h2">
              {t('title')}
            </Typography>
          </div>
          <section className={classes.htmlContent}>
            <TextSection
              titleKey={t('editor.title')}
              textKey={{
                alcampo: t('editor.alcampo.text1'),
                default: t('editor.text1'),
              }}
              isAlcampo={isAlcampo}
            />
            <Typography
              className={classes.typo}
              style={{ marginTop: 30 }}
            >
              {t('editor.text2')}
              <br />
              {t('editor.text3')}
              <br />
              {t('editor.text4')}
              <br />
              {t('editor.text5')}
              <br />
              {t('editor.text6')}
              <br />
              {isAlcampo()
                ? t('editor.alcampo.text7')
                : t('editor.text7')}
            </Typography>

            <TextSection
              titleKey={t('editor.text8')}
              textKey={{ alcampo: '', default: '' }}
              isAlcampo={isAlcampo}
            />
            <Typography
              className={classes.typo}
              style={{ marginTop: 30 }}
            >
              {t('editor.text9')}
            </Typography>

            <TextSection
              titleKey={t('editor.text10')}
              textKey={{ alcampo: '', default: '' }}
              isAlcampo={isAlcampo}
            />
            <Typography
              className={classes.typo}
              style={{ marginTop: 30 }}
            >
              {isAlcampo()
                ? t('editor.alcampo.text11')
                : t('editor.text11')}
            </Typography>
          </section>
          <div style={{ width: '100%', paddingBottom: '50px' }}>
            <Footer />
          </div>
        </section>
      </Box>
    </ThemeProvider>
  );
}
