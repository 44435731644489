import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import intro from '../../static/alcampo/intro.svg';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '25%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function IntroAlcampo(props) {
  const classes = useStyles();

  return (
    <div
      style={{ height: '100vh', width: '100%', background: 'white' }}
      {...props}
    >
      <img className={classes.img} alt="" src={intro} />
    </div>
  );
}
