import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import pricing_asset_1 from '../../../../static/pricing/pricing-asset-1.svg';
import pricing_asset_mobile_2 from '../../../../static/pricing/pricing-asset-mobile-2.svg';
import { selectAnimals, selectAnimalType } from '../pricingSlice';
import SelectAnimals from './SelectAnimals';

const useStyles = makeStyles((theme) => ({
  animalSelection: {
    width: '100%',
    paddingTop: (props) => (props.isAccount ? 0 : 60),
    paddingRight: 10,
    paddingLeft: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: (props) => (props.isAccount ? 0 : 10),
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  mobile_image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    justifyContent: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));
function AnimalSelection({ handleNext, step, isAccount, isLoading }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalSelection',
  });
  const formId = useSelector((state) => state.pricing.formId);
  const firstName =
    useSelector((state) => state.pricing.firstName) || '';
  const classes = useStyles({ isAccount });
  const dispatch = useDispatch();
  const selectAnimal = (animalType) => {
    if (isLoading) return;
    dispatch(selectAnimalType(animalType));
    dispatch(selectAnimals([]));
    handleNext({
      step: step,
      id: formId,
      quoteType: animalType,
    });
  };
  return (
    <>
      {isAccount ? (
        <></>
      ) : (
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: 'absolute',
            zIndex: -1,
          }}
          className={'pricing_asset_class'}
        >
          <img
            alt="svg"
            src={pricing_asset_1}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      )}
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.animalSelection}
      >
        <Grid item xs={12} className={classes.title_container}>
          {isAccount ? (
            <Typography
              style={{ display: isAccount ? 'block' : 'none' }}
              color={'primary'}
              variant={'h4'}
              className={classes.title_text}
            >
              {t('title1', { firstName })}
              <span style={{ display: 'inline-block' }}>
                {t('title2')}
              </span>
            </Typography>
          ) : (
            <Typography
              style={{ display: isAccount ? 'none' : 'block' }}
              color={'primary'}
              variant={'h4'}
              className={classes.title_text}
            >
              {t('isAccount.title1')}
              <span style={{ display: 'inline-block' }}>
                {t('isAccount.title2', { firstName })}
              </span>
              <br />
              {t('isAccount.title3')}
              <span style={{ display: 'inline-block' }}>
                {t('isAccount.title4')}
              </span>
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginTop: { xs: '20px', sm: '30px' } }}
        >
          <SelectAnimals
            OnChange={selectAnimal}
            isLoading={isLoading}
            isAccount={isAccount}
            t={t}
          />
        </Grid>
      </Grid>
    </>
  );
}

AnimalSelection.componentName = 'stepLabels.companion';

export default AnimalSelection;
