import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';
import StripeModifier from '../admin/StripeModifier';

const useStyles = makeStyles((theme) => ({
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));

export default function Step({ data, handleNext }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.step0',
  });
  const classes = useStyles();

  return (
    <Box
      style={{
        width: '90%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        color={'primary'}
        variant={'h4'}
        className={classes.title_text}
      >
        {t('title1')}
        <br /> {t('title2')}
      </Typography>
      <div style={{ margin: 'auto' }}>
        <StripeModifier
          data={{
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
          }}
          iban_only={true}
          handleNext={handleNext}
        />
      </div>
    </Box>
  );
}
