import { App as CapacitorApp } from '@capacitor/app';
import { Device } from '@capacitor/device';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import logoAlcampo from '../../../static/alcampo/logo.svg';
import logoDrooky from '../../../static/logo.svg';
import { isAlcampo } from '../../../utils/language';
import { DrawerContent } from './Drawer';

const backgroundColor = '#f5f5f5';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 46,
    [theme.breakpoints.down('md')]: {
      minHeight: 46,
    },
  },
  drawerPaper: {
    width: (props) => props.drawerWidth,
    backgroundColor: 'white',
    boxSizing: 'border-box',
    '& > *': {
      color: theme.palette.primary.main,
    },
  },
}));

const SM_WIDTH = 310;
const LG_WIDTH = 330;
const XL_WIDTH = 350;

function ResponsiveDrawer(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.navBar',
  });
  const logo = isAlcampo() ? logoAlcampo : logoDrooky;
  const [drawerWidth, setDrawerWidth] = React.useState(
    window.innerWidth >= 1200
      ? XL_WIDTH
      : window.innerWidth >= 768
      ? LG_WIDTH
      : SM_WIDTH,
  );
  const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';
  const isProd = process.env.NODE_ENV === 'production';

  React.useEffect(() => {
    const handleResizeWindow = () => {
      if (window.innerWidth < 768 && drawerWidth !== SM_WIDTH)
        setDrawerWidth(SM_WIDTH);
      else if (
        window.innerWidth >= 768 &&
        window.innerWidth < 1200 &&
        drawerWidth !== LG_WIDTH
      )
        setDrawerWidth(LG_WIDTH);
      else if (window.innerWidth >= 1200 && drawerWidth !== XL_WIDTH)
        setDrawerWidth(XL_WIDTH);
    };
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [drawerWidth]);
  const classes = useStyles({ drawerWidth });
  const { children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    setMobileOpen(false);
  }, [location, setMobileOpen]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  React.useEffect(() => {
    if (!isMobileApp || !isProd) return;
    const fetchData = async () => {
      const deviceInfo = await Device.getInfo();
      const isAndroid = deviceInfo?.platform === 'android';
      if (isAndroid)
        CapacitorApp.addListener('backButton', () => {
          setMobileOpen(true);
        });
    };
    try {
      fetchData();
    } catch (e) {
      console.log('error = ', e);
    }
  }, [isProd, isMobileApp, setMobileOpen]);
  return (
    <Box
      sx={{ display: 'flex' }}
      style={{
        backgroundColor: backgroundColor,
        height: '100%',
        minHeight: '100vh',
        minWidth: '100vw',
        width: '100%',
      }}
    >
      <CssBaseline />
      <AppBar
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          display: { md: 'none' },
        }}
      >
        {isMobileApp ? (
          <div
            style={{
              height: 'env(safe-area-inset-top)',
              backgroundColor: backgroundColor,
            }}
          ></div>
        ) : null}
        <Toolbar
          className={classes.toolbar}
          style={{ backgroundColor: backgroundColor }}
        >
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              alignItems: 'center',
              height: '35px',
              textDecoration: 'none',
            }}
            component={RouterLink}
            to="/account/"
          >
            <img
              style={{ height: '100%' }}
              src={logo}
              alt={'Accueil'}
            />
            {!isAlcampo() && (
              <Typography
                color="primary"
                variant="h5"
                style={{ fontSize: '22px', marginLeft: '10px' }}
              >
                {t('companyName')}
              </Typography>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

        {/*////////////MOBILE///////////*/}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
          classes={{ paper: classes.drawerPaper }}
        >
          <DrawerContent disabled={props.disabled} />
        </Drawer>
        {/*///////////DESKTOP/////////////*/}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
          classes={{ paper: classes.drawerPaper }}
          open
        >
          <DrawerContent disabled={props.disabled} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 0, md: 1, lg: 2 },
        }}
      >
        <Toolbar
          sx={{
            minHeight: '46px !important',
            height: isMobileApp
              ? 'calc(46px + env(safe-area-inset-top))'
              : 'unset',
            display: { xs: 'block', md: 'none' },
          }}
          disabled={props.disabled}
        />
        {children}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
