import { Box, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'use-hooks';

import Footer from '../components/templates/home/Footer';
import Navbar from '../components/templates/home/Navbar';
import { blue_light } from '../theme';
import theme from '../theme/home';
import { isAlcampo } from '../utils/language';
import { makeStaticPageStyles } from './styles';

const refSize = 1920;
const minZoomMobile = 400;
const minZoomDesktop = 1000;

const useStyles = makeStyles((theme) => makeStaticPageStyles(theme));

const SectionTitle = ({ title }) => (
  <Typography
    variant="h3"
    sx={{ marginTop: { xs: '50px', md: '100px' } }}
  >
    {title}
  </Typography>
);

const TextBlock = ({ children, className }) => (
  <Typography
    className={className}
    style={{ marginTop: 30 }}
    sx={{ whiteSpace: 'pre-line' }}
  >
    {children}
  </Typography>
);

const TableBlock = ({ data, className }) => (
  <Table
    style={{ border: '1px solid ' + theme.palette.primary.main }}
  >
    <TableBody>
      {data.map((row, index) => (
        <TableRow key={index}>
          {row.map((cell, cellIndex) => (
            <TableCell
              key={cellIndex}
              style={{
                border: '1px solid ' + theme.palette.primary.main,
              }}
            >
              <Typography className={className}>{cell}</Typography>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default function PrivacyPolicyLayout(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'layouts.privacyPolicy',
  });
  const { width } = useWindowSize();
  const zoom =
    width < minZoomDesktop
      ? 1 + (width - minZoomMobile) / 900
      : width / refSize;
  const classes = useStyles({ zoom });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tableData = [
    [
      t('step3.tableCell.purpose.title'),
      t('step3.tableCell.legalBasis.title'),
      t('step3.tableCell.duration.title'),
    ],
    [
      t('step3.tableCell.purpose.text1'),
      t('step3.tableCell.legalBasis.text1'),
      t('step3.tableCell.duration.text1'),
    ],
    [
      t('step3.tableCell.purpose.text2'),
      t('step3.tableCell.legalBasis.text2'),
      t('step3.tableCell.duration.text2'),
    ],
    [
      t('step3.tableCell.purpose.text3'),
      t('step3.tableCell.legalBasis.text3'),
      t('step3.tableCell.duration.text3'),
    ],
    [
      t('step3.tableCell.purpose.text4'),
      t('step3.tableCell.legalBasis.text4'),
      t('step3.tableCell.duration.text4'),
    ],
  ];

  return (
    <ThemeProvider theme={theme}>
      <Navbar {...props} />
      <Box className={classes.zoomHome}>
        <section
          style={{
            background: blue_light,
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              paddingTop: '40px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography color="primary" variant="h2">
              {t('title')}
            </Typography>
          </div>
          <section className={classes.htmlContent}>
            <TextBlock className={classes.typo}>
              {isAlcampo() ? t('alcampo.intro1') : t('intro1')}
              <br />
              <br />
              {isAlcampo() ? t('alcampo.intro2') : t('intro2')}
              <br />
              <br />
              {t('intro3')}
            </TextBlock>
            <SectionTitle title={t('step1.title')} />
            <TextBlock className={classes.typo}>
              {isAlcampo()
                ? t('alcampo.step1.text1')
                : t('step1.text1')}
            </TextBlock>
            <SectionTitle title={t('step2.title')} />
            <TextBlock className={classes.typo}>
              {t('step2.text1')}
              <br />
              <br />
              {t('step2.text2')}
              <br />
              <br />
              <div style={{ marginLeft: '50px' }}>
                <li>{t('step2.text3')}</li>
                <br />
                <li>{t('step2.text4')}</li>
              </div>
              <br />
              {t('step2.text5')}
            </TextBlock>
            <SectionTitle title={t('step3.title')} />
            <TextBlock className={classes.typo}>
              <TableBlock data={tableData} className={classes.typo} />
            </TextBlock>
            <SectionTitle title={t('step4.title')} />
            <TextBlock className={classes.typo}>
              {t('step4.text')}
            </TextBlock>
            <SectionTitle title={t('step5.title')} />
            <TextBlock className={classes.typo}>
              {t('step5.text')}
              <br />
              <br />
              <div style={{ marginLeft: '50px' }}>
                <li>{t('step5.subText1')}</li>
                <br />
                <li>{t('step5.subText2')}</li>
                <br />
                <li>{t('step5.subText3')}</li>
              </div>
            </TextBlock>
            <SectionTitle title={t('step6.title')} />
            <TextBlock className={classes.typo}>
              {t('step6.text')}
            </TextBlock>
            <SectionTitle title={t('step7.title')} />
            <TextBlock className={classes.typo}>
              {t('step7.text')}
            </TextBlock>
            <SectionTitle title={t('step8.title')} />
            <TextBlock className={classes.typo}>
              {isAlcampo()
                ? t('alcampo.step8.text')
                : t('step8.text')}
            </TextBlock>
            <SectionTitle title={t('step9.title')} />
            <TextBlock className={classes.typo}>
              {t('step9.text')}
            </TextBlock>
          </section>
          <div style={{ width: '100%', paddingBottom: '50px' }}>
            <Footer />
          </div>
        </section>
      </Box>
    </ThemeProvider>
  );
}
