import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { blue } from '../../../../theme';
import Emoji from '../../../atoms/Emoji';
import { getNextPayments } from './garantiesSlice';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '360px',
    width: '65%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
      minWidth: '310px',
    },
    [theme.breakpoints.down(450)]: {
      width: '95%',
      minWidth: '310px',
    },
    margin: '25px auto',
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'default',
  },
  rowHead: {
    background: theme.palette.primary.main,
    '& th, & th *, & th *:hover, & th *:focus, & th *:active': {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  rowCollapse: {
    '& td, & th': {
      padding: '0 5px',
    },
    '& .MuiBox-root': {
      margin: 0,
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
  },
  rowCollapse_2: {
    '& td, & th': {
      padding: 0,
    },
  },
  row: {
    '& th': {
      padding: '0 10px',
    },
    '& td': {
      padding: '0 5px',
    },
  },
  title: {
    fontFamily: 'Supply',
    color: blue,
    fontSize: 'clamp(1.8rem, 0.74rem + 1.88vw, 3rem)',
    marginLeft: '50px',
  },
}));

function Row(props) {
  const classes = useStyles();

  const { row } = props;

  return (
    <React.Fragment>
      <TableRow
        style={{
          backgroundColor: props.open ? 'hsl(198, 31%, 91%)' : '',
        }}
        onClick={() =>
          props.large &&
          row?.details?.length !== 0 &&
          props.handleClick()
        }
        sx={{ '& > *': { borderBottom: 'unset' } }}
        className={classes.row}
      >
        {props.large && row?.details?.length !== 0 ? (
          <TableCell style={{ padding: 0, width: 'fit-content' }}>
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{
                paddingLeft: { xs: '5px', lg: '15px !important' },
                paddingRight: { xs: 0, lg: '10px' },
              }}
            >
              {props.open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        ) : (
          props.large && <TableCell />
        )}
        <TableCell component="th" scope="row">
          {props.open} {row.date}
        </TableCell>
        <TableCell align="right">
          {isNaN(row.cost)
            ? row.cost
            : (Number.isInteger(row.cost)
                ? row.cost
                : row.cost.toFixed(2).replace('.', ',')) + '€'}
        </TableCell>
      </TableRow>
      {props.large && (
        <TableRow className={classes.rowCollapse}>
          <TableCell />
          <TableCell colSpan={2}>
            <Collapse in={props.open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="details">
                  <TableBody>
                    {row?.details?.map((historyRow, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{ wordBreak: 'break-all' }}
                          component="th"
                          scope="row"
                          colSpan={2}
                        >
                          {historyRow.health ? (
                            <Emoji symbol="👩‍⚕️" />
                          ) : (
                            <Emoji symbol="🧚‍" />
                          )}{' '}
                          {historyRow.name}
                        </TableCell>
                        <TableCell align="right">
                          {isNaN(historyRow.cost)
                            ? historyRow.cost
                            : (Number.isInteger(historyRow.cost)
                                ? historyRow.cost
                                : historyRow.cost
                                    .toFixed(2)
                                    .replace('.', ',')) + '€'}{' '}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default function PaymentSchedule(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.guarantees.paymentSchedule',
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const { status, next_payment } = useSelector(
    (state) => state.garanties,
  );

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 0;
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const [open, setOpen] = React.useState();

  function handleClick(id) {
    setOpen((prevState) => !prevState);
  }

  React.useEffect(() => {
    if (!props.schedule) dispatch(getNextPayments());
  }, [dispatch, props.schedule]);

  if (props.schedule) {
    return (
      <TableContainer>
        <Table
          size="small"
          aria-label="a dense table"
          className={classes.table}
        >
          <TableHead className={classes.rowHead}>
            <TableRow>
              {width >= breakpoint && (
                <TableCell style={{ width: 15, padding: 0 }} />
              )}
              <TableCell>{t('nextPayment')}</TableCell>
              <TableCell align="right">{t('amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.schedule.map((item, index) => (
              <Row
                key={index}
                row={item}
                large={width >= breakpoint}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  if (status === 'succeeded' && next_payment) {
    return (
      <TableContainer>
        <Table
          size="small"
          aria-label="a dense table"
          className={classes.table}
        >
          <TableHead className={classes.rowHead}>
            <TableRow>
              {width >= breakpoint && (
                <TableCell style={{ width: 15, padding: 0 }} />
              )}
              <TableCell>{t('nextPayment')}</TableCell>
              <TableCell align="right">{t('amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Row
              row={next_payment}
              large={width >= breakpoint}
              handleClick={handleClick}
              open={open}
            />
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  if (status === 'failed') {
    return 'error';
  }

  return <CircularProgress sx={{ margin: 'auto' }} />;
}
