import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import HelloSign from 'hellosign-embedded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';

import chien_grue from '../../../static/anims/horloge.json';
import {
  loadStripeOney,
  loadStripeWakam,
} from '../../../utils/stripeLoader';
import Widget from '../../atoms/Widget';
import axiosAPI from '../../axiosApi';
import Modal from '../../molecules/Modal';
import CreditCardElement from './CreditCardElement';
import { reset, updateQuote, validatePayment } from './signingSlice';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`payment-tabpanel-${index}`}
      aria-labelledby={`payment-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
            paddingLeft: { xs: 1, sm: 3 },
            paddingRight: { xs: 1, sm: 3 },
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function StripeProvider({
  affiliate,
  quoteID,
  price,
  rates,
  onlyPayment,
  setCloseable = () => {},
  guard = false,
  resiliation = false,
  provider = process.env.REACT_APP_PROVIDER,
  onComplete = undefined,
}) {
  const [stripeOney, setStripeOney] = React.useState(null);
  const [stripeWakam, setStripeWakam] = React.useState(null);
  React.useEffect(() => {
    const fetchOney = async () => await loadStripeOney();
    const fetchWakam = async () => await loadStripeWakam();
    const oneyStripeProvider = fetchOney().catch(console.error);
    const wakamStripeProvider = fetchWakam().catch(console.error);
    setStripeOney(oneyStripeProvider);
    setStripeWakam(wakamStripeProvider);
  }, []);
  const dispatch = useDispatch();
  const { quoteId } = useSelector((state) => state.pricing);
  const { status_docs, contract } = useSelector(
    (state) => state.signing,
  );
  const { t } = useTranslation(null, {
    keyPrefix: 'quote',
  });

  const [paymentStatus, setPaymentStatus] = React.useState();
  const [signStatus, setSignStatus] = React.useState();
  const [timer, setTimer] = React.useState(3);

  const optionOrGuard =
    guard || rates?.option === 'option' || resiliation;

  const timeout = React.useRef();

  const hs = React.useRef();
  const hsNode = React.useRef(null);
  const [openHS, setOpenHS] = React.useState(false);
  const closeHS = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenHS(false);
  };
  React.useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  React.useEffect(() => {
    dispatch(reset());
    if (onlyPayment && quoteId)
      dispatch(updateQuote({ id: quoteId }));
    else if (typeof onlyPayment === 'undefined')
      dispatch(
        updateQuote({
          id: quoteId,
          rates: rates,
        }),
      );
  }, [dispatch, quoteId, rates, onlyPayment]);

  React.useEffect(() => {
    if (
      paymentStatus === 'succeeded' &&
      status_docs === 'succeeded' &&
      contract === 'succeeded' &&
      !signStatus
    ) {
      if (!optionOrGuard) {
        setTimer(5);
        timeout.current = setTimeout(() => {
          window.location.href = '/login';
        }, 4500);
      } else {
        try {
          axiosAPI
            .post('signingQuote/', quoteId)
            .then((response) => {
              setOpenHS(true);
              hs.current = new HelloSign(
                process.env.REACT_APP_HELLO_SIGN_PROD === 'true'
                  ? {
                      clientId:
                        process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                    }
                  : {
                      clientId:
                        process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                      skipDomainVerification: true,
                    },
              );
              hs.current.on('cancel', (data) => {
                setSignStatus('closed');
                setOpenHS(false);
                setTimer(5);
                timeout.current = setTimeout(() => {
                  window.location.href = '/login';
                }, 4500);
              });

              hs.current.on('error', (data) => {
                setSignStatus('error');
                setOpenHS(false);
                setTimer(5);
                timeout.current = setTimeout(() => {
                  window.location.href = '/login';
                }, 4500);
              });

              hs.current.on('sign', (data) => {
                setSignStatus('signed');
                setOpenHS(false);
                setTimer(5);
                timeout.current = setTimeout(() => {
                  window.location.href = '/login';
                }, 4500);
              });
              hs.current.open(response.data);
              setCloseable(true);
            })
            .catch((error) => {
              throw new Error(t('stripeProvider.errorSignQuote'));
            });
        } catch {
          setSignStatus('closed');
          setTimer(5);
          setOpenHS(false);
          timeout.current = setTimeout(() => {
            window.location.href = '/login';
          }, 4500);
        }
      }
    }

    if (onComplete !== undefined && paymentStatus === 'succeeded') {
      setTimeout(() => {
        onComplete();
      }, 2000);
    }
    return () => clearTimeout(timeout.current);
  }, [
    paymentStatus,
    status_docs,
    quoteId,
    dispatch,
    timeout,
    signStatus,
    contract,
    setCloseable,
    optionOrGuard,
    onComplete,
    t,
  ]);

  React.useEffect(() => {
    if (paymentStatus) {
      switch (paymentStatus) {
        case 'succeeded':
          if (typeof onlyPayment === 'undefined' || !onlyPayment)
            dispatch(validatePayment(quoteId));
          if (
            typeof window.gtag !== 'undefined' &&
            process.env.NODE_ENV !== 'development'
          ) {
            window.gtag('event', 'conversion', {
              send_to: process.env.REACT_APP_GTAG_ID,
              currency: 'EUR',
              value: price,
              transaction_id: quoteId.toString(),
            });
          }
          if (
            typeof window.uetq !== 'undefined' &&
            process.env.NODE_ENV !== 'development'
          ) {
            window.uetq.push('event', 'purchase', {});
          }
          break;
        case 'processing':
          break;
        case 'requires_payment_method':
          break;
        case 'timeout':
          break;
        case 'card_error':
          break;
        default:
          break;
      }
    }
  }, [paymentStatus, quoteId, dispatch, onlyPayment, price]);

  let content;

  switch (signStatus) {
    case 'signed':
      content = (
        <>
          <CheckCircleOutlineIcon
            color="success"
            style={{ fontSize: '20rem', margin: '0 auto' }}
          />
          <Typography sx={{ mt: 2, mb: 1, fontSize: '18px' }}>
            {t('stripeProvider.signStatus.signed.text1')} <br />
            {t('stripeProvider.signStatus.signed.text2')} <br />
            {t('stripeProvider.signStatus.signed.text3')} <br />
            {t('stripeProvider.signStatus.signed.text4', { timer })}
          </Typography>
        </>
      );
      break;
    case 'closed':
      content = (
        <>
          <CheckCircleOutlineIcon
            color="success"
            style={{ fontSize: '20rem', margin: '0 auto' }}
          />
          <Typography sx={{ mt: 2, mb: 1, fontSize: '18px' }}>
            {t('stripeProvider.signStatus.closed.text1')} <br />
            {t('stripeProvider.signStatus.closed.text2')} <br />
            {t('stripeProvider.signStatus.closed.text3')} <br />
            {t('stripeProvider.signStatus.closed.text4', { timer })}
          </Typography>
        </>
      );
      break;
    case 'error':
      content = (
        <>
          <CheckCircleOutlineIcon
            color="success"
            style={{ fontSize: '20rem', margin: '0 auto' }}
          />
          <Typography sx={{ mt: 2, mb: 1, fontSize: '18px' }}>
            {t('stripeProvider.signStatus.error.text1')} <br />
            {t('stripeProvider.signStatus.error.text2')}
            <br />
            {t('stripeProvider.signStatus.error.text3')}
            <br />
            {t('stripeProvider.signStatus.error.text4')} <br />
            {t('stripeProvider.signStatus.error.text5')} <br />
            {t('stripeProvider.signStatus.error.text6', { timer })}
          </Typography>
        </>
      );
      break;
    default:
      if (status_docs === 'loading') {
        content = (
          <Stack sx={{ margin: 'auto', height: '50%' }}>
            <Lottie
              animationData={chien_grue}
              play={true}
              loop={true}
              style={{
                position: 'relative',
                margin: '0 auto',
                padding: 0,
              }}
            />
            <Typography
              sx={{
                mt: 2,
                mb: 1,
                fontSize: '22px',
                maxWidth: '400px',
                margin: 'auto',
              }}
            >
              {t('stripeProvider.signStatus.loading.text1')}
            </Typography>
          </Stack>
        );
      } else if (status_docs === 'failed') {
        content = (
          <>
            <ErrorOutlineIcon
              color="error"
              style={{ fontSize: '20rem', margin: 'auto' }}
            />
            <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
              {t('stripeProvider.signStatus.failed.text1')}
              <br />
              {t(
                'stripeProvider.signStatus.failed.text2',
              )} <br /> <br />
              {t(
                'stripeProvider.signStatus.failed.text3',
              )} <br /> {t('stripeProvider.signStatus.failed.text4')}
            </Typography>
          </>
        );
      } else if (status_docs === 'already') {
        content = (
          <>
            <ErrorOutlineIcon
              color="error"
              style={{ fontSize: '20rem', margin: '0 auto' }}
            />
            <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
              {t('stripeProvider.signStatus.already.text1')}
              <br />
              {t('stripeProvider.signStatus.already.text2')} <br />
              {t('stripeProvider.signStatus.already.text3')}
            </Typography>
          </>
        );
      } else if (
        status_docs === 'succeeded' &&
        paymentStatus === 'card_error'
      ) {
        content = (
          <>
            <ErrorOutlineIcon
              color="error"
              style={{ fontSize: '20rem', margin: '0 auto' }}
            />
            <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
              {t(
                'stripeProvider.signStatus.succeededAndCardError.text1',
              )}{' '}
              <br />
              {t(
                'stripeProvider.signStatus.succeededAndCardError.text2',
              )}
            </Typography>
          </>
        );
      } else if (
        status_docs === 'succeeded' &&
        paymentStatus === 'succeeded' &&
        onlyPayment
      ) {
        setCloseable(true);
        content = (
          <>
            <CheckCircleOutlineIcon
              color="success"
              style={{ fontSize: '10rem', margin: '0 auto' }}
            />
            <Typography sx={{ mt: 2, mb: 1 }}>
              Paiement validé !
            </Typography>
          </>
        );
      } else if (
        status_docs === 'succeeded' &&
        paymentStatus === 'succeeded' &&
        contract === 'loading'
      ) {
        setCloseable(false);
        content = (
          <>
            <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
              {t(
                'stripeProvider.signStatus.succeededAndOnlyPaiement.text1',
              )}
            </Typography>
            <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
              {t(
                'stripeProvider.signStatus.succeededAndOnlyPaiement.text2',
              )}{' '}
              <br />
              {t(
                'stripeProvider.signStatus.succeededAndOnlyPaiement.text3',
              )}
            </Typography>
            <Lottie
              animationData={chien_grue}
              play={true}
              loop={true}
              style={{
                position: 'relative',
                margin: '0 auto',
                padding: 0,
              }}
            />
          </>
        );
      } else if (
        status_docs === 'succeeded' &&
        paymentStatus === 'succeeded' &&
        contract === 'succeeded'
      ) {
        if (!optionOrGuard) {
          content = (
            <>
              <CheckCircleOutlineIcon
                color="success"
                style={{ fontSize: '20rem', margin: '0 auto' }}
              />
              <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
                {t(
                  'stripeProvider.signStatus.succeededAndContrat.text1',
                )}
                <br />
                {t(
                  'stripeProvider.signStatus.succeededAndContrat.text2',
                )}
                <br />
                {t(
                  'stripeProvider.signStatus.succeededAndContrat.text3',
                )}{' '}
                <br />
                {t(
                  'stripeProvider.signStatus.succeededAndContrat.text4',
                  { timer },
                )}
              </Typography>
            </>
          );
        } else {
          content = (
            <>
              <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
                {t('stripeProvider.signStatus.dontCloseWindow')}
              </Typography>
              <Lottie
                animationData={chien_grue}
                play={true}
                loop={true}
                style={{
                  position: 'relative',
                  margin: '0 auto',
                  padding: 0,
                }}
              />
              <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
                {t('stripeProvider.signStatus.signInProgress')}
              </Typography>
            </>
          );
        }
      } else if (
        status_docs === 'succeeded' &&
        paymentStatus === 'succeeded' &&
        contract === 'failed'
      ) {
        content = (
          <>
            <ErrorOutlineIcon
              color="error"
              style={{ fontSize: '20rem', margin: '0 auto' }}
            />
            <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
              {t(
                'stripeProvider.signStatus.succeededAndNotContrat.text1',
              )}{' '}
              <br />
              {t(
                'stripeProvider.signStatus.succeededAndNotContrat.text2',
              )}{' '}
              <br />
              {t(
                'stripeProvider.signStatus.succeededAndNotContrat.text3',
              )}{' '}
              <br /> <br />
              {t(
                'stripeProvider.signStatus.succeededAndNotContrat.text4',
              )}
              <br />{' '}
              {t(
                'stripeProvider.signStatus.succeededAndNotContrat.text5',
              )}
            </Typography>
          </>
        );
      } else if (status_docs === 'succeeded') {
        content = (
          <>
            <Box
              sx={{
                padding: 0,
                borderBottom: 1,
                borderColor: 'divider',
                textAlign: 'center',
              }}
            >
              <CardHeader
                title={t('stripeProvider.succeeded.title')}
              />
            </Box>
            <CardContent
              sx={{ margin: 'auto', width: '100%', p: 0, m: 0 }}
            >
              <Elements
                style={{ padding: 0 }}
                stripe={
                  provider === 'wakam' ? stripeWakam : stripeOney
                }
              >
                <TabPanel
                  value={0}
                  index={0}
                  style={{
                    maxWidth: '500px',
                    margin: 'auto',
                    padding: 0,
                  }}
                >
                  <CreditCardElement
                    affiliate={affiliate}
                    quoteID={quoteID}
                    price={price}
                    setter={setPaymentStatus}
                    id={quoteId}
                    onlyPayment={onlyPayment}
                    setCloseable={setCloseable}
                  />
                </TabPanel>
              </Elements>
            </CardContent>
          </>
        );
      } else {
        content = (
          <>
            <ErrorOutlineIcon
              color="error"
              style={{ fontSize: '20rem', margin: '0 auto' }}
            />
            <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
              {t('stripeProvider.signStatus.unknowError1')}
              <br />
              {t('stripeProvider.signStatus.unknowError2')}
              <br /> <br />
              {t('stripeProvider.signStatus.unknowError3')}
              <br /> {t('stripeProvider.signStatus.unknowError4')}
            </Typography>
          </>
        );
      }
  }
  return (
    <Widget color="white" modal>
      <Modal
        open={openHS}
        onClose={closeHS}
        closeAfterTransition
        hellosign
        sx={{ overflow: { xs: 'none', md: 'scroll' } }}
      >
        <div
          style={{ height: '90%', width: '800px', margin: 'auto' }}
          ref={hsNode}
        ></div>
      </Modal>
      {content}
    </Widget>
  );
}

StripeProvider.componentName = 'stepLabels.payment';

export default StripeProvider;
