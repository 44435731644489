import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';

const useStyles = makeStyles((theme) => ({
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));

export default function Step(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.step5',
  });
  const classes = useStyles();

  const [context, setContext] = props.setContext;

  const handleChange = (event) => {
    setContext(event.target.value);
  };

  return (
    <Box
      style={{
        width: '90%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        color={'primary'}
        variant={'h4'}
        className={classes.title_text}
      >
        {!props.isQuote ? (
          <>{t('isNotQuote.title1')}</>
        ) : (
          <>
            {t('isQuote.title1')} <br />
            {t('isQuote.title2')}
          </>
        )}
      </Typography>

      <TextField
        id="context"
        multiline
        minRows={4}
        onChange={handleChange}
        value={context}
        placeholder={t('placeholderContext')}
        InputLabelProps={{
          shrink: true,
          height: '100%',
        }}
        inputProps={{ maxLength: 200 }}
        helperText={context.length + t('helperTextContext')}
        sx={{
          display: 'flex',
          width: { xs: '90%', sm: '80%' },
          height: { xs: '90%', sm: '80%' },
          margin: 'auto',
          marginTop: '20px',
        }}
      />
    </Box>
  );
}
