import { Fab, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  marginFab: {
    width: (props) => (props.size ? props.size : 140),
    height: (props) => (props.size ? props.size : 140),
    borderRadius: 0,
    color: (props) => (props.disabled ? 'red' : 'inherit'),
    cursor: (props) => (!props.disabled ? 'pointer' : 'default'),
    // backgroundColor: (props) => (props.disabled ? 'red' : theme.palette.primary.main),
    // backgroundColor: 'red',
    /*'&:hover': {
      backgroundColor: (props) =>
        props.disabled ? '#014c7e' : theme.palette.primary.main,
    },*/
    clipPath:
      'polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)',
  },
  perMonth: {
    fontSize: (props) =>
      props.fontSize
        ? props.fontSize
        : props.oldPrice
        ? '1.1em'
        : '1.2em',
    textTransform: 'none',
  },
  strikethrough: {
    '&::before': {
      top: '30%',
      left: '25%',
      width: '50%',
      height: '5px',
      content: '""',
      display: 'block',
      opacity: '0.7',
      position: 'absolute',
      transform: 'rotate(-10deg) translateY(-50%)',
      background: 'red',
    },
  },
}));

function FabPrice(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'atoms.fabPrice',
  });
  const classes = useStyles(props);
  const { price, oldPrice } = props;
  return (
    <Fab
      color="primary"
      size={props.size ? props.size : 'large'}
      aria-label="add"
      className={`${props.className} ${classes.marginFab}`}
    >
      <div>
        <Typography color="secondary" className={classes.perMonth}>
          {oldPrice ? (
            <div>
              <span className={classes.strikethrough}>
                {oldPrice}
                {props.symbol}
              </span>
            </div>
          ) : (
            ''
          )}
          {props.symbol === '€'
            ? Number.isInteger(price)
              ? price.toString()
              : price?.toFixed(2).replace('.', ',')
            : price}
          {props.symbol}
        </Typography>
        {props.an ? (
          <Typography color="secondary" className={classes.perMonth}>
            {props.an === 'Annuel' ? t('perYear') : t('perMonth')}
          </Typography>
        ) : (
          ''
        )}
      </div>
    </Fab>
  );
}

export default FabPrice;
