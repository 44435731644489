import { Box, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useWindowSize } from 'use-hooks';

import '../../../App.css';
import notFoundLeft from '../../../static/drooky-notFound-left.svg';
import notFoundRight from '../../../static/drooky-notFound-right.svg';
import home_level_0_transition from '../../../static/home/home-0-transition.png';
import { blue_light, breakpoints } from '../../../theme';
import Button from '../../atoms/Button';

const refSize = 1500;

const useStyles = makeStyles((theme) => {
  return {
    headerContainer: {
      paddingTop: 160,
      [theme.breakpoints.down('md')]: {
        float: 'right',
        paddingTop: 120,
      },
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.between(0, 'xl')]: {
        zoom: (props) => props.zoom,
        '@supports ( -moz-appearance:none )': {
          '-moz-transform': (props) => `scale(${props.zoom})`,
          '-moz-transform-origin': '0 0',
          width: (props) => `${100 * (1 / props.zoom)}vw`,
        },
      },
    },
    textContent: {
      marginBottom: '200px',
      marginTop: '20px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '150px',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '100px',
      },
    },
    svgImgLeft: {
      height: 300,
      [theme.breakpoints.down('md')]: {
        height: 'unset',
        width: '90%',
      },
    },
    svgImgRight: {
      height: 300,
      marginLeft: 150,
      marginTop: -200,
    },
    text_1: {
      marginBottom: 40,
      maxWidth: 930,
      marginLeft: 30,
      marginRight: 30,
      fontSize: '3rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '2.4rem',
        maxWidth: 800,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.7rem',
        marginLeft: 20,
        marginRight: 20,
      },
    },
  };
});

function NotFoundPage() {
  const { t } = useTranslation(null, {
    keyPrefix: 'notFound',
  });
  const { width } = useWindowSize();
  const zoom =
    width < breakpoints.values.md
      ? 1 + (width - breakpoints.values.md) / 2500
      : width / refSize;
  const classes = useStyles({ zoom });

  return (
    <Box className={classes.headerContainer}>
      <Grid
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.textContent}
      >
        <Typography
          color="primary"
          variant="h2"
          className={classes.text_1}
        >
          {t('title1')}
          <span style={{ display: 'inline-block' }}>
            {t('title2')}
          </span>
        </Typography>

        <div style={{ zIndex: 100 }}>
          <Link component={RouterLink} to="/">
            <Button>
              <Typography
                variant={'h5'}
                color={'secondary'}
                sx={{
                  fontSize: { xs: '18px', md: '30px' },
                  textTransform: 'lowercase',
                }}
              >
                {t('prevButton')}
              </Typography>
            </Button>
          </Link>
        </div>
      </Grid>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          style={{ zIndex: 100 }}
          sx={{ width: { xs: '100%', md: 'unset' } }}
        >
          <img
            className={classes.svgImgLeft}
            src={notFoundLeft}
            alt="notFound-left"
          />
        </Box>
        <Box
          style={{ zIndex: 100 }}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <img
            className={classes.svgImgRight}
            src={notFoundRight}
            alt="notFound-right"
          />
        </Box>
      </Grid>
      <Box
        style={{ top: '20px' }}
        className={'home-img-transition-wrapper'}
        sx={{
          height: {
            xs: '630px',
            sm: '750px',
            md: '900px',
            lg: '930px',
          },
        }}
      >
        <img
          src={home_level_0_transition}
          alt="transition"
          style={{ height: '100%', width: '100%' }}
        />
      </Box>
      <div style={{ height: '100%', backgroundColor: blue_light }} />
    </Box>
  );
}

export default NotFoundPage;
