import { datadogRum } from '@datadog/browser-rum';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  getAppName,
  getErrorTranslation,
  isAlcampo,
} from '../../../utils/language';
import axiosAPI from '../../axiosApi';

const initialState = {
  username: '',
  email: '',
  isFetching: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  account: null,
};

export const getAccount = createAsyncThunk(
  'account/',
  async (a, thunkAPI) => {
    try {
      const response = await axiosAPI.get('account/');
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(
          getErrorTranslation(response?.data),
        );
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(
        getErrorTranslation(e?.response?.data),
      );
    }
  },
);

export const preLoginUser = createAsyncThunk(
  'users/preLogin',
  async ({ email }, thunkAPI) => {
    try {
      const response = await axiosAPI.post('preLogin/', {
        email,
      });
      let data = response.data;
      if (response.status === 200) {
        return email;
      } else {
        return thunkAPI.rejectWithValue(getErrorTranslation(data));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(
        getErrorTranslation(e?.response?.data, {
          app: getAppName(),
          domainPrefix: isAlcampo() ? 'es' : 'com',
        }),
      );
    }
  },
);

export const loginUser = createAsyncThunk(
  'users/login',
  async ({ email, code, changeLanguage }, thunkAPI) => {
    try {
      const response = await axiosAPI.post('login/', {
        email,
        code,
      });
      let data = response.data;
      if (response.status === 200) {
        axiosAPI.defaults.headers['Authorization'] =
          'JWT ' + data.token;
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
        changeLanguage(data?.language);
        if (
          process.env.NODE_ENV === 'production' &&
          process.env.REACT_APP_ENVIRONMENT === 'production'
        ) {
          datadogRum.setUser({
            id: data?.id,
            name: data?.email,
            email: data?.email,
          });
        }
        return data;
      } else {
        return thunkAPI.rejectWithValue(getErrorTranslation(data));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(
        getErrorTranslation(e?.response?.data),
      );
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    setErrorMessage: (state) => {
      state.errorMessage = '';
    },
  },
  extraReducers: {
    [preLoginUser.fulfilled]: (state, { payload }) => {
      state.email = payload;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
    [preLoginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [preLoginUser.pending]: (state) => {
      state.isFetching = true;
    },
    ///////////////////////////////////////////////////////
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = '';
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [getAccount.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = '';
      state.isError = false;
      state.account = payload;
      return state;
    },
    [getAccount.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getAccount.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState, setErrorMessage } = userSlice.actions;

export const userSelector = (state) => state.user;

export default userSlice.reducer;
