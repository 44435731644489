import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageContext } from './index';

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(
    ['fr', 'es'].includes(i18n.language)
      ? i18n.language
      : process.env.REACT_APP_LANGUAGE,
  );

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
