import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import prevention from '../../../../static/prevention.svg';
import prevoyance from '../../../../static/prevoyance.svg';
import pricing_asset_mobile_2 from '../../../../static/pricing/pricing-asset-mobile-2.svg';
import {
  GUARD_TYPE_PREVENTION,
  GUARD_TYPE_PREVOYANCE,
} from '../../pricing/constants';
import { selectPreventionOrInsurance } from '../../pricing/pricingSlice';

const useStyles = makeStyles((theme) => ({
  animalSelection: {
    width: '100%',
    paddingRight: 10,
    paddingLeft: 10,
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  mobile_image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    justifyContent: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  img: {
    height: '100px',
    [theme.breakpoints.down('sm')]: {
      height: '75px',
    },
  },
  container: {
    margin: '20px',
    [theme.breakpoints.down('md')]: {
      margin: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '15px',
    },
  },
  typo: {
    color: theme.palette.primary.main,
    fontSize: '1.7rem',
    marginTop: '6px',
    maxWidth: 260,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 210,
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 180,
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
      fontSize: '1.1rem',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: '#C0D8E2',
    },
  },
  marginButton: {
    marginBottom: 30,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
    },
  },
}));
function SelectQuoteType({ handleNext, contract }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.selectQuoteType',
  });
  const firstName =
    useSelector((state) => state.pricing.firstName) || '';
  const classes = useStyles();
  const dispatch = useDispatch();
  const goNextStep = (quoteType) => {
    dispatch(selectPreventionOrInsurance(quoteType));
    handleNext({
      quoteType,
    });
  };

  const availableGuarantees = contract?.availableGuarantees || [];
  const guaranteeGardAvailable =
    contract && availableGuarantees.includes('guard');

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.animalSelection}
    >
      <Grid item xs={12} className={classes.title_container}>
        <Typography
          color={'primary'}
          variant={'h4'}
          className={classes.title_text}
        >
          {t('title', { firstName })}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ marginTop: { xs: '20px', sm: '30px' } }}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            spacing={4}
            alignItems="stretch"
            className={classes.marginButton}
          >
            {guaranteeGardAvailable && (
              <Grid
                item
                xs={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <div className={classes.container}>
                  <Button
                    className={classes.button}
                    onClick={() => goNextStep(GUARD_TYPE_PREVOYANCE)}
                  >
                    <img
                      src={prevoyance}
                      alt={'prevoyance'}
                      className={classes.img}
                    />
                  </Button>
                  <Typography variant={'h4'} className={classes.typo}>
                    {t('prevoyanceButton')}
                  </Typography>
                </div>
              </Grid>
            )}
            <Grid
              item
              xs={guaranteeGardAvailable ? 6 : 12}
              // sx={{ paddingLeft: { xs: "30px", sm: "80px"}}}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.container}>
                <Button
                  className={classes.button}
                  onClick={() => goNextStep(GUARD_TYPE_PREVENTION)}
                >
                  <img
                    src={prevention}
                    alt={'prevention'}
                    className={classes.img}
                  />
                </Button>
                <Typography variant={'h4'} className={classes.typo}>
                  {t('preventionButton')}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SelectQuoteType.componentName = 'stepLabels.type';

export default SelectQuoteType;
