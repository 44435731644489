import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';
import theme from '../../../../theme';
import ButtonCustom from '../../../atoms/Button';
import { CAT, DOG } from '../constants';
import Input from './Input';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBottom: 10,
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  mobile_image: {
    width: '100%',
    position: 'absolute',
    justifyContent: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}));

const schema = yup.object().shape({
  animalName: yup.string().trim().required(),
});

export default function SingleAnimal(props) {
  const {
    onSubmit,
    animalConfig,
    animalType,
    animalsInit,
    isLoading,
    t,
  } = props;
  const classes = useStyles();
  const index = 0;

  const [animals, setAnimals] = React.useState(animalsInit);
  const animal = animals[index] || {};

  const { control, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  useEffect(() => {
    if (animalsInit.length === 0) {
      setAnimals(
        animalType === DOG
          ? [{ species: DOG, name: '' }]
          : [{ species: CAT, name: '' }],
      );
      reset();
    }
  }, [animalType, animalsInit, reset]);

  const { isValid, errors } = formState;

  const handleOnSubmit = (data) => {
    const animals = [
      {
        ...animal,
        species: animalType,
        name: `${data.animalName
          .charAt(0)
          .toUpperCase()}${data.animalName.slice(1)}`,
      },
    ];
    onSubmit(animals);
  };

  const handleAnimal = (type, name) => {
    const newAnimals = animals.map((a, i) =>
      i === index
        ? {
            ...a,
            species: type,
            name: `${name.charAt(0).toUpperCase()}${name.slice(1)}`,
          }
        : a,
    );
    setAnimals(newAnimals);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        autoComplete="off"
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.root}
        >
          <Grid item xs={12} className={classes.title_container}>
            <Typography
              color={'primary'}
              variant={'h4'}
              className={classes.title_text}
            >
              {t('singleAnimal.title1')}
              <span style={{ display: 'inline-block' }}>
                {t('singleAnimal.title2')}
              </span>
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    t(
                      'animalConfig.dog.title',
                      animalConfig?.title,
                    ) || '',
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '10px ' }}>
            <Controller
              control={control}
              name="animalName"
              defaultValue={animal.name || ''}
              render={({ field }) => {
                const handleChange = (e) => {
                  field.onChange(e);
                  handleAnimal(animal.type, e.target.value);
                };
                return (
                  <Input
                    animal={animal}
                    errors={errors}
                    src={animalConfig?.icon || ''}
                    handleChange={handleChange}
                    label={
                      t(
                        `animalConfig.${animalType}.placeholder`,
                        animalConfig?.placeholder,
                      ) || ''
                    }
                    field={{ ...field, variant: 'standard' }}
                  />
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '20px',
              marginTop: { xs: '30px', sm: '50px' },
            }}
          >
            <ButtonCustom
              type={'submit'}
              width={122}
              height={47}
              disabled={!isValid}
            >
              {!isLoading ? (
                t('singleAnimal.button')
              ) : (
                <CircularProgress
                  sx={{
                    color: theme.palette.secondary.main,
                    display: 'block',
                  }}
                  thickness={5.0}
                  size="25px"
                />
              )}
            </ButtonCustom>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
