// import {makeStyles} from "@mui/styles";
import { Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';

import gender_f from '../../../../static/gender-f.svg';
import gender_m from '../../../../static/gender-m.svg';
import { ImageRadio } from '../../../atoms/ImageRadio';
import { FEMALE, MALE } from '../constants';

// const useStylesRadio = makeStyles({
//     root: {
//         position: 'absolute',
//         opacity: 0,
//         width: 0,
//         height: 0
//     }
// });

// function StyledRadio(props) {
//     const classes = useStylesRadio();

//     return (
//         <Radio className={classes.root}
//                {...props}/>
//     );
// }

export default function Input({
  classes,
  handleAnimal,
  animalGender,
  t,
}) {
  return (
    <RadioGroup
      value={animalGender}
      onClick={(event) => {
        if (event.target.value) handleAnimal(event.target.value);
      }}
      style={{
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <FormControlLabel
        value={MALE}
        style={{ zIndex: 100 }}
        control={<Radio />}
        labelPlacement="top"
        label={
          <ImageRadio
            label={t('singleAnimal.labelMale')}
            src={gender_m}
            alt={MALE}
            classes={classes}
            isChecked={animalGender === MALE}
          />
        }
      />
      <FormControlLabel
        value={FEMALE}
        style={{ zIndex: 100 }}
        control={<Radio />}
        labelPlacement="top"
        label={
          <ImageRadio
            label={t('singleAnimal.labelFemale')}
            src={gender_f}
            alt={FEMALE}
            classes={classes}
            isChecked={animalGender === FEMALE}
          />
        }
      />
    </RadioGroup>
  );
}
