import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

export default function HexagonalButton({
  isActive,
  onClick,
  value,
}) {
  const theme = useTheme();
  const hexagone =
    "path('M11.05,21.14C.89,35.86,0,66.62,0,66.62S4.49,99.37,14,111s51.83,19.84,51.83,19.84,46.24-11.23,52.85-15.85S136,69,136,69s-1.84-32.63-6.54-41.21C124.44,18.5,94,3,94,3L45.39,0')";

  return (
    <Button
      onClick={onClick}
      variant={'contained'}
      sx={{
        width: '137px',
        height: '132px',
        transform: { xs: 'scale(0.4)', md: 'scale(0.87249)' },
        background: isActive ? theme.palette.primary.main : '#9ebecc',
        clipPath: hexagone,
        WebkitTextSizeAdjust: 'auto',
        '&:hover': {
          background: '#9ebecc',
        },
        '&:disabled': {
          background: isActive
            ? theme.palette.primary.main
            : 'darkgrey',
          opacity: !isActive && 0.9,
        },
      }}
    >
      <Typography
        variant={'h2'}
        color={isActive ? 'secondary' : 'primary'}
        width={'100%'}
        sx={{
          fontSize: '35.24px !important',
          textTransform: 'lowercase',
          lineHeight: '30px',
        }}
      >
        {value}
      </Typography>
    </Button>
  );
}
