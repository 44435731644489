import ClearIcon from '@mui/icons-material/Clear';
import { Grid, RadioGroup, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';

import pricingTheme from '../../../../theme/pricing';
import { ImageRadio } from '../../../atoms/ImageRadio';
import { CAT, DOG, GUARD } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  paper: {
    padding: 10,
    background: 'inherit',
  },
  img: {
    maxHeight: 70,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 60,
    },
  },
  typo: {
    textAlign: 'center',
    fontSize: '0.8rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
  },
  text_field_label: {
    color: theme.palette.primary.main,
  },
  text_field: {
    color: theme.palette.primary.main,
    '&:before': {
      borderColor: theme.palette.primary.main,
    },
    '&:after': {
      borderColor: theme.palette.primary.main,
    },
  },
  checked: {
    backgroundColor: '#C0D8E2',
  },
}));

const useStylesRadio = makeStyles({
  root: {
    position: 'absolute',
    opacity: 0,
    width: 0,
    height: 0,
  },
});

function StyledRadio(props) {
  const classes = useStylesRadio();

  return <Radio className={classes.root} {...props} />;
}

export default function MultipleAnimalElement(props) {
  const { animalsConfig, index, animals, setAnimals, animalType, t } =
    props;
  const classes = useStyles();

  const animal = animals[index];
  const animalConfig = animalsConfig[animal.species];

  const handleAnimal = (species, name) => {
    const newAnimals = animals.map((a, i) =>
      i === index
        ? {
            ..._.omit(a, ['breedType', 'breed', 'isWatchdog']),
            species,
            name: `${name.charAt(0).toUpperCase()}${name.slice(1)}`,
          }
        : a,
    );
    setAnimals(newAnimals);
  };

  const ChooseAnimal = (
    <RadioGroup
      aria-label="position"
      name="position"
      onChange={(value) => {
        handleAnimal(value.target.value, animal.name);
      }}
      sx={{ marginRight: { xs: 0, sm: '10px' } }}
      style={{
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <FormControlLabel
        value={DOG}
        style={{ zIndex: 100 }}
        control={<StyledRadio />}
        labelPlacement="top"
        label={
          <ImageRadio
            src={animalsConfig[DOG].icon}
            alt={DOG}
            classes={classes}
            isChecked={animal.species === DOG}
          />
        }
      />
      <FormControlLabel
        value={CAT}
        style={{ zIndex: 100 }}
        control={<StyledRadio />}
        labelPlacement="top"
        label={
          <ImageRadio
            src={animalsConfig[CAT].icon}
            alt={CAT}
            classes={classes}
            isChecked={animal.species === CAT}
          />
        }
      />
    </RadioGroup>
  );

  const EnterName = (
    <TextField
      id="animaName"
      name={`animalName_${index}`}
      defaultValue={animal.name}
      label={
        t(
          `animalConfig.${animal.species}.placeholder`,
          animalConfig?.placeholder,
        ) || ''
      }
      variant="standard"
      InputLabelProps={{ className: classes.text_field_label }}
      InputProps={{ className: classes.text_field }}
      onChange={(e) => handleAnimal(animal.species, e.target.value)}
      value={animal.name}
    />
  );
  return (
    <Grid container direction="row" className={classes.root}>
      <section>{ChooseAnimal}</section>
      <section
        style={{
          display: 'flex',
          paddingRight: '10px',
          paddingLeft: '10px',
        }}
      >
        <div>{EnterName}</div>
        <div
          style={{
            visibility:
              animals.length > (animalType === GUARD ? 1 : 2)
                ? 'visible'
                : 'hidden',
          }}
        >
          <IconButton
            aria-label="delete"
            style={{ color: pricingTheme.palette.primary.main }}
            onClick={() => {
              const newAnimals = animals.filter(
                (e, i) => i !== index,
              );
              setAnimals(newAnimals);
            }}
            size="large"
          >
            <ClearIcon fontSize="inherit" />
          </IconButton>
        </div>
      </section>
    </Grid>
  );
}
