import { Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import theme, { blue_light } from '../../../../theme';
import ButtonCustom from '../../../atoms/Button';
import { animalsConfig } from '../animalName';
import { FEMALE, MALE } from '../constants';
import Input from './Input';

const useStyles = makeStyles((theme) => ({
  name_text: {
    overflow: 'auto',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
  },
  icon: {
    height: 80,
    width: 80,
    marginRight: 40,
    [theme.breakpoints.down('lg')]: {
      height: 70,
      width: 70,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 30,
      marginLeft: 30,
    },
  },
  img: {
    marginTop: 10,
    maxHeight: 50,
    [theme.breakpoints.down('md')]: {
      maxHeight: 30,
    },
  },
  typo: {
    fontSize: '1.4rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  checked: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: (props) =>
        props.isAccount ? '#C0D8E2' : blue_light,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#C0D8E2',
    },
  },
  paper: {
    background: 'inherit',
    width: 100,
    height: 100,
    [theme.breakpoints.down('md')]: {
      width: 80,
      height: 80,
    },
  },
}));

export default function Pricing({
  handleNextStep,
  isAccount,
  isLoading,
  t,
}) {
  const pricing = useSelector((state) => state.pricing);
  const { animals } = pricing;
  const classes = useStyles({ isAccount });
  const defaultAnimalGenders = animals.map(({ gender }) =>
    _.includes([MALE, FEMALE], gender) ? gender : MALE,
  );
  const [animalGenders, setAnimalGenders] = React.useState(
    defaultAnimalGenders,
  );
  const isValid = animalGenders?.length === animals?.length;

  const handleAnimal = (gender, index) => {
    const newAnimalGenders = _.cloneDeep(animalGenders);
    newAnimalGenders[index] = gender;
    setAnimalGenders(newAnimalGenders);
  };
  const onSubmit = () => {
    const animalValues = _.cloneDeep(animals);
    _.forEach(animalGenders, (gender, index) => {
      _.assign(animalValues[index], { gender });
    });
    handleNextStep(animalValues);
  };
  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          marginTop: '40px ',
        }}
      >
        {animals.map((animal, index) => (
          <Grid
            item
            xs={12}
            display="flex"
            direction="column"
            alignItems="center"
            style={{ marginBottom: '20px' }}
          >
            <Grid
              style={{ marginBottom: '10px', width: '100%' }}
              sx={{
                justifyContent: { xs: 'center', sm: 'flex-start' },
              }}
              display="flex"
              alignItems="center"
            >
              <Typography
                className={classes.name_text}
                color="primary"
              >
                {`${animal?.name
                  .charAt(0)
                  .toUpperCase()}${animal?.name.slice(1)}`}
              </Typography>
            </Grid>
            <Grid
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <img
                className={classes.icon}
                src={animalsConfig[animal?.species]?.icon}
                alt={'img'}
              />
              <Input
                classes={classes}
                handleAnimal={(gender) => {
                  handleAnimal(gender, index);
                }}
                animalGender={animalGenders[index]}
                t={t}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: '20px',
          marginTop: { xs: '30px', md: '50px' },
        }}
      >
        <ButtonCustom
          onClick={() => onSubmit()}
          width={122}
          height={47}
          disabled={!isValid}
        >
          {!isLoading ? (
            t('nextButton')
          ) : (
            <CircularProgress
              sx={{
                color: theme.palette.secondary.main,
                display: 'block',
              }}
              thickness={5.0}
              size="25px"
            />
          )}
        </ButtonCustom>
      </Grid>
    </>
  );
}
