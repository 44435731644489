import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { InlineWidget } from 'react-calendly';

import { LanguageContext } from '../../providers/context/language';
import { getCalendlyUrl } from '../../utils/calendly';
import Modal from '../molecules/Modal';

export default function CalendlyBooking({ open, setOpen }) {
  const { language } = useContext(LanguageContext);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={{ height: { xs: '100vh', md: '150vh' } }}>
        <InlineWidget
          url={getCalendlyUrl(language)}
          styles={{
            height: '100%',
          }}
          pageSettings={{
            backgroundColor: 'ffffff',
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
            primaryColor: '00a2ff',
            textColor: '4d5055',
          }}
          prefill={{
            email: '',
            name: '',
          }}
        />
      </Box>
    </Modal>
  );
}
