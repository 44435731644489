import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import theme from '../../../theme';
import ButtonCustom from '../../atoms/Button';
import Widget from '../../atoms/Widget';

const hexagone =
  "path('M11.05,21.14C.89,35.86,0,66.62,0,66.62S4.49,99.37,14,111s51.83,19.84,51.83,19.84,46.24-11.23,52.85-15.85S136,69,136,69s-1.84-32.63-6.54-41.21C124.44,18.5,94,3,94,3L45.39,0')";

const useStyles = makeStyles((theme) => ({
  text_field_label: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  text_field: {
    maxWidth: 160,
    fontSize: 21,
    color: theme.palette.primary.main,
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const mobileZoom = 0.7;

export default function AgeConfirmation({
  close,
  setUserBirthdayDate,
  defaultBirthdayDate,
  isModification,
}) {
  const { t } = useTranslation(null, {
    keyPrefix: 'quote.ageConfirmation',
  });

  const [birthdayDate, setBirthdayDate] = useState(
    defaultBirthdayDate || null,
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = useStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAgeChangeHandler = useCallback(
    _.debounce((newDate) => {
      setUserBirthdayDate(newDate);
    }, 1000),
    [setUserBirthdayDate],
  );

  const userAge = useMemo(() => {
    const now = new Date();
    if (!birthdayDate || now <= birthdayDate) {
      return 0;
    } else {
      return (
        new Date(
          now.getTime() - birthdayDate.getTime(),
        ).getFullYear() - 1970
      );
    }
  }, [birthdayDate]);

  const isAgeValid = userAge >= 18 && userAge <= 80;

  useEffect(() => {
    if (isAgeValid) debouncedAgeChangeHandler(birthdayDate);
  }, [birthdayDate, debouncedAgeChangeHandler, isAgeValid]);

  const { supplement } = useSelector((state) => state.pricing);
  const onSubmit = (isCancel = false) => {
    close(
      isCancel
        ? isModification
          ? defaultBirthdayDate
          : null
        : birthdayDate,
    );
  };

  const handleBirthdayDateChange = (date) => setBirthdayDate(date);

  const nowLess80Years = new Date();
  nowLess80Years.setFullYear(nowLess80Years.getFullYear() - 80);
  const nowLess18Years = new Date();
  nowLess18Years.setFullYear(nowLess18Years.getFullYear() - 18);

  return (
    <Widget color="white" modal>
      <Stack
        direction="column"
        height="100%"
        justifyContent="space-between"
      >
        <Typography
          variant={'h5'}
          sx={{ fontSize: { xs: '18px', md: '25px' } }}
        >
          {t('title')}
        </Typography>
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              zoom: { xs: mobileZoom, md: 1 },
              '-webkit-text-size-adjust': 'auto',
              '@supports ( -moz-appearance:none )': {
                '-moz-transform': {
                  xs: `scale(${mobileZoom})`,
                  md: `scale(${1})`,
                },
              },
            }}
          >
            <MobileDatePicker
              views={['year', 'month']}
              slots={{
                textField: (props) => (
                  <TextField
                    {...props}
                    InputLabelProps={{
                      className: classes.text_field_label,
                    }}
                    label={t('mobileDatePicker.labelBirthday')}
                  />
                ),
              }}
              open={isOpen}
              cancelText={t('mobileDatePicker.cancelText')}
              okText={t('mobileDatePicker.okText')}
              onChange={(date) => {
                handleBirthdayDateChange(date);
              }}
              onYearChange={(date) => {
                handleBirthdayDateChange(date);
              }}
              onMonthChange={(date) => {
                handleBirthdayDateChange(date);
                setIsOpen(false);
              }}
              onOpen={() => {
                setIsOpen(true);
              }}
              onAccept={() => setIsOpen(false)}
              onClose={() => setIsOpen(false)}
              showToolbar={false}
              minDate={nowLess80Years}
              maxDate={nowLess18Years}
              InputProps={{ className: classes.date_picker }}
              value={birthdayDate}
            />
          </Box>
          <Box
            sx={{
              zoom: { xs: mobileZoom, md: 1 },
              marginBottom: { xs: '30px', md: 0 },
              '@supports ( -moz-appearance:none )': {
                marginTop: '-26px',
                '-moz-transform': {
                  xs: `scale(${mobileZoom})`,
                  md: `scale(${1})`,
                },
              },
            }}
          >
            <Button
              disabled={true}
              variant={'contained'}
              style={{
                width: '137px',
                height: '132px',
                backgroundColor: theme.palette.primary.main,
                clipPath: hexagone,
                transform: 'scale(1.0814)',
                WebkitTextSizeAdjust: 'auto',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  variant={'h5'}
                  color={'secondary'}
                  width={'100%'}
                  style={{
                    textTransform: 'lowercase',
                    fontSize: '17.8px',
                    lineHeight: '45px',
                    marginTop: -20,
                  }}
                >
                  {t('extraCharge')}
                  <br />
                </Typography>
                <Typography
                  variant={'h5'}
                  color={'secondary'}
                  width={'100%'}
                  style={{
                    textTransform: 'lowercase',
                    fontSize: '30px',
                  }}
                >
                  +
                  {isAgeValid
                    ? supplement.toFixed(2).replace('.', ',')
                    : '0'}
                  <span style={{ marginLeft: '5px' }}>€</span>
                </Typography>
              </Box>
            </Button>
          </Box>
        </Stack>
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
        >
          <Button
            style={{
              width: '100px',
              marginRight: '50px',
              textDecoration: 'underline',
            }}
            onClick={() => onSubmit(true)}
          >
            {t('cancelButton')}
          </Button>
          <ButtonCustom
            type={'submit'}
            width={'100px'}
            disabled={!isAgeValid}
            onClick={() => onSubmit()}
          >
            {t('confirmButton')}
          </ButtonCustom>
        </Stack>
      </Stack>
    </Widget>
  );
}
