import { Link, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactPinField from 'react-pin-field';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LanguageContext } from '../../../../providers/context/language';
import Button from '../../../atoms/Button';
import Snackbar from '../../../atoms/Snackbar';
import {
  clearState,
  loginUser,
  preLoginUser,
  setErrorMessage,
  userSelector,
} from '../userSlice';

const useStyles = makeStyles((theme) => ({
  root: {},
  block: {
    display: 'block',
    maxWidth: '985px',
  },
  section: {
    textAlign: 'left',
    '& > *': {
      margin: theme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inline: {
    width: 'calc(50% - 2 * ' + theme.spacing(1) + 'px)',
  },
  pinField: {
    backgroundColor: 'rgb(248, 249, 250)',
    border: '1px solid rgb(204, 204, 204)',
    borderRadius: '0.3rem',
    fontSize: '2rem',
    margin: '0.25rem',
    height: '3.5rem',
    outline: 'none',
    textAlign: 'center',
    transitionDuration: '250ms',
    transitionProperty:
      'background, color, border, box-shadow, transform',
    width: '3rem',
    '&:focus': {
      borderColor: 'rgb(0, 123, 255)',
      outline: 'none',
      transform: 'scale(1.05)',
    },

    '&:invalid': {
      animation: `$shake 3 linear 75ms`,
      borderColor: 'rgb(220, 53, 69)',
      boxShadow: '0 0 0.25rem rgba(220, 53, 69, 0.5)',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      opacity: '0.5',
    },
  },

  'swd-pin-field[completed] .pinField': {
    borderColor: 'rgb(40, 167, 69)',
    backgroundColor: 'rgba(40, 167, 69, 0.1)',
  },

  '@keyframes shake': {
    from: {
      transform: 'scale(1.05) translateY(-5%)',
    },
    to: {
      transform: 'scale(1.05) translateY(5%)',
    },
  },
}));

const App = ({ t, otp, setOtp }) => {
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { isSuccess, isError, errorMessage } =
    useSelector(userSelector);
  const { changeLanguage } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(clearState());
    dispatch(setErrorMessage(''));
  }, [dispatch]);
  const onSubmit = (data) => {
    setIsLoading(true);
    if (data.email) {
      _.assign(data, { email: data.email.trim().toLowerCase() });
      setEmail({ email: data.email.trim().toLowerCase() });
    }
    otp
      ? dispatch(
          loginUser({ ...data, changeLanguage }, changeLanguage),
        )
      : dispatch(preLoginUser(data)).then((rep) => {
          if (!rep.error) setOtp(true);
          if (
            process.env.REACT_APP_IS_MOBILE_APP === 'true' &&
            rep?.error &&
            rep?.payload
          ) {
            enqueueSnackbar(
              rep?.payload.replace(/<br\s*[\\/]?>/gi, '\n'),
              {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              },
            );
          }
        });
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState();

  const classes = useStyles();

  useEffect(() => {
    setIsLoading(false);
    if (isSuccess) {
      dispatch(clearState());
      navigate('/account/');
    }
    if (isError) {
      dispatch(clearState());
    }
  }, [isSuccess, isError, dispatch, errorMessage, otp, navigate]);

  // const renderLanguageSelectorMobile = () => (
  //   <Box
  //     sx={{
  //       display: 'inline-flex',
  //       pt: 3,
  //       width: '100%',
  //       placeContent: 'center',
  //       gap: 3,
  //     }}
  //   >
  //     <Typography variant="h4" sx={{ pr: 1, alignSelf: 'center' }}>
  //       {t('signInComponent.mobile.changeLanguage')}
  //     </Typography>
  //     <LanguageSelector
  //       divSx={{
  //         alignSelf: 'center',
  //       }}
  //     />
  //   </Box>
  // );

  if (isLoading)
    return (
      <section style={{ height: '150px' }}>
        <CircularProgress />
      </section>
    );

  if (otp)
    return (
      <Stack>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            color="primary"
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              textAlign: 'center',
            }}
          >
            {t('signInComponent.otpText1')}
            <span style={{ display: 'inline-block' }}>
              {t('signInComponent.otpText2')}
            </span>
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 15,
              marginBottom: 15,
              paddingTop: 15,
            }}
          >
            <Controller
              name="code"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange },
                fieldState: { error },
              }) => (
                <ReactPinField
                  className={classes.pinField}
                  validate="0123456789"
                  inputMode="numeric"
                  length={4}
                  onChange={onChange}
                  error={!!error}
                  onComplete={handleSubmit(onSubmit)}
                />
              )}
            />
          </div>
        </form>
        <Link
          color="primary"
          underline="hover"
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 15,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={() => {
            setOpenSnackbar(true);
            dispatch(preLoginUser(email));
          }}
        >
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Typography
              style={{
                textAlign: 'center',
                textDecoration: 'underline',
              }}
            >
              {t('signInComponent.mobile.notSend1')}
              <span
                style={{
                  display: 'inline-block',
                  textDecoration: 'underline',
                }}
              >
                {t('signInComponent.mobile.notSend2')}
              </span>
              <br />
              {t('signInComponent.mobile.notSend3')}
              <span
                style={{
                  display: 'inline-block',
                  textDecoration: 'underline',
                }}
              >
                {t('signInComponent.mobile.notSend4')}
              </span>
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Typography>{t('signInComponent.notSend')}</Typography>
          </Box>
        </Link>
        {errorMessage?.length ? (
          <Typography
            style={{
              marginTop: '10px',
              color: '#d32f2f',
              textAlign: 'center',
              paddingRight: '15px',
              paddingLeft: '15px',
            }}
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          />
        ) : (
          <></>
        )}

        <Snackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={() => setOpenSnackbar(false)}
          alertStatus={'success'}
          snackbarMessage={t('signInComponent.snackbar')}
        ></Snackbar>
      </Stack>
    );

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Stack>
          <Controller
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <TextField
                value={value}
                type={'email'}
                onChange={onChange}
                error={!!error}
                data-cy="login-emailInput"
                helperText={error ? error.message : null}
                placeholder={t('signInComponent.placeholderAddress')}
                className={classes.block}
              />
            )}
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: t('signInComponent.errorFieldAddress'),
            }}
          />
          {process.env.REACT_APP_IS_MOBILE_APP === 'false' &&
          errorMessage?.length ? (
            <Typography
              data-cy="login-errorMessage"
              style={{
                marginTop: '10px',
                color: '#d32f2f',
                textAlign: 'center',
                paddingRight: '15px',
                paddingLeft: '15px',
              }}
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          ) : (
            <></>
          )}
          <br />
          <Button
            sx={{ width: 'fit-content', margin: 'auto' }}
            type="submit"
            data-cy="login-submitButton"
          >
            {t('signInComponent.connexion')}
          </Button>
        </Stack>
      </form>

      {/* {process.env.REACT_APP_IS_MOBILE_APP === 'true' &&
        renderLanguageSelectorMobile()} */}
    </>
  );
};

export default App;
