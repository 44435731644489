import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 320,
    '& *': {
      fontFamily: (props) => (props.normal ? 'Roboto' : 'Supply'),
    },
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: (props) => props.mobileHeight || '100%',
    },
    backgroundColor: (props) => (props.color ? props.color : 'white'),
    border: 0,
    cursor: (props) => (props.disabled ? 'default' : 'pointer'),
  },
  area: {
    display: 'contents',
    '& .MuiTouchRipple-root span': {
      backgroundColor: (props) => (props.ripple ? props.ripple : ''),
      opacity: 0.3,
    },
  },
  centerBottom: {
    height: '100px',
    justifyContent: 'center',
    flex: '0 1 40px',
    marginTop: '0px !important',
    padding: 4,
    marginBottom: '50px !important',
  },
  content: {
    // minHeight: (props) => props.modal?550:'',
    textAlign: 'center',
    display: 'flex',
    flex: '1 1 auto',
    padding: '0 !important',
    margin: (props) => props.contentMargin || '14px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5px !important',
    },
    flexDirection: 'column',
    overflowY: 'auto',
  },
  title: {
    padding: '10px 16px',
    borderBottom: 'solid 1px beige',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    flex: '0 1 auto',
    '& span': {
      fontSize: 14,
    },
  },
}));

export default function OutlinedCard({ children, ...props }) {
  const classes = useStyles(props);
  if (props.onClick && !props.disabled)
    return (
      <Card className={classes.root} variant="outlined">
        <CardActionArea
          className={classes.area}
          onClick={props.onClick}
        >
          {props.title ? (
            <CardHeader
              title={props.title}
              className={classes.title}
            />
          ) : (
            ''
          )}

          <CardContent className={classes.content}>
            {children}
          </CardContent>
        </CardActionArea>

        {props.action ? (
          <CardActions className={classes.centerBottom}>
            {props.action}
          </CardActions>
        ) : (
          ''
        )}
      </Card>
    );

  return (
    <Card className={classes.root} variant="outlined">
      {props.title ? (
        <CardHeader title={props.title} className={classes.title} />
      ) : (
        ''
      )}

      <Stack
        justifyContent="flex-end"
        alignItems="stretch"
        spacing={3}
        sx={{ height: props.action ? '98%' : '100%' }}
      >
        <CardContent className={classes.content}>
          {children}
        </CardContent>

        {props.action ? (
          <CardActions className={classes.centerBottom}>
            {props.action}
          </CardActions>
        ) : (
          ''
        )}
      </Stack>
    </Card>
  );
}
