import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import IconAccident from '../../../static/home/elements/Fichier 18.svg';
import IconMalade from '../../../static/home/elements/Fichier 19.svg';
import IconCat from '../../../static/icon_cat.svg';
import IconDog from '../../../static/icon_dog.svg';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: '#C0D8E2',
    },
  },
  img: {
    width: 100,
    height: 100,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      width: 80,
      height: 80,
    },
  },
  btn: {
    backgroundColor: theme.palette.primary.main,
    '&:disabled': {
      color: 'white',
    },
    width: 150,
    [theme.breakpoints.down('lg')]: {
      width: 120,
    },
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
}));

const image = (img) => {
  switch (img) {
    case 'dog':
      return IconDog;
    case 'cat':
      return IconCat;
    case 'accident':
      return IconAccident;
    case 'malade':
      return IconMalade;
    default:
      return IconDog;
  }
};
export default function ItemAnimal(props) {
  const classes = useStyles();
  return (
    <Button
      className={classes.box}
      onClick={() => props.onClick(props.data.value)}
      style={{ backgroundColor: props.isActive ? '#C0D8E2' : '' }}
    >
      <img
        src={image(props.data.img)}
        className={classes.img}
        alt={props.data.img}
      />
      <Button disabled className={classes.btn}>
        {props.data.text}
      </Button>
    </Button>
  );
}
