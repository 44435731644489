import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  Box,
  CardContent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Context } from '../../../../layouts/Account';
import {
  loadStripeOney,
  loadStripeWakam,
} from '../../../../utils/stripeLoader';
import CreditCardElement from './CreditCardElement';
import IbanElement from './IbanElement';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`payment-tabpanel-${index}`}
      aria-labelledby={`payment-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `payment-tab-${index}`,
    'aria-controls': `payment-tabpanel-${index}`,
  };
}

export default function StripeModifier(props) {
  const [stripeOney, setStripeOney] = React.useState(null);
  const [stripeWakam, setStripeWakam] = React.useState(null);
  React.useEffect(() => {
    const fetchOney = async () => await loadStripeOney();
    const fetchWakam = async () => await loadStripeWakam();
    const oneyStripeProvider = fetchOney().catch(console.error);
    const wakamStripeProvider = fetchWakam().catch(console.error);
    setStripeOney(oneyStripeProvider);
    setStripeWakam(wakamStripeProvider);
  }, []);
  const [value, setValue] = React.useState(0);
  const { data } = useContext(Context);
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.admin',
  });

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <Elements
      stripe={
        data.default_contract.provider === 'wakam'
          ? stripeWakam
          : stripeOney
      }
    >
      {props.iban_only ? (
        <IbanElement {...props} />
      ) : (
        <>
          <Box sx={{ borderBottom: 1 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
              variant="fullWidth"
            >
              <Tab
                icon={<CreditCardIcon fontSize={'large'} />}
                label={t('creditCard')}
                {...a11yProps(0)}
              />
              <Tab
                icon={<AccountBalanceIcon fontSize={'large'} />}
                label="IBAN"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <CardContent
            sx={{
              margin: 'auto',
              width: '100%',
              padding: 0,
              '&:last-child': { padding: 0 },
            }}
          >
            <TabPanel value={value} index={0}>
              <CreditCardElement {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <IbanElement {...props} />
            </TabPanel>
          </CardContent>
        </>
      )}
    </Elements>
  );
}
