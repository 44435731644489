import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import IconBirthDay from '../../../../static/birthday.svg';
import IconCat from '../../../../static/cat.svg';
import IconMultiple from '../../../../static/cat_and_dog.svg';
import IconDog from '../../../../static/dog.svg';
import IconGuard from '../../../../static/guard.svg';
import { selectAnimals } from '../pricingSlice';
import Input from './Input';

const icons = {
  cat: IconCat,
  dog: IconDog,
  multiple: IconMultiple,
  guard: IconGuard,
  birthday: IconBirthDay,
};

const useStyles = makeStyles((theme) => ({
  birthday_icon: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
  },
}));

const App = ({ control, register, animal, t, ...props }) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const animal_birthday = animal?.birthday
    ? new Date(animal.birthday)
    : null;
  const handleAnimal = (birthday) => {
    setTimeout(() => {
      if (window.innerWidth < 768)
        window.scrollTo({ top: 1500, behavior: 'smooth' });
    }, 500);
    const newAnimal = { ...animal, birthday };
    dispatch(selectAnimals([newAnimal]));
  };
  return (
    <Grid
      item
      xs={12}
      sx={{
        width: { xs: 'none', sm: '400px', md: '450px', lg: '500px' },
      }}
    >
      <Controller
        control={control}
        name="animalBirthday_0"
        defaultValue={animal_birthday}
        render={({ field }) => {
          const handleChange = (e) => {
            field.onChange(e);
            handleAnimal(e);
          };
          return (
            <Input
              register={register}
              field={{
                ...field,
                onChange: handleChange,
              }}
              animal={animal}
              iconClass={classes.birthday_icon}
              label={t('labelBirthday')}
              {...props}
              src={icons.birthday}
            />
          );
        }}
      />
    </Grid>
  );
};

export default App;
