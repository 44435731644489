import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { blue } from '../../../../theme';
import {
  createData,
  ERROR_COLOR,
  SUCCESS_COLOR,
} from './myRefundsOrQuotes';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Supply',
    color: blue,
    fontSize: 'clamp(1.6rem, 0.74rem + 1.88vw, 1.8rem)',
  },
  sub: {
    display: 'block',
    color: 'gray',
  },
  name: {
    fontWeight: 'bold',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.mobileTable',
  });
  const classes = useStyles();
  const isRefunds = props.isRefunds;

  const [order] = React.useState('desc');
  const [orderBy] = React.useState('createdAt');
  const [page, setPage] = React.useState(0);

  const rowsPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const rows = props.data.claims
    .filter(
      (item) => item.typeClaim === (isRefunds ? 'refund' : 'quote'),
    )
    .map((item) => createData(item));
  return (
    <>
      <h2 className={classes.title}>
        {isRefunds ? t('refundsTitle1') : t('refundsTitle2')}
      </h2>

      {rows.length === 0 ? (
        <h3>
          {isRefunds ? (
            <>
              {t('noRefund.text1')}
              <span style={{ display: 'inline-block' }}>
                {t('noRefund.text2')}
              </span>{' '}
              <br />
              <br />
              {isRefunds ? t('noRefund.text3') : t('noRefund.text4')}
            </>
          ) : (
            <>{t('noQuote')}</>
          )}
        </h3>
      ) : (
        <>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              sx={{ height: '100%' }}
            >
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell
                          align="left"
                          style={{
                            paddingLeft: '14px',
                            paddingRight: '4px',
                          }}
                        >
                          <div className={classes.name}>
                            {row.animal}
                            <small>
                              {' '}
                              <span
                                style={{ display: 'inline-block' }}
                              >
                                ({t('cost')} {row.montantP} €)
                              </span>
                            </small>
                          </div>
                          <small className={classes.sub}>
                            {t('the')}{' '}
                            {row.dateS.toLocaleDateString('fr')}
                          </small>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            paddingLeft: '4px',
                            paddingRight: '14px',
                          }}
                        >
                          <div
                            style={{
                              color:
                                row.status === 'refused'
                                  ? ERROR_COLOR
                                  : row.status === 'paid'
                                  ? SUCCESS_COLOR
                                  : '',
                            }}
                          >
                            {row.montantR} €
                          </div>{' '}
                          <small
                            style={{
                              color:
                                row.status === 'refused'
                                  ? ERROR_COLOR
                                  : row.status === 'paid'
                                  ? SUCCESS_COLOR
                                  : '',
                            }}
                            className={classes.sub}
                          >
                            {row.statusLabel}
                          </small>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ marginRight: '20px', marginBottom: '30px' }}
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            className={classes.page}
            labelDisplayedRows={({ from, to, count }) =>
              t('totalLines', { from, to, count })
            }
          />
        </>
      )}
    </>
  );
}
