import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

import logoAlcampo from '../../static/alcampo/logo.svg';
import logoDrooky from '../../static/logo.svg';
import { isAlcampo } from '../../utils/language';

const style = {
  clipPath: { xs: '', xl: 'polygon(1% 5%,100% 0%,98% 100%,0% 98%)' },
  width: { xs: '100%', xl: '80%' },
  height: { xs: '100%', xl: '80%' },
  minWidth: { xs: '100%', xl: '80%' },
  minHeight: { xs: '100%', xl: '80%' },
  backgroundColor: 'white',
  display: 'flex',
  flexFlow: 'column',
};

const fitStyle = {
  clipPath: { xs: '', md: 'polygon(1% 5%,100% 0%,98% 100%,0% 98%)' },
  width: { xs: '100%', md: '830px' },
  height: { xs: '100%', md: '80%' },
  minWidth: { xs: '100%', md: '500px' },
  minHeight: { xs: '100%', md: '80%' },
  backgroundColor: 'white',
  display: 'flex',
  flexFlow: 'column',
};

const helloSignStyle = {
  clipPath: { xs: '', md: 'polygon(1% 5%,100% 0%,98% 100%,0% 98%)' },
  width: { xs: '100%', md: '830px' },
  height: { xs: '100%', md: '80%' },
  minWidth: { xs: '100%', md: '500px' },
  minHeight: { xs: '100%', md: '80%' },
  backgroundColor: 'white',
  display: 'flex',
  flexFlow: 'column',
};

const useStyles = makeStyles((theme) => ({
  header: {
    height: 72,
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      height: 50,
    },
    textAlign: 'right',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    position: 'absolute',
    '&:hover': {
      cursor: 'pointer',
    },
    right: 0,
    top: 0,
    // padding: 5,
    transform: 'scale(3) translateX(-50%) translateY(50%)',
    [theme.breakpoints.down('md')]: {
      transform: 'scale(1.5) translateX(-40%) translateY(30%)',
    },
    color: theme.palette.primary.main,
  },
}));

export default function MyModal({ children, ...props }) {
  const classes = useStyles();
  const [closeable, setCloseable] = React.useState(true);
  const logo = isAlcampo() ? logoAlcampo : logoDrooky;

  const click = () => {
    closeable && props.onClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      {...props}
      className={classes.center}
    >
      <Box
        sx={
          props.modalStyle
            ? props.modalStyle
            : props.fit
            ? fitStyle
            : props.hellosign
            ? helloSignStyle
            : style
        }
      >
        <div className={classes.header}>
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center',
              height: '35px',
            }}
          >
            <img
              style={{ height: '100%' }}
              src={logo}
              alt={'Accueil'}
            />
            {!isAlcampo() && (
              <Typography
                color="primary"
                variant="h5"
                style={{ fontSize: '22px', marginLeft: '10px' }}
              >
                drooky
              </Typography>
            )}
          </Box>
          <CloseIcon onClick={click} className={classes.icon} />
        </div>
        {React.useMemo(
          () =>
            React.isValidElement(children)
              ? React.cloneElement(children, { setCloseable })
              : children,
          [children],
        )}
      </Box>
    </Modal>
  );
}
