import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import theme from '../../theme';
import { blue } from '../../theme';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& > div > div': {
    [theme.breakpoints.down('xl')]: {
      width: '100%',
      height:
        'calc(100% - (env(safe-area-inset-top) + env(safe-area-inset-bottom)))',
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 0,
      marginTop: 'env(safe-area-inset-top)',
      marginBottom: 'safe-area-inset-bottom',
    },
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogTitle-root': {
    fontSize: 30,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, textAlign: 'center', color: blue }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'molecules.dialog',
  });
  return (
    <div>
      <BootstrapDialog
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.onClose}
        >
          {props.title}
        </BootstrapDialogTitle>
        <DialogContent sx={{ textAlign: 'justify', margin: 'auto' }}>
          {props.children}
        </DialogContent>
        {props.done ? (
          ''
        ) : (
          <DialogActions>
            <Button onClick={props.onClose}>
              {t('cancelButton')}
            </Button>
            <Button
              onClick={props.action}
              variant={'contained'}
              sx={{
                width: { xs: '97px', sm: '122px' },
                height: { xs: '37px', md: '46px' },
                clipPath:
                  'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
                borderRadius: 0,
              }}
              autoFocus
            >
              {props.status === 'loading' ? (
                <CircularProgress
                  thickness={5.0}
                  size="26px"
                  sx={{ color: theme.palette.secondary.main }}
                />
              ) : (
                t('confirmButton')
              )}
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
