import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';
import pricingTheme from '../../../../theme/pricing';
import Dropzone from '../../../molecules/Dropzone';

const useStyles = makeStyles((theme) => ({
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));

export default function Step(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.step6',
  });
  const classes = useStyles();

  const [files, setFiles] = props.files;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      style={{
        width: '90%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        color={'primary'}
        variant={'h4'}
        className={classes.title_text}
      >
        {!props.isQuote ? (
          <>
            {t('isNotQuote.title1')}
            <span style={{ display: 'inline-block' }}>
              {t('isNotQuote.title2')}
              <IconButton
                sx={{
                  color: {
                    xs: 'white',
                    sm: pricingTheme.palette.primary.main,
                  },
                }}
                onClick={handleClick}
              >
                <HelpOutlineIcon size="large" />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Typography
                  color={'primary'}
                  sx={{
                    maxWidth: { xs: '450px', md: '600px' },
                    padding: '10px',
                    fontSize: { xs: '15px', sm: '18px' },
                  }}
                >
                  {t('isNotQuote.popoverText')}
                </Typography>
              </Popover>
            </span>
            <br />
            {t('isNotQuote.text3')}
          </>
        ) : (
          <>{t('isQuote.title1')}</>
        )}
      </Typography>
      <Dropzone files={[files, setFiles]} />
    </Box>
  );
}
