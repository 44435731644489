import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { blue_light } from '../../../../theme';
import { FEMALE, MALE } from '../constants';
import Input from './Input';

const useStyles = makeStyles((theme) => ({
  img: {
    marginTop: 15,
    maxHeight: 70,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 60,
    },
  },
  typo: {
    marginTop: 10,
    fontSize: '1.7rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  checked: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: (props) =>
        props.isAccount ? '#C0D8E2' : blue_light,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#C0D8E2',
    },
  },
  paper: {
    background: 'inherit',
    width: 150,
    height: 150,
    [theme.breakpoints.down('lg')]: {
      width: 140,
      height: 140,
    },
    [theme.breakpoints.down('md')]: {
      width: 130,
      height: 130,
    },
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 120,
    },
  },
}));

export default function Pricing({ handleNextStep, isAccount, t }) {
  const classes = useStyles({ isAccount });
  const pricing = useSelector((state) => state.pricing);
  const { animals } = pricing;
  const animal = _.first(animals) || {};
  const defaultAnimalGender = _.includes(
    [MALE, FEMALE],
    animal?.gender,
  )
    ? animal.gender
    : undefined;
  const [animalGender, setAnimalGender] = React.useState(
    defaultAnimalGender,
  );

  const handleAnimal = (gender) => {
    setAnimalGender(gender);
    const animalValues = [{ ...animal, gender: gender }];
    handleNextStep(animalValues);
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: '40px ' }}>
        <Input
          classes={classes}
          handleAnimal={handleAnimal}
          animalGender={animalGender}
          t={t}
        />
      </Grid>
    </>
  );
}
