import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import pricing_asset_mobile_2 from '../../../../static/pricing/pricing-asset-mobile-2.svg';
import { blue } from '../../../../theme';
import { selectQuotePricing } from '../../pricing/pricingSlice';

const useStyles = makeStyles((theme) => ({
  animalSelection: {
    width: '100%',
    paddingRight: 10,
    paddingLeft: 10,
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  mobile_image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    justifyContent: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  img: {
    maxHeight: 80,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 70,
    },
  },
  container: {
    margin: '20px',
    [theme.breakpoints.down('md')]: {
      margin: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '15px',
    },
  },
  typo: {
    color: theme.palette.primary.main,
    fontSize: '1.7rem',
    marginTop: '6px',
    maxWidth: 260,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 210,
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 180,
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
      fontSize: '1.1rem',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: '#C0D8E2',
    },
  },
  marginButton: {
    marginBottom: 30,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
    },
  },
}));
function SelectQuotePricing({ data, handleNext }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.selectQuotePricing',
  });
  const firstName =
    useSelector((state) => state.pricing.firstName) || '';

  const classes = useStyles();
  const dispatch = useDispatch();
  const [quotePricing, setQuotePricing] = React.useState(null);
  const activeContractItems = data?.contracts
    ?.filter((contract) => !contract.isResiliated)
    .map((contract) => contract.items)[0];
  const animals = activeContractItems
    ?.filter((item) => item.contractItemType === 'health')
    ?.filter(
      (item) =>
        !activeContractItems
          .filter((item) => item.contractItemType === 'prev')
          .map((item) => item.animal.id)
          .includes(item.animal.id),
    );

  const handleQuotePricingChange = (e) => {
    const {
      target: { value },
    } = e;
    setQuotePricing(value);
    dispatch(selectQuotePricing(value));
    handleNext({
      prevention: value,
    });
  };

  return animals && animals.length !== 0 ? (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.animalSelection}
    >
      <Grid item xs={12} className={classes.title_container}>
        <Typography
          color={'primary'}
          variant={'h4'}
          className={classes.title_text}
        >
          {t('title', { firstName })}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mt: { xs: '20px', md: '50px' } }}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={quotePricing === '100'}
              onChange={handleQuotePricingChange}
              value="100"
            />
          }
          label={
            <>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: 'left' }}
              >
                {t('firstOption.text1')}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ textAlign: 'left' }}
              >
                {t('firstOption.text2')}
              </Typography>
            </>
          }
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ mt: { xs: '20px', md: '50px' } }}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={quotePricing === '200'}
              onChange={handleQuotePricingChange}
              value="200"
            />
          }
          label={
            <>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: 'left' }}
              >
                {t('secondOption.text1')}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ textAlign: 'left' }}
              >
                {t('secondOption.text2')}
              </Typography>
            </>
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: { xs: '100px', md: '200px' } }}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {t('text1')} <br />
        {t('text2')}
      </Grid>
    </Grid>
  ) : (
    <Box
      style={{
        margin: 'auto',
        width: '90%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h2
        style={{
          fontFamily: 'Supply',
          color: blue,
          margin: 'auto',
        }}
      >
        {t('noContractAnimal.text1')}
        <br />
        <br />
        {t('noContractAnimal.text2')}
        <br />
        {t('noContractAnimal.text3')}
        <br />
        {t('noContractAnimal.text4')}
        <br />
        {t('noContractAnimal.text5')}
      </h2>
    </Box>
  );
}

SelectQuotePricing.componentName = 'stepLabels.price';

export default SelectQuotePricing;
