import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import makeStyles from '@mui/styles/makeStyles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { blue } from '../../../../theme';
import {
  createData,
  ERROR_COLOR,
  SUCCESS_COLOR,
} from './myRefundsOrQuotes';

const useStyles = makeStyles((theme) => ({
  table: {
    '& th, & td': {
      padding: 6,
    },
    '& th:last-child, & td:last-child': {
      minWidth: 175,
    },
    [theme.breakpoints.down(1200)]: {
      '& th:nth-child(2), & td:nth-child(2), & th:nth-last-child(2), & td:nth-last-child(2)':
        {
          display: 'none',
        },
    },
    /*[theme.breakpoints.down(900)]: {
      '& th:nth-child(2), & td:nth-child(2), & th:nth-last-child(2), & td:nth-last-child(2)':
        {
          display: 'block',
        },
    },
    [theme.breakpoints.down(830)]: {
      '& th:nth-child(2), & td:nth-child(2), & th:nth-last-child(2), & td:nth-last-child(2)':
        {
          display: 'none',
        },
    },*/
  },
  rowHead: {
    background: theme.palette.primary.main,
    '& th, & th *, & th *:hover, & th *:focus, & th *:active': {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  page: {
    overflow: 'visible',
  },
  title: {
    fontFamily: 'Supply',
    color: blue,
    fontSize: 'clamp(1.8rem, 0.74rem + 1.88vw, 3rem)',
    marginLeft: '50px',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.table',
  });
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 1450;
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const headCells = [
    {
      id: 'dateS',
      numeric: false,
      disablePadding: false,
      label:
        width >= breakpoint
          ? t('headCell.healthDate')
          : t('headCell.date'),
    },
    {
      id: 'ref',
      numeric: false,
      disablePadding: false,
      label: t('headCell.reference'),
    },
    {
      id: 'animal',
      numeric: false,
      disablePadding: false,
      label: t('headCell.animal'),
    },
    {
      id: 'montantP',
      numeric: true,
      disablePadding: false,
      label:
        width >= breakpoint
          ? t('headCell.amountPayed')
          : t('headCell.payed'),
    },
    {
      id: 'montantR',
      numeric: true,
      disablePadding: false,
      label:
        width >= breakpoint
          ? t('headCell.amountRefund')
          : t('headCell.refund'),
    },
    {
      id: 'reste',
      numeric: true,
      disablePadding: false,
      label:
        width >= breakpoint
          ? t('headCell.remains')
          : t('headCell.rest'),
    },
    {
      id: 'statut',
      numeric: false,
      statut: true,
      disablePadding: false,
      label: t('headCell.statut'),
    },
  ];

  return (
    <TableHead className={classes.rowHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.statut
                ? 'center'
                : headCell.numeric
                ? 'right'
                : 'left'
            }
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.table',
  });
  const classes = useStyles();
  const isRefunds = props.isRefunds;

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const rowsPerPage = 15;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const rows = props.data.claims
    .filter(
      (item) => item.typeClaim === (isRefunds ? 'refund' : 'quote'),
    )
    .map((item) => createData(item));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - rows.length)
      : 0;

  if (rows.length) {
    if (rows.length) {
      return (
        <>
          <h1 className={classes.title}>
            {isRefunds ? t('title.isRefund') : t('title.isQuote')}
          </h1>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              className={classes.table}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell align="left">
                          {row.dateS.toLocaleDateString()}
                        </TableCell>
                        <TableCell align="left">{row.ref}</TableCell>
                        <TableCell align="left">
                          {row.animal}
                        </TableCell>
                        <TableCell align="right">
                          {row.montantP} €
                        </TableCell>
                        <TableCell align="right">
                          {row.montantR}{' '}
                          {row.montantR !== '-' ? '€' : ''}
                        </TableCell>
                        <TableCell align="right">
                          {row.reste} {row.reste !== '-' ? '€' : ''}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color:
                              row.status === 'refused'
                                ? ERROR_COLOR
                                : row.status === 'paid'
                                ? SUCCESS_COLOR
                                : '',
                          }}
                        >
                          {row.statusLabel}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            className={classes.page}
            labelDisplayedRows={({ from, to, count }) =>
              t('totalLines', { from, to, count })
            }
          />
        </>
      );
    }
  }

  return (
    <>
      <h1 className={classes.title}>
        {isRefunds ? t('title.isRefund') : t('title.isQuote')}
      </h1>

      <h2 style={{ fontFamily: 'Supply', color: blue }}>
        {isRefunds ? (
          <>
            {t('allGood1')}
            <br />
            <br />
            {t('allGood2')}
          </>
        ) : (
          <>{t('noQuote')}</>
        )}
      </h2>
    </>
  );
}
