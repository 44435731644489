import { HelpOutline } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import quote_1_franchise from '../../static/quote/1-franchise.svg';
import quote_2_title1 from '../../static/quote/2-title1.svg';
import mobile_0_subtitle from '../../static/quote/mobile/0-subtitle.svg';
import { isAlcampo } from '../../utils/language';

export default function Slider({
  title,
  subtitle,
  children,
  toolTip,
}) {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: { md: '80px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          minWidth: { xs: 'none', md: '500px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
          }}
        >
          <Typography variant={'h5'} sx={{ m: '0px 16px 0px 8px' }}>
            {title}
          </Typography>
          {toolTip && (
            <Tooltip
              placement="bottom"
              followCursor
              enterTouchDelay={0}
              title={toolTip}
              sx={{ mt: '0px !important' }}
            >
              <IconButton>
                <HelpOutline />
              </IconButton>
            </Tooltip>
          )}
          <Box
            component="img"
            src={isXsScreen ? mobile_0_subtitle : quote_2_title1}
            alt="cat"
            sx={{
              zIndex: -10,
              position: 'absolute',
            }}
          />
        </Box>
        <Typography
          variant={'h6'}
          sx={{
            display: { xs: 'none', md: 'block' },
            minWidth: '420px',
            paddingTop: '32px',
            marginLeft: '8px',
            lineHeight: '14px',
            textAlign: 'left',
            whiteSpace: 'pre-line',
          }}
        >
          {subtitle}
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: 'auto', md: '1080px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: {
              md: 'space-evenly',
            },
            alignItems: 'center',
            width: '100%',
            transform: { xs: 'scale(0.9)', md: 'scale(1)' },
            height: '100px',
            opacity: isAlcampo() ? 0.9 : 1,
          }}
        >
          <img
            src={quote_1_franchise}
            alt="transition"
            style={{
              width: '90%',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
          {children}
        </Box>
      </Box>
    </Box>
  );
}
