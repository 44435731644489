import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Intro from '../components/atoms/Intro';
import IntroAlcampo from '../components/atoms/IntroAlcampo';
import Navbar from '../components/templates/home/Navbar';
import theme from '../theme/home';
import { isAlcampo } from '../utils/language';
import './styles.css';

export default function HomePageLayout(props) {
  const [showSite, setShowSite] = useState(
    sessionStorage.getItem('init'),
  );
  const [showSplash, setShowSplash] = useState(!showSite);

  useEffect(() => {
    setTimeout(() => {
      setShowSite(true);
    }, props.wait);
  }, [props.wait]);

  const renderSplash = () => {
    if (showSplash) {
      if (isAlcampo()) {
        return <IntroAlcampo />;
      } else {
        return <Intro />;
      }
    }
    return null;
  };

  return (
    <>
      {renderSplash()}
      <div
        className={`${showSite ? 'fadeIn' : 'fadeOut hidden'}`}
        onTransitionEnd={() => {
          showSite ? setShowSplash(false) : setShowSplash(true);
          sessionStorage.setItem('init', true);
        }}
      >
        <ThemeProvider theme={theme}>
          <Navbar {...props}>
            <Outlet />
          </Navbar>
        </ThemeProvider>
      </div>
    </>
  );
}
