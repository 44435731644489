import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import { useTranslation } from 'react-i18next';

import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';

const useStyles = makeStyles((theme) => ({
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));

export default function Step(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.step4',
  });
  const classes = useStyles();

  const [value, setValue] = props.value;
  return (
    <Box
      style={{
        width: '90%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        color={'primary'}
        variant={'h4'}
        className={classes.title_text}
      >
        {t('title1')}
        <span style={{ display: 'inline-block' }}>{t('title2')}</span>
      </Typography>
      <DatePicker
        label={t('labelDate')}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        slots={{
          textField: (params) => (
            <TextField
              {...params}
              sx={{ display: 'flex', width: '250px', margin: 'auto' }}
            />
          ),
        }}
      />
    </Box>
  );
}
