import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { JSEncrypt } from 'jsencrypt';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axiosAPI from '../../../axiosApi';
import StripeInput from './StripeInput';

const pub_key = process.env.REACT_APP_PUBKEY;

const useOptions = () => {
  const options = useMemo(
    () => ({
      supportedCountries: ['SEPA'],
      hideIcon: true,
      style: {
        base: {
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [],
  );

  return options;
};

const IbanForm = (props) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.admin.payment.ibanElement',
  });
  const options = useOptions();
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [valid, setValid] = useState(false);
  const [elementWidth, setElementWidth] = React.useState(
    window.innerWidth > 768 ? 330 : 270,
  );
  React.useEffect(() => {
    const handleResizeWindow = () => {
      setElementWidth(window.innerWidth > 768 ? 330 : 280);
    };
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [setElementWidth]);

  React.useEffect(() => {
    elements?.getElement(IbanElement)?.on('change', function (event) {
      if (event.error || event.empty) {
        setValid(false);
      } else if (!event.empty && event.complete) {
        setValid(true);
      }
    });
  }, [elements]);

  const [iban, setIban] = React.useState('');

  const handleChange = (event) => {
    const formattedIban = event.target?.value
      ?.trim()
      ?.replace(/\s/g, '');
    setIban(formattedIban);
    setValid(
      formattedIban.match(
        /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)(\d{25}|\d{19}[A-Za-z]\d{5}|\d{22}[A-Za-z]\d{2})|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/g,
      ),
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);

    if (props.iban_only) {
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(pub_key);
      await axiosAPI
        .post('updatePaymentMethod/', {
          type: 'sepa_debit',
          mode: props.iban_only,
          value: encrypt.encrypt(iban),
        })
        .then(() => {
          if (props.setChangePayment) {
            props.setChangePayment(false);
            props.setStatus('done');
          } else {
            props.handleNext();
          }
        })
        .catch(() => {
          props.setStatus('error');
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      // Create payment method
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: elements.getElement(IbanElement),
        billing_details: {
          name: `${props.data.first_name} ${props.data.last_name}`,
          email: props.data.email,
        },
        metadata: { type: 'payment' },
      });

      await axiosAPI
        .post('updatePaymentMethod/', {
          type: 'sepa_debit',
          mode: props.iban_only,
        })
        .then(async ({ data }) => {
          const { setupIntent } = await stripe.confirmSepaDebitSetup(
            data?.client_secret,
            {
              payment_method: paymentMethodReq.paymentMethod.id,
            },
          );
          switch (setupIntent?.status) {
            case 'succeeded':
              await axiosAPI.post(
                'validatePaymentMethod/',
                paymentMethodReq.paymentMethod.id,
              );
              setProcessing(false);
              if (props.setChangePayment) {
                props.setChangePayment(false);
                props.setStatus('done');
              } else {
                props.handleNext();
              }
              break;
            case 'processing':
              break;
            case 'requires_payment_method':
              props.setStatus('error');
              setProcessing(false);
              break;
            default:
              props.setStatus('error');
              setProcessing(false);
              break;
          }
        })
        .catch(() => {
          props.setStatus('error');
          setProcessing(false);
        });
    }
  };
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <Stack spacing={width < 1200 ? 2 : 6}>
      {props.iban_only ? (
        //textfield classic so we can get the value and encrypt it
        <TextField
          label="IBAN"
          name="iban"
          error={iban && !valid}
          variant="outlined"
          fullWidth
          sx={{ width: `${elementWidth}px`, margin: 'auto' }}
          value={iban}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountBalanceIcon />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        //stripe element version so it is tokenized to be handle in Stripe
        <TextField
          label="IBAN"
          name="iban"
          variant="outlined"
          fullWidth
          sx={{ width: `${elementWidth}px`, margin: 'auto' }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: IbanElement,
              options: { options },
            },
            startAdornment: (
              <InputAdornment position="start">
                <AccountBalanceIcon />
              </InputAdornment>
            ),
          }}
        />
      )}

      {processing ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction="row" spacing={2}>
            {props.setChangePayment && (
              <Button
                variant={'contained'}
                style={{
                  width: { xs: 'unset', md: '137px' },
                  margin: 'auto',
                  marginBottom: '10px',
                  height: { xs: 'unset', md: '62px' },
                  padding: { xs: '8px 16px', md: 'unset' },
                  clipPath: `polygon(2px 0, 0 62px, 337px 60px, ${elementWidth}px 3px, 2px 0)`,
                  borderRadius: 0,
                }}
                onClick={() => props.setChangePayment(false)}
              >
                {t('cancelButton')}
              </Button>
            )}
            <Button
              variant={'contained'}
              style={{
                width: { xs: 'unset', md: '137px' },
                margin: 'auto',
                marginBottom: '10px',
                height: { xs: 'unset', md: '62px' },
                padding: { xs: '8px 16px', md: 'unset' },
                clipPath: `polygon(2px 0, 0 62px, 337px 60px, ${elementWidth}px 3px, 2px 0)`,
                borderRadius: 0,
              }}
              onClick={handleSubmit}
              disabled={!stripe || !elements || !valid}
            >
              {t('confirmButton')}
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default IbanForm;
