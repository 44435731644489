import { Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import src_icon from '../../../../static/animal-complementary-info.svg';
import pricing_asset_3 from '../../../../static/pricing/pricing-asset-3.svg';
import pricing_asset_mobile_2 from '../../../../static/pricing/pricing-asset-mobile-2.svg';
import theme, { blue } from '../../../../theme';
import ButtonCustom from '../../../atoms/Button';
import { GUARD, MULTIPLE } from '../constants';
import { selectAnimals } from '../pricingSlice';
import Input from './Input';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    paddingTop: (props) => (props.isAccount ? 0 : 60),
    paddingRight: 10,
    paddingLeft: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: (props) => (props.isAccount ? 0 : 10),
      backgroundColor: 'unset',
    },
    paddingBottom: 10,
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  subtitle_text: {
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
  },
  icon: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  },
  typo: {
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
      color: blue,
    },
  },
}));

function AnimalAdditionalInfo({
  handleNext,
  step,
  isAccount,
  isLoading,
}) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalAdditionalInfo',
  });
  const classes = useStyles({ isAccount });
  const dispatch = useDispatch();
  const { formId, animals, animalType } = useSelector(
    (state) => state.pricing,
  );
  const multipleTemplate =
    (animalType === MULTIPLE || animalType === GUARD) &&
    animals?.length > 1;
  const animal = _.first(animals) || {};
  const handleNextStep = () => {
    if (isLoading) return;
    dispatch(selectAnimals(animals));
    handleNext({ step, id: formId, animals });
  };
  const [switchValues, setSwitchValues] = React.useState({
    VACCINES: true,
    NO_ILLNESS: true,
    CHIP: true,
  });
  const isValid = Object.values(switchValues).indexOf(false) < 0;
  return (
    <>
      {isAccount ? null : (
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: 'absolute',
            zIndex: -1,
          }}
          className={'pricing_asset_class'}
        >
          <img
            alt="svg"
            src={pricing_asset_3}
            style={{
              width: '10%',
              height: '100%',
              position: 'absolute',
              right: 0,
            }}
          />
        </Box>
      )}
      <section className={classes.root}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              color={'primary'}
              variant={'h4'}
              className={classes.title_text}
            >
              {!multipleTemplate ? (
                <>
                  {t('title')}
                  <span style={{ display: 'inline-block' }}>
                    {animal.name}?
                  </span>
                </>
              ) : (
                <>
                  {t('multiple.title1')}
                  <span style={{ display: 'inline-block' }}>
                    {t('multiple.title2')}
                  </span>
                </>
              )}
            </Typography>
          </Grid>
          {multipleTemplate ? (
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Typography
                className={classes.subtitle_text}
                color="primary"
              >
                {t('multiple.subtitle1')}
                <span style={{ display: 'inline-block' }}>
                  {t('multiple.subtitle2')}
                </span>
              </Typography>
            </Grid>
          ) : null}
          <Grid
            container
            direction="row"
            sx={{
              marginTop: '40px',
              width: {
                xs: 'none',
                sm: '655px',
                md: '700px',
                lg: '800px',
              },
            }}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={12} sm={4} className={classes.icon}>
              <img
                style={{ width: '100%', height: '100%' }}
                src={src_icon}
                alt={'img'}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              display="flex"
              justifyContent="center"
            >
              <Input
                classes={classes}
                switchValues={switchValues}
                setSwitchValues={setSwitchValues}
                t={t}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '20px',
              marginTop: {
                xs: isValid ? '30px' : '10px',
                md: isValid ? '90px' : '50px',
              },
            }}
          >
            {!isValid ? (
              <Typography
                color="error"
                style={{ fontSize: '1.1em', marginBottom: '10px' }}
              >
                {t('warning1')}
                <span style={{ display: 'inline-block' }}>
                  {t('warning2')}
                </span>
              </Typography>
            ) : null}
            <ButtonCustom
              onClick={() => {
                handleNextStep();
              }}
              width={122}
              height={47}
              disabled={!isValid}
            >
              {!isLoading ? (
                t('nextButton')
              ) : (
                <CircularProgress
                  sx={{
                    color: theme.palette.secondary.main,
                    display: 'block',
                  }}
                  thickness={5.0}
                  size="25px"
                />
              )}
            </ButtonCustom>
          </Grid>
        </Grid>
      </section>
    </>
  );
}

AnimalAdditionalInfo.componentName = 'stepLabels.additionalInfo';

export default AnimalAdditionalInfo;
