import React from 'react';
import { Outlet } from 'react-router-dom';

import Navbar from '../components/templates/pricing/Navbar';

export default function FeedbackLayout(props) {
  return (
    <Navbar {...props}>
      <Outlet />
    </Navbar>
  );
}
