import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageContext } from '../../providers/context/language';
import LogoAppStore from '../../static/logo_app_store.svg';
import LogoAppStoreEs from '../../static/logo_app_store_es.svg';
import LogoPlayStore from '../../static/logo_play_store.svg';
import LogoPlayStoreEs from '../../static/logo_play_store_es.svg';
import { isAlcampo } from '../../utils/language';

const StackItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'none',
  backgroundColor: '#f8e873',
}));
export default function UpdateAppReminder() {
  const { t } = useTranslation(null, {
    keyPrefix: 'updateAppReminder',
  });
  const { language } = useContext(LanguageContext);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="subtitle1" sx={{ pl: 7, pr: 7 }}>
            {isAlcampo() ? t('alcampoTitle') : t('title')}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="subtitle1" sx={{ pl: 7, pr: 7 }}>
            {t('subtitle1')}&nbsp;
            {isAlcampo() ? (
              <a href="https://alcampomascotas.es">
                alcampomascotas.es
              </a>
            ) : (
              <a href="https://drooky.com">drooky.com</a>
            )}
            .
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          alignItems="center"
          justifyContent="center"
        >
          <StackItem>
            <a
              href={process.env.REACT_APP_MOBILE_IOS_URL}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  language === 'fr' ? LogoAppStore : LogoAppStoreEs
                }
                alt="AppStore"
                style={{ width: '200px' }}
              />
            </a>
          </StackItem>
          <StackItem>
            <a
              href={process.env.REACT_APP_MOBILE_ANDROID_URL}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  language === 'fr' ? LogoPlayStore : LogoPlayStoreEs
                }
                alt="PlayStore"
                style={{ width: '200px' }}
              />
            </a>
          </StackItem>
        </Stack>
      </Grid>
    </Grid>
  );
}
