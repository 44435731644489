import { assoc, curry, keys, reduce } from 'ramda';
import { useTranslation } from 'react-i18next';

import createI18next from './createI18next';
import esLocales from './locales/es.json';
import frLocales from './locales/fr.json';

createI18next({ ...frLocales, ...esLocales });

const renameKeys = curry((keysMap, obj) =>
  reduce(
    (acc, key) => assoc(keysMap[key] || key, obj[key], acc),
    {},
    keys(obj),
  ),
);

export default function useI18nextProvider() {
  const { t, i18n } = useTranslation();

  return {
    translate: (key, options = {}) => {
      const enhancedOptions = renameKeys(
        { smart_count: 'count' },
        options,
      );
      return t(key, enhancedOptions);
    },
    changeLocale: (locale) => i18n.changeLanguage(locale),
    getLocale: () => i18n.language,
  };
}
