import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import pricing_asset_1 from '../../../../static/pricing/pricing-asset-1.svg';
import pricing_asset_mobile_2 from '../../../../static/pricing/pricing-asset-mobile-2.svg';
import MultipleAnimal from '../animalGender/MultipleAnimal';
import SingleAnimal from '../animalGender/SingleAnimal';
import { GUARD, MULTIPLE } from '../constants';
import { selectAnimals } from '../pricingSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    paddingTop: (props) => (props.isAccount ? 0 : 60),
    paddingRight: 10,
    paddingLeft: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: (props) => (props.isAccount ? 0 : 30),
      backgroundColor: 'unset',
    },
    paddingBottom: 10,
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

function AnimalGender({ handleNext, step, isAccount, isLoading }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalGender',
  });

  const classes = useStyles({ isAccount });
  const dispatch = useDispatch();
  const pricing = useSelector((state) => state.pricing);
  const { formId, animals, animalType } = pricing;
  const animal = _.first(animals) || {};
  const animalName = animal?.name
    ? `${animal.name.charAt(0).toUpperCase()}${animal.name.slice(1)}`
    : '';
  const handleNextStep = (animals) => {
    if (isLoading) return;
    dispatch(selectAnimals(animals));
    handleNext({ step, id: formId, animals });
  };

  const multipleTemplate =
    (animalType === MULTIPLE || animalType === GUARD) &&
    animals?.length > 1;
  return (
    <>
      {isAccount ? null : (
        <>
          <Box
            sx={{
              display: { xs: 'none', sm: 'block' },
              position: 'absolute',
              zIndex: -2,
            }}
            className={'pricing_asset_class'}
          >
            <img
              alt="svg"
              src={pricing_asset_1}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </>
      )}
      <section className={classes.root}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} className={classes.title_container}>
            <Typography
              color={'primary'}
              variant={'h4'}
              className={classes.title_text}
            >
              {!multipleTemplate ? (
                <>
                  <Typography
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      m: 0,
                      p: 0,
                    }}
                    color={'primary'}
                    variant={'h4'}
                    className={classes.title_text}
                  >
                    {' '}
                    {t('title1')}
                  </Typography>{' '}
                  {t('title2', { animalName })}
                  <br />
                  {t('title3')}
                  <span style={{ display: 'inline-block' }}>
                    {t('title4')}
                  </span>
                </>
              ) : (
                <>
                  {t('multiple.title1')}
                  <span style={{ display: 'inline-block' }}>
                    {t('multiple.title2')}
                  </span>
                </>
              )}
            </Typography>
          </Grid>
          {multipleTemplate ? (
            <MultipleAnimal
              isAccount={isAccount}
              isLoading={isLoading}
              handleNextStep={handleNextStep}
              t={t}
            />
          ) : (
            <SingleAnimal
              handleNextStep={handleNextStep}
              isLoading={isLoading}
              isAccount={isAccount}
              t={t}
            />
          )}
        </Grid>
      </section>
    </>
  );
}

AnimalGender.componentName = 'stepLabels.gender';

export default AnimalGender;
