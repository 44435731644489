import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';

import pricing_asset_icon_plus from '../../../../static/pricing/pricing-asset-icon-plus.svg';
import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';
import theme from '../../../../theme';
import ButtonCustom from '../../../atoms/Button';
import { DOG, GUARD } from '../constants';
import MultipleAnimalElement from './MultipleAnimalElement';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBottom: 10,
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  mobile_image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    justifyContent: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  plus_icon: {
    marginLeft: 250,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 225,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: -40,
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  animals: {
    width: '100%',
    marginTop: 20,
  },
  date: {
    justifyContent: 'center',
  },
  formWrapper: {
    margin: theme.spacing(3),
  },
}));

export default function MultipleAnimal(props) {
  const {
    onSubmit,
    animalsConfig,
    animalType,
    animalsInit,
    isLoading,
    t,
  } = props;

  const classes = useStyles();
  const [animals, setAnimals] = React.useState(animalsInit);
  useEffect(() => {
    if (animalsInit.length === 0) {
      setAnimals([
        { species: DOG, name: '' },
        ...(animalType === GUARD ? [] : [{ species: DOG, name: '' }]),
      ]);
    }
  }, [animalType, animalsInit]);
  const isValid = !animals
    .map((a) => !!a?.name?.trim()?.length)
    .includes(false);
  const handleSubmit = () => {
    const formattedAnimas = animals.map((a) => ({
      ...a,
      name: `${a.name.trim().charAt(0).toUpperCase()}${a.name
        .trim()
        .slice(1)}`,
    }));
    onSubmit(formattedAnimas);
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12} className={classes.title_container}>
        <Typography
          color={'primary'}
          variant={'h4'}
          className={classes.title_text}
        >
          {t('multipleAnimal.title1')}
          <span style={{ display: 'inline-block' }}>
            {t('multipleAnimal.title2')}
          </span>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html:
                t(
                  'animalConfig.multiple.title',
                  animalsConfig[animalType]?.title,
                ) || '',
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.animals}>
        {animals.map((e, index) => (
          <MultipleAnimalElement
            key={`MultipleAnimalElement_${index}`}
            animalType={animalType}
            animalsConfig={animalsConfig}
            index={index}
            animals={animals}
            setAnimals={setAnimals}
            t={t}
          />
        ))}
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center">
        <Button
          className={classes.plus_icon}
          onClick={() =>
            setAnimals([...animals, { species: DOG, name: '' }])
          }
        >
          <img
            src={pricing_asset_icon_plus}
            style={{ width: '40px', height: '40px' }}
            alt="ajoutez un animal !"
          />
        </Button>
        <Typography color={'#5E6C72'}>
          {t('multipleAnimal.addButton')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: '20px',
          marginTop: { xs: '30px', sm: '50px' },
        }}
      >
        <ButtonCustom
          onClick={handleSubmit}
          width={122}
          height={47}
          disabled={!isValid}
        >
          {!isLoading ? (
            t('multipleAnimal.button')
          ) : (
            <CircularProgress
              sx={{
                color: theme.palette.secondary.main,
                display: 'block',
              }}
              thickness={5.0}
              size="25px"
            />
          )}
        </ButtonCustom>
      </Grid>
    </Grid>
  );
}
