import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayErrorMessage } from '../../../../providers/snackbar';
import { getErrorTranslation } from '../../../../utils/language';
import axiosAPI from '../../../axiosApi';
import StripeInput from './StripeInput';

const useOptions = (theme) => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: theme.palette.primary.main,
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          fontSize: theme.fontSize,
        },
        invalid: {
          color: theme.palette.error.main,
        },
      },
    }),
    [
      theme.fontSize,
      theme.palette.primary.main,
      theme.palette.error.main,
    ],
  );

  return options;
};

const SplitForm = (props) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.admin.payment.creditCardElement',
  });
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions(theme);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);

    // Create payment method
    const paymentMethodReq = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      metadata: { type: 'payment' },
    });

    try {
      const {
        data: { client_secret },
      } = await axiosAPI.post('updatePaymentMethod/', {
        type: 'card',
      });

      const { setupIntent } = await stripe.confirmCardSetup(
        client_secret,
        {
          payment_method: paymentMethodReq.paymentMethod?.id,
        },
      );
      switch (setupIntent?.status) {
        case 'succeeded':
          await axiosAPI.post(
            'validatePaymentMethod/',
            paymentMethodReq.paymentMethod.id,
          );
          setProcessing(false);
          props.setChangePayment(false);
          props.setStatus('done');
          if (props.notifType === 'payment')
            await axiosAPI.post('removeNotification/', props.notifID);
          break;
        case 'processing':
          break;
        case 'requires_payment_method':
          props.setStatus('error');
          setProcessing(false);
          break;
        default:
          props.setStatus('error');
          setProcessing(false);
          break;
      }
    } catch (e) {
      displayErrorMessage(getErrorTranslation());
    }
  };

  return (
    <Stack spacing={4}>
      <TextField
        label={t('labelCardNumber')}
        name="ccnumber"
        variant="outlined"
        fullWidth
        sx={{ width: '235px', margin: 'auto' }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardNumberElement,
            options: { options },
          },
          startAdornment: (
            <InputAdornment position="start">
              <CreditCardIcon />
            </InputAdornment>
          ),
        }}
      />
      <Stack
        direction="row"
        spacing={2}
        style={{ margin: '32px auto 0' }}
      >
        <TextField
          label={t('labelExpirationCard')}
          name="cexpiryElement"
          variant="outlined"
          fullWidth
          sx={{ width: '110px' }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
              options: { options },
            },
            startAdornment: (
              <InputAdornment position="start">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label={t('labelCVC')}
          name="ccvcElement"
          variant="outlined"
          fullWidth
          sx={{ width: '110px' }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
              options: { options },
            },
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {processing ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack
            direction="row"
            spacing={10}
            style={{ margin: '32px auto 0' }}
          >
            <Button
              variant={'contained'}
              style={{
                width: { xs: 'unset', md: '137px' },
                height: { xs: 'unset', md: '62px' },
                padding: { xs: '8px 16px', md: 'unset' },
                clipPath:
                  'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
                borderRadius: 0,
              }}
              onClick={() => props.setChangePayment(false)}
            >
              {t('cancelButton')}
            </Button>

            <Button
              variant={'contained'}
              style={{
                width: { xs: 'unset', md: '137px' },
                height: { xs: 'unset', md: '62px' },
                padding: { xs: '8px 16px', md: 'unset' },
                clipPath:
                  'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
                borderRadius: 0,
              }}
              onClick={handleSubmit}
              disabled={!stripe}
            >
              {t('confirmButton')}
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default SplitForm;
