import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import quote_2_select1 from '../../../static/quote/2-select1.svg';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: '150px',
  height: '52.55px',
  backgroundColor: '#9EBECC',
  borderRadius: 0,
  clipPath:
    'polygon(2.01px 2px, 1.02px 74.54px, 149.08px 50.55px, 146.12px 1.01px, 2.01px 2px)',
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      transform: 'translateX(1px)',
      '& .MuiSwitch-thumb': {
        clipPath:
          'polygon(150.68px 0.89px, 74.68px 1.89px, 74.68px 73.89px, 149.68px 73.89px, 145.68px 0.89px)',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#9EBECC',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    transition: 'all .5s',
    '@supports (-webkit-appearance:none) and (stroke-color:transparent)':
      {
        transition: 'unset',
      },
    transform: 'translateX(-10px) translateY(-9px)',
    backgroundColor: theme.palette.primary.main,
    width: '1000px',
    height: '1000px',
    borderRadius: 0,
    clipPath:
      'polygon(74.39px 1.67px, 2.18px 1.83px, 1.35px 74.38px, 74.72px 73.88px, 74.39px 1.67px)',
    '&:hover': {
      backgroundColor: '#9EBECC',
    },
  },
  '& .MuiSwitch-track': {
    transform: 'translateY(-12px)',
    opacity: 1,
    backgroundColor: '#9EBECC',
    borderRadius: 0,
    width: '150px',
    height: '52.55px',
    clipPath:
      'polygon(2.01px 2px, 1.02px 74.54px, 149.08px 73.55px, 145.12px 1.01px, 2.01px 2px)',
  },
}));

export default function CustomizedSwitches({ onChange, checked }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'quote.switch',
  });
  return (
    <>
      <Box
        style={{ position: 'relative' }}
        sx={{
          width: { xs: '150px' },
          m: { xs: 'auto', md: 'inherit' },
          mt: { md: '-56px' },
          ml: { md: '45px' },
        }}
      >
        <Box>
          <img
            alt="svg"
            src={quote_2_select1}
            style={{
              position: 'absolute',
              top: '-1px',
              left: 0,
              width: '156px',
              height: '56px',
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '156px',
              height: '56px',
            }}
          >
            <MaterialUISwitch
              checked={checked}
              onChange={(event) => onChange(event.target.checked)}
              inputProps={{ 'aria-label': 'Switch A' }}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              pointerEvents: 'none',
              width: '156px',
              height: '56px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              color={checked ? 'primary' : 'secondary'}
              variant={'h4'}
              style={{
                width: '78px',
                fontSize: 23,
                transition: 'all .5s',
                '@supports (-webkit-appearance:none) and (stroke-color:transparent)':
                  {
                    transition: 'unset',
                  },
              }}
            >
              {t('no')}
            </Typography>
            <Typography
              color={checked ? 'secondary' : 'primary'}
              variant={'h4'}
              style={{
                width: '78px',
                fontSize: 23,
                transition: 'all .5s',
                '@supports (-webkit-appearance:none) and (stroke-color:transparent)':
                  {
                    transition: 'unset',
                  },
              }}
            >
              {t('yes')}
            </Typography>
          </div>
        </Box>
      </Box>
    </>
  );
}
