import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import IconBirthDay from '../../../../static/birthday.svg';
import pricing_asset_mobile_7 from '../../../../static/pricing/pricing-asset-mobile-7.svg';
import theme from '../../../../theme';
import { subtractYearsFromDate } from '../../../../utils/globalFunctions';
import ButtonCustom from '../../../atoms/Button';
import { selectBirthday } from '../pricingSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: (props) => (props.isAccount ? 0 : 60),
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: (props) => (props.isAccount ? 0 : 10),
    },
  },
  form: {
    margin: 5,
  },
  text_field_labeltext_field_label: {
    color: theme.palette.primary.main,
  },
  text_field: {
    color: theme.palette.primary.main,
    '&:before': {
      color: theme.palette.primary.main,
    },
    '&:after': {
      color: theme.palette.primary.main,
    },
  },
  text_field_label_error: {
    color: theme.palette.error.main + ' !important',
  },
  text_field_error: {
    color: theme.palette.error.main + ' !important',
    '&:before': {
      borderColor: theme.palette.error.main + ' !important',
    },
    '&:after': {
      borderColor: theme.palette.error.main + ' !important',
    },
  },
  title_text: {
    fontSize: '2.1rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_7})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: 20,
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  text_field_label: {
    color: theme.palette.primary.main,
  },
  input_container: {
    marginTop: 50,
  },
  submit_btn: {
    marginTop: 50,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
  },
  date_picker: {
    maxWidth: 206,
    color: theme.palette.primary.main,
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '& input': {
      cursor: 'pointer',
    },
  },
}));
function Birthday({ step, handleNext, isLoading, isAccount }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.birthday',
  });
  const { formId, firstName, birthday } = useSelector(
    (state) => state.pricing,
  );
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    birthday: yup
      .date()
      .required()
      .max(new Date())
      .min(new Date('1900-01-01')),
  });
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { isValid } = formState;
  const onSubmit = (data) => {
    if (isLoading) return;
    const birthday = data.birthday?.toISOString().slice(0, 10);
    dispatch(selectBirthday(birthday));
    handleNext({ step, id: formId, birthday });
  };
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = useStyles({ isAccount });
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.root}
        spacing={0}
      >
        <Grid item xs={12} className={classes.title_container}>
          <Typography
            color={'primary'}
            variant={'h4'}
            className={classes.title_text}
          >
            {firstName},<br />
            {t('title1')}
            <span style={{ display: 'inline-block' }}>
              {t('title2')}
            </span>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          className={classes.input_container}
        >
          <Box
            component="img"
            src={IconBirthDay}
            sx={{
              width: { xs: '50px', sm: '80px' },
              marginRight: { xs: '20px', sm: '40px' },
            }}
          />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Controller
              control={control}
              name="birthday"
              defaultValue={birthday ? new Date(birthday) : null}
              render={({ field }) => (
                <MobileDatePicker
                  views={['year', 'month']}
                  slots={{
                    textField: (props) => (
                      <TextField
                        {...props}
                        InputLabelProps={{
                          className: classes.text_field_label,
                        }}
                        label={t('labelBirthday')}
                      />
                    ),
                  }}
                  open={isOpen}
                  cancelText={t('cancel')}
                  okText={'Ok'}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  onYearChange={(date) => {
                    field.onChange(date);
                  }}
                  onMonthChange={(date) => {
                    field.onChange(date);
                    setIsOpen(false);
                  }}
                  onOpen={() => {
                    setIsOpen(true);
                  }}
                  onAccept={() => setIsOpen(false)}
                  onClose={() => setIsOpen(false)}
                  showToolbar={false}
                  minDate={new Date('1900-01-01')}
                  maxDate={subtractYearsFromDate(18)}
                  {...field}
                  InputProps={{ className: classes.date_picker }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.submit_btn}>
          <ButtonCustom
            type="submit"
            width={165}
            height={47}
            disabled={!isValid}
          >
            {!isLoading ? (
              t('nextButton')
            ) : (
              <CircularProgress
                sx={{
                  color: theme.palette.secondary.main,
                  display: 'block',
                }}
                thickness={5.0}
                size="25px"
              />
            )}
          </ButtonCustom>
        </Grid>
      </Grid>
    </form>
  );
}

Birthday.componentName = 'stepLabels.birthday';

export default Birthday;
