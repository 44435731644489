import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import pricing_asset_mobile_2 from '../../../../static/pricing/pricing-asset-mobile-2.svg';
import pricing_asset_mobile_emoticon from '../../../../static/pricing/pricing-asset-mobile-emoticon.svg';
import { isAlcampo } from '../../../../utils/language';
import { MALE } from '../constants';
import Form from './Form';
import { calculateMaxAnimalAge } from './utils';

const useStyles = makeStyles((theme) => ({
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  mobile_image: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  formWrapper: {
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
}));
export default function Pricing(props) {
  const { animals, control, register, t } = props;
  const animal = animals[0];
  const isMale = animal?.gender === MALE;
  const animalName = animal?.name
    ? `${animal.name.charAt(0).toUpperCase()}${animal.name.slice(1)}`
    : '';

  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.title_container}>
        <Typography
          sx={{ display: { xs: 'none', sm: 'block' } }}
          color={'primary'}
          variant={'h4'}
          className={classes.title_text}
        >
          {t('title', { animalName })}
        </Typography>
        <Typography
          sx={{ display: { xs: 'block', sm: 'none' } }}
          color={'primary'}
          variant={'h4'}
          className={classes.title_text}
        >
          {t('mobile.title1')}
          <span style={{ display: 'inline-block' }}>
            {animalName} ?
          </span>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: '10px', marginBottom: '20px' }}
        alignItems="center"
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        <Typography
          style={{
            fontSize: '1em',
            fontWeight: 'bold',
            paddingLeft: '5px',
            paddingRight: '5px',
          }}
          color="primary"
        >
          {t('mobile.subtitle1')}
          <span
            style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            {t('mobile.subtitle2')}
            <img
              alt="emoticon"
              style={{
                width: '25px',
                height: '25px',
                marginLeft: '5px',
              }}
              src={pricing_asset_mobile_emoticon}
            />
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.formWrapper}>
        <Form
          control={control}
          register={register}
          animal={animal}
          t={t}
          {...props}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '30px' }}>
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            paddingLeft: '30px',
            paddingRight: '30px',
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: '1.1em', textAlign: 'left' }}
          >
            {t('tips.ageWarning1', { animalName })}
            <span style={{ display: 'inline-block' }}>
              {' '}
              {props.provider === 'wakam'
                ? Math.ceil(calculateMaxAnimalAge(animal) / 12)
                : isAlcampo()
                ? 6
                : 8}{' '}
              {t('tips.ageWarning2')}
            </span>
            <br />
            {isMale
              ? t('mobile.tips.maleAgeWarning')
              : t('mobile.tips.femaleAgeWarning')}
            <span style={{ display: 'inline-block' }}>
              {' '}
              {t('tips.ageWarning3')}
              <FavoriteIcon
                style={{
                  fontSize: 'inherit',
                  color: '#d32d00',
                  transform: 'translateY(3px)',
                }}
              />
            </span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' },
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: '1.1em', textAlign: 'center' }}
          >
            {t('mobile.tips.ageWarning1', { animalName })}
            {props.provider === 'wakam'
              ? Math.ceil(calculateMaxAnimalAge(animal) / 12)
              : isAlcampo()
              ? 6
              : 8}{' '}
            {isMale
              ? t('mobile.tips.maleAgeWarning')
              : t('mobile.tips.femaleAgeWarning')}
            <span style={{ display: 'inline-block' }}>
              {t('mobile.tips.ageWarning2')}
              <FavoriteIcon
                style={{
                  fontSize: 'inherit',
                  color: '#d32d00',
                  transform: 'translateY(3px)',
                }}
              />
            </span>
          </Typography>
        </Box>
      </Grid>
    </>
  );
}
