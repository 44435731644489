import Backdrop from '@mui/material//Backdrop';
import { ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import { createContext } from 'react';
import { Outlet } from 'react-router-dom';

import Modal from '../components/molecules/Modal';
import Navbar from '../components/templates/home/Navbar';
import theme from '../theme/home';

export const Context = createContext(() => {});

export default function QuoteLayout(props) {
  const [open, setOpen] = useState(false);
  const [activeObject, setActiveObject] = useState(null);
  const [modalStyle, setModalStyle] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    const childrenProps = activeObject?.props;
    if (childrenProps?.close) {
      childrenProps.close(childrenProps.defaultAge);
      return;
    }
    setOpen(false);
    setModalStyle(null);
  };

  const openModal = (component, style) => {
    setActiveObject(component);
    if (style) setModalStyle(style);
    setOpen(true);
  };

  const closeModal = () => {
    setActiveObject(null);
    setOpen(false);
    setModalStyle(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Context.Provider value={[openModal, closeModal]}>
        <Navbar {...props}>
          <Outlet />
          <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            fit
            modalStyle={modalStyle}
            sx={{ overflow: { xs: 'none', md: 'scroll' } }}
          >
            {activeObject}
          </Modal>
        </Navbar>
      </Context.Provider>
    </ThemeProvider>
  );
}
