export const getCalendlyUrl = (userLanguage) => {
  if (process.env.REACT_APP_LANGUAGE === 'fr') {
    return userLanguage === 'fr'
      ? process.env.REACT_APP_CALENDLY_URL_DROOKY_FR
      : process.env.REACT_APP_CALENDLY_URL_DROOKY_ES;
  }
  return userLanguage === 'fr'
    ? process.env.REACT_APP_CALENDLY_URL_ALCAMPO_FR
    : process.env.REACT_APP_CALENDLY_URL_ALCAMPO_ES;
};

const methods = {
  getCalendlyUrl,
};

export default methods;
