import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayErrorMessage } from '../../../../providers/snackbar';
import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';
import {
  getErrorTranslation,
  getStripeErrorTranslation,
} from '../../../../utils/language';
import Widget from '../../../atoms/Widget';
import axiosAPI from '../../../axiosApi';
import StripeInput from '../../quote/StripeInput';

const useStyles = makeStyles((theme) => ({
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));

const useOptions = (theme) => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: theme.palette.primary.main,
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          fontSize: theme.fontSize,
        },
        invalid: {
          color: theme.palette.error.main,
        },
      },
    }),
    [
      theme.fontSize,
      theme.palette.primary.main,
      theme.palette.error.main,
    ],
  );

  return options;
};

const Checkout = (props) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.notifications',
  });
  const classes = useStyles();
  const theme = useTheme();
  const options = useOptions(theme);
  const elements = useElements();
  const stripe = useStripe();

  const [processing, setProcessing] = useState(false);
  const [useDefault, setUseDefault] = useState(false);
  const [asDefault, setAsDefault] = useState(false);
  const [error, setError] = useState();
  const [paymentIntent, setPaymentIntent] = useState();

  useEffect(() => {
    axiosAPI
      .get(
        `notification_payment_intents/?notification_id=${props.notifID}`,
      )
      .then((response) => {
        setPaymentIntent(response.data);
      })
      .catch((error) => {
        displayErrorMessage(
          getErrorTranslation(error.response?.data),
        );
        setPaymentIntent(null);
      });
  }, [props.notifID]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setProcessing(true);
    try {
      let intent = paymentIntent;
      let payment_method_id = null;
      if (!useDefault) {
        const { paymentMethod, error } =
          await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            metadata: { type: 'payment' },
          });
        if (error) {
          throw error;
        }
        payment_method_id = paymentMethod.id;
      }
      let response = await axiosAPI.patch(
        'notification_payment_intents/',
        {
          notification_id: props.notifID,
          payment_method_id: payment_method_id,
          as_default: asDefault,
        },
      );
      intent = response.data;
      setPaymentIntent(intent);

      response = await stripe.confirmCardPayment(
        intent.client_secret,
      );
      if (response.error) {
        throw response.error;
      }
      intent = {
        ...intent,
        intent_status: response.paymentIntent.status,
      };
      setPaymentIntent(intent);
      if (response.paymentIntent.status === 'succeeded') {
        await axiosAPI.post('remove_notification/', {
          notification_id: props.notifID,
        });
      }
    } catch (error) {
      console.log(error);
      let error_message = null;
      if (error.response?.data) {
        // Handle error from backend API
        if (
          error.response.data.error_type ===
          'payment_intent_save_failed'
        ) {
          // Handle error from stripe in backend api
          error_message = getStripeErrorTranslation(
            error.response.data.error_code,
          );
        }
        // Handle other backend Api errors
        error_message = getErrorTranslation(error.response.data);
      } else if (error.code) {
        // Handle error from stripe
        error_message = getStripeErrorTranslation(error.code);
      } else {
        displayErrorMessage(error);
      }
      setError(error_message);
    } finally {
      setProcessing(false);
    }
  };

  let content;
  if (!paymentIntent) {
    content = <CircularProgress sx={{ margin: 'auto' }} />;
  } else {
    if (paymentIntent.intent_status === 'succeeded') {
      content = (
        <>
          <CheckCircleOutlineIcon
            color="success"
            style={{ fontSize: '20rem', margin: '0 auto' }}
          />
          <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
            {t('confirmPayment')}
          </Typography>
        </>
      );
    } else {
      content = (
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <Box>
            <Typography
              color={'primary'}
              variant={'h4'}
              className={classes.title_text}
            >
              {t('payment')}
            </Typography>
          </Box>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useDefault}
                  onChange={(event) =>
                    setUseDefault(event.target.checked)
                  }
                />
              }
              label={t('formControlLabel1')}
            />
          </FormGroup>

          {useDefault ? (
            ''
          ) : (
            <Stack spacing={6}>
              <TextField
                label={t('labelCardNumber')}
                name="ccnumber"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                    options: { options },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Stack direction="row" spacing={0}>
                <TextField
                  label={t('labelExpirationCard')}
                  name="cexpiryElement"
                  variant="outlined"
                  // required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardExpiryElement,
                      options: { options },
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  label={t('labelCVC')}
                  name="ccvcElement"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardCvcElement,
                      options: { options },
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={asDefault}
                      onChange={(event) =>
                        setAsDefault(event.target.checked)
                      }
                    />
                  }
                  label={t('formControlLabel2')}
                />
              </FormGroup>
            </Stack>
          )}

          <div style={{ textAlign: 'center' }}>
            <Button
              variant={'contained'}
              onClick={handleSubmit}
              disabled={!stripe}
            >
              {processing
                ? t('paymentProcessing')
                : t('pay') +
                  ' ' +
                  paymentIntent?.price.toFixed(2).replace('.', ',') +
                  ' €'}
            </Button>
          </div>
          <div
            style={{
              color: 'red',
              marginTop: '10px',
              textAlign: 'center',
            }}
          >
            {error}
          </div>
        </Stack>
      );
    }
  }

  return <Widget color="white">{content}</Widget>;
};

export default Checkout;
