import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import React, { useState } from 'react';

import { isAlcampo } from '../../../../utils/language';
import './style.css';

const libraries = ['places'];
const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
}));

function MyComponent({
  setAddressValue,
  loading,
  isManualAddress,
  setIsManualAddress,
  control,
  register,
  errors,
  t,
}) {
  const classes = useStyles();
  const [autoComplete, setAutoComplete] = useState(null);

  const onPlacesChanged = () => {
    const place = autoComplete.getPlace();
    const hasPostalCode =
      !!place &&
      !!place?.address_components?.find((a) =>
        a.types.includes('postal_code'),
      );
    if (!hasPostalCode) return;
    setAddressValue(place.address_components);
  };

  const onLoad = (autoComplete) => {
    setAutoComplete(autoComplete);
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlacesChanged}
        options={{
          types: ['street_address', 'route'],
          componentRestrictions: {
            country: isAlcampo()
              ? ['es']
              : ['fr', 'gp', 'mf', 're', 'mq'],
          },
        }}
      >
        <TextField
          className={classes.input}
          variant="standard"
          id="address"
          label={t('googleMaps.addressField')}
          placeholder={t('googleMaps.placeholderAddress')}
          {...register('address')}
          disabled={loading}
          sx={{ display: isManualAddress ? 'none' : 'flex' }}
        />
      </Autocomplete>
      <TextField
        control={control}
        {...register('street')}
        label={t('googleMaps.streetNumber')}
        variant="standard"
        InputLabelProps={{
          className: errors?.street
            ? classes.text_field_label_error
            : '',
        }}
        InputProps={{
          className: errors?.street ? classes.text_field_error : '',
        }}
        disabled={loading}
        style={{
          display: isManualAddress ? 'flex' : 'none',
        }}
      />
      <TextField
        control={control}
        {...register('postal_code')}
        label={t('googleMaps.postalCode')}
        variant="standard"
        InputLabelProps={{
          className: errors?.postal_code
            ? classes.text_field_label_error
            : '',
        }}
        InputProps={{
          className: errors?.postal_code
            ? classes.text_field_error
            : '',
        }}
        disabled={loading}
        style={{
          display: isManualAddress ? 'flex' : 'none',
        }}
      />
      <TextField
        control={control}
        {...register('city')}
        label={t('googleMaps.city')}
        variant="standard"
        InputLabelProps={{
          className: errors?.city
            ? classes.text_field_label_error
            : '',
        }}
        InputProps={{
          className: errors?.city ? classes.text_field_error : '',
        }}
        disabled={loading}
        style={{
          display: isManualAddress ? 'flex' : 'none',
        }}
      />
      <p style={{ color: 'gray', margin: 0, textAlign: 'left' }}>
        {t('googleMaps.notFindAddress')}
        <Checkbox
          checked={isManualAddress}
          onChange={(e) => setIsManualAddress(e.target.checked)}
        />
      </p>
    </LoadScript>
  );
}

export default React.memo(MyComponent);
