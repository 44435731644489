import FavoriteIcon from '@mui/icons-material/Favorite';
import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useWindowSize } from 'use-hooks';

import { LanguageContext } from '../../../providers/context/language';
import alambic from '../../../static/anims/alambic.json';
import bird_left from '../../../static/anims/bird-left.json';
import bird_white_big from '../../../static/anims/bird-white-big.json';
import bird_yellow_small from '../../../static/anims/bird-yellow-small.json';
import bras_d from '../../../static/anims/bras-D.json';
import chat_chaise from '../../../static/anims/chat-chaise.json';
import chat_froid from '../../../static/anims/chat-froid.json';
import chat_ligote from '../../../static/anims/chat-ligote.json';
import chat_plage from '../../../static/anims/chat-plage.json';
import chien_pompe from '../../../static/anims/chien-pompe.json';
import chien_tasse from '../../../static/anims/chien-tasse.json';
import doudou from '../../../static/anims/doudou.json';
import enveloppe from '../../../static/anims/enveloppe.json';
// import balance from "../../../static/anims/coeur_balance.json";
import main_soins from '../../../static/anims/main-soins.json';
import main_soins_alcampo from '../../../static/anims/main-soins_alcampo.json';
import motion_2euros from '../../../static/anims/motion-2euros.json';
import motionTwoEurosAlcampo from '../../../static/anims/motion-2euros_alcampo.json';
import palmier_d from '../../../static/anims/palmier-D.json';
import palmier_g from '../../../static/anims/palmier-G.json';
import requin from '../../../static/anims/requin.json';
import chien_boxe from '../../../static/home/chien_boxe.svg';
import home_elements_3 from '../../../static/home/elements/Fichier 3.svg';
import home_elements_8 from '../../../static/home/elements/Fichier 8.svg';
import home_elements_9 from '../../../static/home/elements/Fichier 9.svg';
import home_elements_10 from '../../../static/home/elements/Fichier 10.svg';
import home_elements_11 from '../../../static/home/elements/Fichier 11.svg';
import home_elements_13 from '../../../static/home/elements/Fichier 13.svg';
// import home_elements_15 from "../../../static/home/elements/Fichier 15.svg";
import home_elements_18 from '../../../static/home/elements/Fichier 18.svg';
import home_elements_19 from '../../../static/home/elements/Fichier 19.svg';
import home_elements_20 from '../../../static/home/elements/Fichier 20.svg';
import home_elements_21 from '../../../static/home/elements/Fichier 21.svg';
import home_elements_22 from '../../../static/home/elements/Fichier 22.svg';
import home_elements_23 from '../../../static/home/elements/Fichier 23.svg';
import home_elements_24 from '../../../static/home/elements/Fichier 24.svg';
import home_elements_25 from '../../../static/home/elements/Fichier 25.svg';
import home_elements_26 from '../../../static/home/elements/fichier-26.svg';
import home_elements_27 from '../../../static/home/elements/fichier-27.svg';
import home_elements_28 from '../../../static/home/elements/fichier-28.svg';
import home_elements_29 from '../../../static/home/elements/fichier-29.svg';
import home_elements_2 from '../../../static/home/elements/fichier_2.svg';
import home_elements_12 from '../../../static/home/elements/fichier_12.svg';
import home_level_0_left from '../../../static/home/home-0-left-new.svg';
import home_level_0_right from '../../../static/home/home-0-right-new.svg';
import home_level_0_transition from '../../../static/home/home-0-transition.png';
import home_level_1_transition from '../../../static/home/home-1-transition.png';
import home_level_2_left_back from '../../../static/home/home-2-left-back.svg';
import home_level_2_left_front from '../../../static/home/home-2-left-front.svg';
import home_level_2_right_back from '../../../static/home/home-2-right-back.svg';
import home_level_2_right_front from '../../../static/home/home-2-right-front.svg';
import home_level_2_transition from '../../../static/home/home-2-transition.png';
import home_level_4_1_transition from '../../../static/home/home-4-1-transition.png';
// import home_level_3_transition from "../../../static/home/home-3-transition.png";
import home_level_4_transition from '../../../static/home/home-4-transition.png';
import home_level_7_left from '../../../static/home/home-7-left.svg';
import home_level_7_left_alcampo from '../../../static/home/home-7-left_alcampo.svg';
import home_level_7_right from '../../../static/home/home-7-right.svg';
import home_level_7_transition from '../../../static/home/home-7-transition.png';
import home_level_8_transition from '../../../static/home/home-8-transition.png';
import home_alambique_transition from '../../../static/home/home-alambique-transition.png';
import bftm_tv from '../../../static/home/logos/bfmtv.svg';
import france_3 from '../../../static/home/logos/france3.svg';
import france_bleu from '../../../static/home/logos/francebleu.svg';
import les_echos from '../../../static/home/logos/lesechos.svg';
import home_elements_3_mobile from '../../../static/home/mobile/elements/Fichier 3.svg';
import home_elements_7_mobile from '../../../static/home/mobile/elements/Fichier 7.svg';
import home_elements_9_mobile from '../../../static/home/mobile/elements/Fichier 9.svg';
import home_elements_10_mobile from '../../../static/home/mobile/elements/Fichier 10.svg';
import home_elements_8_mobile from '../../../static/home/mobile/elements/fichier-8.svg';
import home_elements_2_mobile from '../../../static/home/mobile/elements/fichier_2.svg';
import home_level_7_transition_mobile from '../../../static/home/mobile/home-section-7-transition.svg';
import home_level_7_mobile from '../../../static/home/mobile/home-section-7.svg';
import home_level_7_mobile_alcampo from '../../../static/home/mobile/home-section-7_alcampo.svg';
import home_level_8_transition_mobile from '../../../static/home/mobile/home-section-8-transition-mobile.svg';
import home_level_1_transition_mobile from '../../../static/home/mobile/home-section-transition-1.svg';
import home_level_4_transition_mobile from '../../../static/home/mobile/home-section-transition-4.svg';
import star from '../../../static/home/star.svg';
import { blue_light } from '../../../theme';
import { isAlcampo } from '../../../utils/language';
import referenceIntersection from '../../../utils/referenceIntersection';
import Footer from './Footer';

const refSize = 1920;
const refSizeMobile = 360;

const minZoomMobile = 400;
const minZoomDesktop = 1000;
const midZoomDesktop = 1500;
const maxZoomDesktop = 2100;

const zoomDesktop = midZoomDesktop / refSize;

const heightDesktop = 880;
const heightMobile = 600;

export const useStyles = makeStyles((theme) => ({
  zoomHome: {
    background: theme.palette.secondary.main,
    height: '100%',
    width: '100%',
    [theme.breakpoints.between(minZoomMobile, midZoomDesktop)]: {
      zoom: (props) => props.zoom,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': (props) => `scale(${props.zoom})`,
        '-moz-transform-origin': '0 0',
        width: (props) => `${100 * (1 / props.zoom)}vw`,
      },
    },
    [theme.breakpoints.between(midZoomDesktop, maxZoomDesktop)]: {
      zoom: zoomDesktop,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': `scale(${zoomDesktop})`,
        '-moz-transform-origin': '0 0',
        width: `${100 * (1 / zoomDesktop)}vw`,
      },
    },
  },
  homeSection: {
    display: 'flex',
    position: 'relative',
    height: heightMobile,
    overflow: 'hidden',
    zIndex: 0,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
    },
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginTop: 220,
    },
  },
  homeSectionVideo1: {
    position: 'relative',
    height: isAlcampo() ? 470 : 600,
    overflow: 'hidden',
    zIndex: 0,
    [theme.breakpoints.up(768)]: {
      height: 700,
    },
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: isAlcampo() ? 1350 : 1600,
    },
  },
  homeSectionVideo2: {
    position: 'relative',
    height: isAlcampo() ? 350 : 500,
    overflow: 'hidden',
    zIndex: 0,
    [theme.breakpoints.up(768)]: {
      height: 550,
    },
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: isAlcampo() ? 968 : 1250,
      marginTop: 50,
    },
  },
  homeSection1: {
    position: 'relative',
    height: heightMobile,
    overflow: 'hidden',
    background: isAlcampo() && blue_light,
    zIndex: 0,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
    },
  },
  homeSection2: {
    position: 'relative',
    height: 725,
    overflow: 'hidden',
    background: `linear-gradient(${blue_light} 86%, #ffffff 14%)`,
    zIndex: 1,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 1030,
    },
    [theme.breakpoints.down('sm')]: {
      height: 900,
      background: 'rgb(234, 241, 244)',
    },
  },
  homeSection3: {
    position: 'relative',
    height: '550px',
    marginTop: -59.8,
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
    },
    [theme.breakpoints.down('sm')]: {
      height: '700px',
    },
  },
  homeSectionSupercat: {
    position: 'relative',
    height: '540px',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      marginTop: -60,
    },
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
      marginTop: -148,
    },
  },
  homeSectionAlambic: {
    position: 'relative',
    height: isAlcampo() ? 900 : '740px',
    // backgroundColor: blue_light,
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
      marginTop: 0,
    },
  },
  homeSectionBalance: {
    position: 'relative',
    height: '720px',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 550,
      marginTop: 20,
    },
  },
  balanceAnim: {
    width: '100%',
    maxWidth: '1300px',
    margin: '-100px auto',
    [theme.breakpoints.up('sm')]: {
      margin: '-130px auto',
    },
    [theme.breakpoints.up(minZoomDesktop)]: {
      margin: '-350px auto',
    },
  },
  homeSectionPhone: {
    display: 'none',
    position: 'relative',
    height: '740px',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
      marginTop: 0,
      display: 'block',
    },
  },
  homeSection4: {
    position: 'relative',
    background: blue_light,
    height: 2 * heightMobile,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 2 * heightDesktop,
      paddingTop: '20px',
    },
  },
  homeSection5: {
    position: 'relative',
    background: blue_light,
    height: 1130,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 2.5 * heightDesktop,
    },
  },
  homeSection6: {
    display: 'flex',
    position: 'relative',
    background: '#ffffff',
    height: 315,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
    },
  },
  homeSection12: {
    display: 'flex',
    position: 'relative',
    background: '#ffffff',
    height: isAlcampo() ? 200 : 315,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 440,
    },
  },
  homeSection7: {
    display: 'flex',
    position: 'relative',
    background: blue_light,
    height: heightMobile,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
    },
  },
  homeSection8: {
    position: 'relative',
    background: '#ffffff',
    height: 600,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 1400,
    },
  },
  imgMobile: {
    [theme.breakpoints.between(minZoomMobile, minZoomDesktop)]: {
      transform: ({ zoom }) =>
        `translateX(calc(${50 / zoom}vw - ${refSizeMobile / 2}px))`,
    },
    [theme.breakpoints.down(minZoomMobile)]: {
      transform: `translateX(calc((100vw - ${refSizeMobile}px)/2))`,
    },
  },
  imgLeft: {
    transform: `translateX(calc((100vw - ${refSize}px)/2))`,
    [theme.breakpoints.up(minZoomDesktop)]: {
      transform: 'translateX(0px)',
    },
  },
  imgRight: {
    transform: `translateX(calc((100vw - ${refSize}px)/2))`,
    [theme.breakpoints.between(minZoomDesktop, midZoomDesktop)]: {
      transform: 'translateX(0px)',
    },
    [theme.breakpoints.between(midZoomDesktop, maxZoomDesktop)]: {
      transform: `translateX(calc(${
        100 / zoomDesktop
      }vw - ${refSize}px))`,
    },
    [theme.breakpoints.up(maxZoomDesktop)]: {
      transform: `translateX(calc(100vw - ${refSize}px))`,
    },
  },
  homeContent: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginTop: -220,
    },
  },
  homeSection0Title: {
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginBottom: 80,
      marginTop: 230,
    },
    [theme.breakpoints.down(minZoomDesktop, maxZoomDesktop)]: {
      paddingTop: 50,
    },
  },
  homeSection0SubTitle: {
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginBottom: 110,
    },
  },
  homeSection0SubTitle2: {
    fontSize: '12px',
    lineHeight: '14.4px',
    marginTop: '10px',
    color: '#797C7C',
    [theme.breakpoints.up('ss')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      lineHeight: '19.2px',
      marginTop: '70px',
      color: '#014C7E',
    },
    [theme.breakpoints.up(2100)]: {
      fontSize: '18px',
      marginTop: '40px',
    },
  },
  homeSection0AlreadyInsured: {
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginTop: 50,
    },
  },
  homeContent1: {
    marginLeft: 34,
    marginRight: 34,
    width: 679,
    /*[theme.breakpoints.between(midZoomDesktop, maxZoomDesktop)]: {
      // transform: `translateX(calc(${100/zoomDesktop}vw - ${refSize}px ))`,
      transform: `translateX(calc(${100 / zoomDesktop / 2}vw - ${
        refSize / 2
      }px ))`,
    },
    [theme.breakpoints.up(maxZoomDesktop)]: {
      transform: `translateX(calc(${100 / 2}vw - ${refSize / 2}px ))`,
    },*/
  },
  imgVet: {
    transform: 'translateX(0)',
    [theme.breakpoints.between(minZoomDesktop, midZoomDesktop)]: {
      transform: 'translateX(0px)',
    },
    [theme.breakpoints.between(midZoomDesktop, maxZoomDesktop)]: {
      // transform: `translateX(calc(${100/zoomDesktop}vw - ${refSize}px ))`,
      transform: `translateX(calc(${100 / zoomDesktop / 2}vw - ${
        refSize / 2
      }px ))`,
    },
    [theme.breakpoints.up(maxZoomDesktop)]: {
      transform: `translateX(calc(${100 / 2}vw - ${refSize / 2}px ))`,
    },
  },
  imgSection3: {
    width: '350px',
    height: '13px',
    [theme.breakpoints.up(minZoomDesktop)]: {
      width: '1224.012px',
      height: '46.14px',
    },
  },
  imgPhoneText: {
    position: 'absolute',
    textAlign: 'right',
    left: -120,
  },

  contentSection4: {
    position: 'absolute',
    width: '100%',
    [theme.breakpoints.between(midZoomDesktop, maxZoomDesktop)]: {
      transform: `translateX(calc(${50 / zoomDesktop}vw - ${
        refSize / 2
      }px))`,
    },
    [theme.breakpoints.up(maxZoomDesktop)]: {
      transform: `translateX(calc((100vw - ${refSize}px)/2))`,
    },
  },
  homeImg: {
    position: 'absolute',
  },
  contentSection5: {
    position: 'absolute',
    left: '960px',
    transform: 'translateX(calc((- 50vw)/2) ))',
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      transform: 'translateX(0px)',
    },
  },
  contentSection6: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  likesContent: {
    transform: `translateX(calc((100vw - ${refSize}px)/2))`,

    [theme.breakpoints.up(minZoomDesktop)]: {
      transform: 'translateX(0px)',
    },
  },
  doudouAnim: {
    position: 'absolute',
    bottom: '0',
    left: '60px',
    width: '40%',
    maxWidth: '200px',
    [theme.breakpoints.down('sm')]: {
      left: '40px',
      bottom: '15px',
    },
    [theme.breakpoints.down('ss')]: {
      left: '30px',
      bottom: '40px',
    },
  },
  chatChaiseAnim: {
    position: 'absolute',
    bottom: '-30px',
    right: '110px',
    width: '40%',
    maxWidth: '200px',
    [theme.breakpoints.down('sm')]: {
      right: '50px',
    },
    [theme.breakpoints.down('ss')]: {
      right: '40px',
      bottom: '0',
    },
  },
  logoContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflowY: 'hidden',
    width: '100%',
    height: '100px',
    backgroundColor: 'white',
    '& .-webkit-scrollbar': {
      display: 'none',
    },
    '&:-webkit-scrollbar': {
      display: 'none',
    },
    '-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  videoInterview: {
    width: '360px',
    height: '640px',
    borderRadius: '5%',
    [theme.breakpoints.down('md')]: {
      width: '144px',
      height: '256px',
    },
  },
  videoDrooky: {
    width: '1400px',
    height: '790px',
    borderRadius: '5%',
    [theme.breakpoints.down('md')]: {
      width: '500px',
      height: '285px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '400px',
      height: '225px',
    },
    [theme.breakpoints.down('ss')]: {
      width: '100%',
      height: undefined,
    },
  },
}));

export const consultPrice = (t) => (
  <Link component={RouterLink} to="/pricing">
    <Button
      className={'home-content-0-button'}
      sx={{
        height: { xs: '34.8px', md: '67.83px' },
        paddingLeft: { xs: '10px', md: '20px' },
        paddingRight: { xs: '10px', md: '20px' },
      }}
    >
      <Typography
        variant={'h5'}
        color={'secondary'}
        sx={{
          fontSize: { xs: '18px', md: '30px' },
        }}
      >
        {t('header.button')}
      </Typography>
    </Button>
  </Link>
);

function HomePage() {
  const { t } = useTranslation(null, { keyPrefix: 'home' });
  const { width } = useWindowSize();
  const { language } = useContext(LanguageContext);
  const zoom =
    width < minZoomDesktop
      ? 1 + (width - minZoomMobile) / 900
      : width / refSize;

  const classes = useStyles({ zoom });
  const ref1 = useRef();
  const ref2 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const refAlambic = useRef();
  // const refBalance = useRef();

  const isVisible1 = referenceIntersection(ref1);
  const isVisible2 = referenceIntersection(ref2);
  const isVisible4 = referenceIntersection(ref4);
  const isVisible5 = referenceIntersection(ref5);
  const isVisibleAlambic = referenceIntersection(refAlambic);
  // const isVisibleBalance = referenceIntersection(refBalance)

  const location = useLocation();
  const url = new URLSearchParams(location?.search);
  const affiliateId = url?.get('affiliateId');

  useEffect(() => {
    if (affiliateId) {
      localStorage.setItem('affiliate_id', affiliateId);
    }
  }, [affiliateId]);

  const scrollTo = (sectionNumber) => {
    const offsetWidth = document.body.offsetWidth;
    let sectionOffset;
    switch (sectionNumber) {
      case 1:
        if (offsetWidth >= 2100) sectionOffset = 850;
        else if (offsetWidth > 1550) sectionOffset = 820;
        else sectionOffset = 1000 * zoom;
        break;
      case 2:
        // Only for mobile
        sectionOffset = 1670 * zoom;
        break;
      case 3:
        if (offsetWidth >= 2100) sectionOffset = 4280;
        else if (offsetWidth >= 1550) sectionOffset = 3500;
        else if (offsetWidth >= 1000) sectionOffset = 4450 * zoom;
        // Mobile version
        else sectionOffset = 2400 * zoom;
        break;
      case 5:
        if (offsetWidth >= 2100) sectionOffset = 7650;
        else if (offsetWidth >= 1510) sectionOffset = 6130;
        else if (offsetWidth >= 1000) sectionOffset = 7820 * zoom;
        // Mobile version
        else if (offsetWidth >= 768) sectionOffset = 4640 * zoom;
        else sectionOffset = 4800 * zoom;
        break;
      case 6:
        // Only for mobile
        if (offsetWidth >= 768) sectionOffset = 5770 * zoom;
        else sectionOffset = 5910 * zoom;
        break;
      case 8:
        if (offsetWidth >= 2100) sectionOffset = 10960;
        else if (offsetWidth >= 1500) sectionOffset = 8720;
        else sectionOffset = 11140 * zoom;
        break;
      default:
        sectionOffset = null;
    }
    if (!_.isNil(sectionOffset) && !_.isNaN(sectionOffset))
      window.scrollTo({
        top: sectionOffset,
        behavior: 'smooth',
      });
  };
  const mediaLogos = [
    { src: bftm_tv },
    {
      src: france_3,
      xsHeight: '15px',
      ssHeight: '20px',
      mdHeight: '60px',
    },
    { src: france_bleu },
    {
      src: les_echos,
      xsHeight: '17px',
      ssHeight: '22px',
      mdHeight: '60px',
    },
  ];

  return (
    <Box className={classes.zoomHome}>
      <section id={'section0'} className={classes.homeSection}>
        <div ref={ref1} />
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <div>
            <img
              src={home_level_0_transition}
              alt="transition"
              className={'home-img-transition-mobile'}
            />
          </div>
          <img
            style={{
              position: 'absolute',
              width: '100%',
              height: '300px',
              left: '0',
              bottom: '0',
            }}
            src={home_level_0_right}
            alt="cat"
          />

          <Lottie
            animationData={doudou}
            className={classes.doudouAnim}
            play={isVisible1}
            loop={true}
          />
          <Lottie
            animationData={chat_chaise}
            className={classes.chatChaiseAnim}
            play={isVisible1}
            loop={true}
          />
          <Lottie
            animationData={bird_left}
            play={isVisible1}
            loop={true}
            style={{
              position: 'absolute',
              width: '100%',
              top: '120px',
              opacity: '60%',
            }}
          />
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <div className={'home-img-transition-wrapper'}>
            <img
              src={home_level_0_transition}
              alt="transition"
              className={'home-img-transition'}
            />
          </div>
          <img
            style={{
              position: 'absolute',
              top: '320px',
              left: '-130px',
              width: '1920px',
            }}
            src={home_level_0_left}
            alt="dog"
          />

          <Lottie
            animationData={chien_tasse}
            play={isVisible1}
            loop={true}
            style={{
              position: 'absolute',
              bottom: '110px',
              left: '120px',
              width: '600px',
            }}
          />

          <img
            style={{
              position: 'absolute',
              width: '800px',
              top: '320px',
              right: '-100px',
            }}
            src={home_level_0_right}
            alt="cat"
          />

          <Lottie
            animationData={doudou}
            play={isVisible1}
            loop={true}
            style={{
              position: 'absolute',
              bottom: '50px',
              right: '250px',
              width: '400px',
            }}
          />
          <Lottie
            animationData={chat_chaise}
            play={isVisible1}
            loop={true}
            style={{
              position: 'absolute',
              bottom: '50px',
              right: '0',
              width: '400px',
            }}
          />
          <Lottie
            animationData={bird_left}
            play={isVisible1}
            loop={true}
            style={{
              position: 'absolute',
              width: '100%',
              top: '60px',
              opacity: '60%',
            }}
          />
          <Lottie
            animationData={bird_white_big}
            play={isVisible1}
            loop={true}
            style={{
              position: 'absolute',
              width: '100%',
              top: '150px',
              opacity: '60%',
            }}
          />
          <Lottie
            animationData={bird_yellow_small}
            play={isVisible1}
            loop={true}
            style={{
              position: 'absolute',
              width: '100%',
              opacity: '60%',
            }}
          />
        </Box>

        <div className={classes.homeContent}>
          <Typography
            variant="h1"
            sx={{
              mt: { ss: '73px', md: '208px' },
              display: { xs: 'none', ss: 'block' },
            }}
            className={classes.homeSection0Title}
          >
            {t('header.title1')}
            <br />
            {t('header.title2')}{' '}
            <span style={{ display: 'inline-block' }}>
              {t('header.title3')}
            </span>
          </Typography>
          <Typography
            variant="h1"
            style={{
              marginTop: '50px',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
            sx={{ display: { xs: 'block', ss: 'none' } }}
            className={classes.homeSection0Title}
          >
            {t('header.mobile.title1')}
            <span style={{ display: 'inline-block' }}>
              {t('header.mobile.title2')}
            </span>
          </Typography>
          <Typography
            variant="h4"
            sx={{
              mt: { xs: '10px', ss: '18px', md: '40px' },
              mb: { xs: '30px', md: '89px' },
            }}
            className={classes.homeSection0SubTitle}
          >
            {t('header.subtitle1')}
            <FavoriteIcon
              sx={{
                fontSize: { xs: '15px', md: 'inherit' },
                transform: {
                  xs: 'translateY(2px)',
                  md: 'translateY(5px)',
                },
              }}
              style={{ color: '#d32d00' }}
            />
          </Typography>

          <div>{consultPrice(t)}</div>

          <Typography
            variant="h6"
            className={classes.homeSection0SubTitle2}
          >
            {t('header.subtitle2')} <br />
            {t('header.subtitle3')}
          </Typography>

          <Box
            sx={{
              display: { xs: 'none', md: 'block' },
              mt: '110px',
            }}
          >
            <img
              alt="svg"
              src={home_elements_3}
              style={{
                width: '70px',
                height: '39px',
                cursor: 'pointer',
              }}
              className={classes.homeSection0AlreadyInsured}
              onClick={() => scrollTo(1)}
            />
          </Box>
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
              mt: '110px',
            }}
          >
            <img
              alt="svg"
              src={home_elements_3}
              style={{
                width: '30px',
                height: '39px',
                cursor: 'pointer',
              }}
              className={classes.homeSection0AlreadyInsured}
              onClick={() => scrollTo(1)}
            />
          </Box>
        </div>
      </section>
      <section
        id={'homeSectionVideo'}
        className={classes.homeSectionVideo1}
        style={{
          backgroundColor: blue_light,
        }}
      >
        <Typography
          variant={'h3'}
          sx={{
            marginTop: { xs: '40px', md: '30px' },
            display: isAlcampo() ? 'none' : 'block',
          }}
        >
          {t('sectionVideo.talkingAboutTitle')}
        </Typography>
        <Box
          sx={{
            display: isAlcampo() ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingTop: '15px',
            paddingBottom: '15px',
            mt: { xs: '15px', md: '30px' },
            mb: { xs: '0px', md: '90px' },
          }}
        >
          {mediaLogos.map((logo) => (
            <Box
              key={logo.src}
              sx={{
                height: {
                  xs: logo.xsHeight || '30px',
                  ss: logo.ssHeight || '40px',
                  md: logo.mdHeight || '100px',
                },
                marginRight: {
                  xs: '12px',
                  ss: '15px',
                  sm: '25px',
                  md: '80px',
                },
                marginLeft: {
                  xs: '12px',
                  ss: '15px',
                  sm: '25px',
                  md: '80px',
                },
              }}
            >
              <img className={'logo-item'} src={logo.src} alt="" />
            </Box>
          ))}
        </Box>
        <Typography
          variant={'h3'}
          sx={{
            marginTop: { xs: '20px', md: '30px' },
            lineHeight: '60px',
          }}
        >
          {t('sectionVideo.likeUsTitle')}
        </Typography>
        <Typography
          variant={'h5'}
          sx={{
            lineHeight: { xs: undefined, md: '40px' },
            marginBottom: '20px',
          }}
        >
          {t('sectionVideo.likeUsSubtitle1')} <br />
          {t('sectionVideo.likeUsSubtitle2')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '40px',
          }}
        >
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
              marginRight: { xs: '10px', md: '20px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
              marginRight: { xs: '10px', md: '20px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
              marginRight: { xs: '10px', md: '20px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
              marginRight: { xs: '10px', md: '20px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </Box>
        <Box style={{ position: 'relative' }}>
          <iframe
            className={classes.videoDrooky}
            src={
              isAlcampo()
                ? 'https://www.youtube-nocookie.com/embed/I3UBXXxSAWA'
                : 'https://www.youtube-nocookie.com/embed/dq0odpv70-Q'
            }
            title="Drooky song"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
            allowFullScreen
          ></iframe>
        </Box>
      </section>
      <section id={'section2'} className={classes.homeSection2}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <div className={'home-img'}>
            <div className={classes.imgLeft}>
              <img src={home_level_2_left_back} alt="dog" />
            </div>
          </div>

          <div className={'home-img-transition-wrapper'}>
            <img
              src={home_level_2_transition}
              alt="transition"
              className={'home-img-table'}
            />
          </div>

          <div className={'home-img'}>
            <div className={classes.imgLeft}>
              <img src={home_level_2_left_front} alt="dog" />
            </div>
          </div>

          <div className={'home-img'} style={{ right: 0 }}>
            <img src={home_level_2_right_back} alt="cat" />
          </div>

          <div className={'home-img'} style={{ right: 0 }}>
            <img src={home_level_2_right_front} alt="cat" />
          </div>
        </Box>
        <div className={'home-content'}>
          <Typography
            variant={'h3'}
            sx={{
              marginTop: { xs: '40px', md: '30px' },
              mb: { xs: '40px', md: '30px' },
            }}
          >
            {t('sectionRefund.title')}
          </Typography>

          <Grid
            container
            direction="row"
            sx={{ height: '100%', zIndex: 999 }}
            p={2}
            justifyContent="space-around"
          >
            <Grid item xs={12} md={5}>
              <Typography
                variant={'h3'}
                sx={{
                  padding: { xs: '8px', md: '20px' },
                  fontSize: '35px',
                  lineHeight: '45px',
                  mb: 2,
                  backgroundImage: `url(${home_elements_28})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                {t('sectionRefund.corevedCare.title')}
              </Typography>
              <Stack
                direction="row"
                p={3}
                gap={{ xs: 1, md: 2 }}
                flexWrap="wrap"
              >
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.consultations')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.medicine')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.analyzes')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.surgery')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.anesthesias')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.radiotherapy')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.clinicalStays')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.ambulances')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.radiology')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.corevedCare.etc')}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} md={5}>
              <Typography
                variant={'h3'}
                sx={{
                  padding: { xs: '8px', md: '20px' },
                  lineHeight: '45px',
                  fontSize: '35px',
                  mb: 2,
                  backgroundImage: `url(${home_elements_28})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                {t('sectionRefund.accidentsAndIllnesses.title')}
              </Typography>
              <Stack
                direction="row"
                p={3}
                gap={{ xs: 1, md: 2 }}
                flexWrap="wrap"
              >
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.accidentsAndIllnesses.digestive')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t(
                    'sectionRefund.accidentsAndIllnesses.foreignBodies',
                  )}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.accidentsAndIllnesses.trauma')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.accidentsAndIllnesses.otitis')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.accidentsAndIllnesses.cancer')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.accidentsAndIllnesses.arthritis')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.accidentsAndIllnesses.cystitis')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.accidentsAndIllnesses.injuries')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.accidentsAndIllnesses.cyst')}
                </Typography>
                <Typography
                  variant={'h5'}
                  sx={{
                    padding: 1,
                    width: 'fit-content',
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderRadius: '50px',
                  }}
                >
                  {t('sectionRefund.accidentsAndIllnesses.etc')}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <div style={{ marginTop: 450, width: '100%' }}>
              <img
                alt="svg"
                src={home_elements_3}
                style={{
                  width: '70px',
                  height: '39px',
                  cursor: 'pointer',
                }}
                onClick={() => scrollTo(3)}
              />
            </div>
          </Box>
        </div>
      </section>
      <section
        id={'sectionAlambic'}
        className={classes.homeSectionAlambic}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img
            alt="png"
            src={home_alambique_transition}
            style={{ zIndex: -10 }}
            className={'home-img-transition-1-mobile'}
          />
        </Box>
        <Grid container direction="row" sx={{ height: '100%' }} p={2}>
          <Grid item xs={12} md={5}>
            <Typography
              variant={'h3'}
              sx={{
                padding: { xs: '8px', md: '20px' },
                lineHeight: '45px',
                mb: 2,
              }}
            >
              {t('sectionPreventionWellbeing.title1')}
              <br /> {t('sectionPreventionWellbeing.title2')}
            </Typography>
            <Typography
              variant={'h5'}
              sx={{
                lineHeight: { xs: undefined, md: '40px' },
              }}
            >
              {t('sectionPreventionWellbeing.subtitle')}
            </Typography>
            <Stack
              direction="row"
              p={3}
              gap={{ xs: 1, md: 2 }}
              flexWrap="wrap"
            >
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.vaccine')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.sterilization')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.antiparasitic')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.inspectionAnnual')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.osteopathy')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.supplements')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.defensing')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.homeopathy')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.physiotherapy')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.screening')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.descaling')}
              </Typography>
              <Typography
                variant={'h5'}
                sx={{
                  padding: 1,
                  width: 'fit-content',
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderRadius: '50px',
                }}
              >
                {t('sectionPreventionWellbeing.etc')}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            style={{ maxWidth: '1200px', maxHeight: '800px' }}
          >
            <div ref={refAlambic} />
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Lottie
                animationData={alambic}
                play={isVisibleAlambic}
                loop={true}
                speed={2}
                style={{ height: '900px', margin: 'auto' }}
              />
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Lottie
                animationData={alambic}
                play={isVisibleAlambic}
                loop={true}
                style={{ width: '100%', margin: 'auto' }}
              />
            </Box>
          </Grid>
        </Grid>
      </section>
      <section
        id={'homeSectionVideoRefund'}
        className={classes.homeSectionVideo2}
        style={{ display: isAlcampo() ? 'block' : 'none' }}
      >
        <Typography
          variant={'h3'}
          style={{
            marginTop: { xs: 0, md: 30 },
            mb: { xs: 30, md: 60 },
            lineHeight: { xs: '30px', md: '60px' },
          }}
        >
          {t('sectionHowRefund.alcampo')}
        </Typography>
        <Box style={{ position: 'relative', marginTop: 50 }}>
          <iframe
            className={classes.videoDrooky}
            src="https://www.youtube-nocookie.com/embed/mjK-eFNxVCY"
            title="Drooky refund"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
            allowFullScreen
          ></iframe>
        </Box>
      </section>
      <section id={'section6'} className={classes.homeSection12}>
        <div className={'home-content'}>
          <Box
            sx={{
              mt: { xs: '85px', md: '135px' },
            }}
            style={{ width: '100%' }}
          >
            <Typography
              variant={'h3'}
              color={'primary'}
              sx={{
                fontSize: { xs: '15px', md: '37px' },
                lineHeight: { xs: '20px', md: '60px' },
              }}
            >
              {t('sectionPetAbroad.title')}
            </Typography>
            <Typography
              variant={'h5'}
              sx={{
                lineHeight: { xs: undefined, md: '40px' },
              }}
            >
              {t('sectionPetAbroad.subtitle')}
            </Typography>
          </Box>
        </div>
      </section>
      <section id={'section5'} className={classes.homeSection5}>
        <Box className={'home-content'}>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <div style={{ position: 'relative', width: '100%' }}>
              <Typography
                variant={'h3'}
                style={{
                  marginTop: 51.834,
                  lineHeight: '20px',
                }}
              >
                {t('sectionCause.title1')}
                <br />
                {t('sectionCause.title2')}
              </Typography>
              <Typography
                variant={'h3'}
                style={{
                  lineHeight: '18px',
                }}
              >
                <br />
              </Typography>

              <Typography
                variant={'h5'}
                style={{
                  lineHeight: '14px',
                }}
              >
                {t('sectionCause.subtitle1')}
                <br />
                {t('sectionCause.subtitle2')}
              </Typography>
            </div>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography
              variant={'h5'}
              style={{
                fontSize: '37px',
                lineHeight: '60px',
                marginTop: 80,
              }}
            >
              {t('sectionCause.title1')}
              {t('sectionCause.title2')}
            </Typography>
            <Typography variant={'h5'} style={{ lineHeight: '40px' }}>
              {t('sectionCause.subtitle1')}
              {t('sectionCause.subtitle2')}
            </Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: '72px', md: ' 220px' }}
            sx={{
              mt: { xs: '67px', md: '87px' },
            }}
          >
            <Box
              sx={{
                width: { xs: '105px', md: '450px' },
              }}
            >
              <Box
                sx={{
                  width: { xs: 77.4823, md: 223.4555 },
                  height: { xs: 132.4937, md: 382.1059 },
                  margin: { xs: 'auto' },
                }}
              >
                <img alt="svg" src={home_elements_18} />
              </Box>
              <Box
                sx={{
                  position: { xs: 'relative' },
                  mt: { xs: '26px', md: '30px' },
                }}
              >
                <Box
                  sx={{
                    position: { xs: 'absolute' },
                    width: { xs: '104.2451px', md: '268px' },
                    height: { xs: '25.6723px', md: '66px' },
                    left: { xs: '0px', md: '90px' },
                  }}
                >
                  <img
                    alt="svg"
                    src={home_elements_24}
                    style={{ width: '100%' }}
                  />
                </Box>
                <Box style={{ position: 'absolute', width: '100%' }}>
                  <Typography
                    variant={'h4'}
                    sx={{
                      mt: { xs: '5px', md: '20.847px' },
                      fontSize: { xs: '13px', md: '33px' },
                      lineHeight: { xs: '14.4px', md: '24px' },
                    }}
                  >
                    {t('sectionCause.accidents.title')}
                  </Typography>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Typography
                      variant={'h6'}
                      style={{ lineHeight: '24px' }}
                    >
                      <br />
                      {isAlcampo()
                        ? 'Alcampo Mascotas '
                        : 'Drooky '}{' '}
                      {t('sectionCause.accidents.subtitle1')} <br />
                      {t('sectionCause.accidents.subtitle2')}
                    </Typography>
                    <Typography
                      variant={'subtitle2'}
                      style={{ lineHeight: '24px' }}
                    >
                      <br />
                      {t('sectionCause.accidents.waitingPeriod')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: { xs: '105px', md: '450px' },
              }}
            >
              <Box
                sx={{
                  width: { xs: 93.098, md: 269.2222 },
                  height: { xs: 132.1337, md: 382.1059 },
                  margin: { xs: 'auto' },
                }}
              >
                <img alt="svg" src={home_elements_19} />
              </Box>
              <Box
                sx={{
                  position: { xs: 'relative' },
                  mt: { xs: '26px', md: '30px' },
                }}
              >
                <Box
                  sx={{
                    position: { xs: 'absolute' },
                    width: { xs: '104.2451px', md: '269px' },
                    height: { xs: '25.6723px', md: '66px' },
                    left: { xs: '0px', md: '90px' },
                  }}
                >
                  <img
                    alt="svg"
                    src={home_elements_25}
                    style={{ width: '100%' }}
                  />
                </Box>
                <Box style={{ position: 'absolute', width: '100%' }}>
                  <Typography
                    variant={'h3'}
                    sx={{
                      mt: { xs: '5px', md: '20.847px' },
                      fontSize: { xs: '13px', md: '33px' },
                      lineHeight: { xs: '14.4px', md: '24px' },
                    }}
                  >
                    {t('sectionCause.illnesses.title')}
                  </Typography>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Typography
                      variant={'h6'}
                      style={{ lineHeight: '24px' }}
                    >
                      <br />
                      {t('sectionCause.illnesses.subtitle1')}
                      <br />
                      {t('sectionCause.illnesses.subtitle2')}
                    </Typography>
                    <Typography
                      variant={'subtitle2'}
                      style={{ lineHeight: '24px' }}
                    >
                      <br />
                      {t('sectionCause.illnesses.waitingPeriod')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Stack>

          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <div style={{ position: 'relative', width: '100%' }}>
              <Typography
                variant={'h3'}
                style={{
                  marginTop: '88px',
                  lineHeight: '20px',
                }}
              >
                {t('sectionCostRefund.title1')}
                <br />
                {t('sectionCostRefund.title2')}
              </Typography>
              <Typography
                variant={'h3'}
                style={{
                  lineHeight: '18px',
                }}
              >
                <br />
              </Typography>

              <Typography
                variant={'h5'}
                style={{
                  lineHeight: '14px',
                }}
              >
                {t('sectionCostRefund.subtitle')}
              </Typography>

              <Box
                style={{
                  width: '100%',
                  marginTop: '40px',
                  zIndex: 100,
                }}
              >
                <img
                  src={home_elements_10_mobile}
                  alt="transition"
                  style={{ width: '30px', cursor: 'pointer' }}
                  onClick={() => scrollTo(6)}
                />
              </Box>
            </div>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography
              variant={'h5'}
              style={{
                marginTop: '270px',
                fontSize: '37px',
                lineHeight: '60px',
              }}
            >
              {t('sectionCostRefund.title1')}

              {t('sectionCostRefund.title2')}
            </Typography>
            <Typography variant={'h5'} style={{ lineHeight: '40px' }}>
              {t('sectionCostRefund.subtitle')}
            </Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={{ xs: '47px', md: ' 220px' }}
            style={{ marginTop: '35px' }}
          >
            <Box
              sx={{
                width: { xs: '140px', md: '450px' },
              }}
            >
              <Box
                sx={{
                  width: { xs: 103.3514, md: 302.8542 },
                  height: { xs: 112.9564, md: 331 },
                  margin: { xs: 'auto' },
                }}
              >
                <img alt="svg" src={home_elements_21} />
              </Box>
              <Box
                sx={{
                  position: { xs: 'relative' },
                  mt: { xs: '18px', md: '10px' },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ position: 'absolute', width: '100%' }}
                >
                  <Typography
                    variant={'h4'}
                    sx={{
                      mt: { xs: '4px', md: '20.847px' },
                      fontSize: { xs: '13px', md: '33px' },
                      lineHeight: { xs: '14.4px', md: '24px' },
                      padding: { xs: '8px', md: '20px' },
                    }}
                    style={{
                      backgroundImage: `url(${home_elements_26})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  >
                    {t('sectionCostRefund.consultations.title')}
                  </Typography>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Typography
                      variant={'h6'}
                      style={{ lineHeight: '24px' }}
                    >
                      <br />
                      {t('sectionCostRefund.consultations.subtitle1')}
                      <br />
                      {t('sectionCostRefund.consultations.subtitle2')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: { xs: '140px', md: '450px' },
              }}
            >
              <Box
                sx={{
                  width: { xs: 139.4646, md: 476.0285 },
                  height: { xs: 85.6087, md: 292.2044 },
                  margin: { xs: 'auto' },
                  mt: { xs: '20px', md: '20px' },
                }}
              >
                <img alt="svg" src={home_elements_20} />
              </Box>
              <Box
                sx={{
                  position: { xs: 'relative' },
                  mt: { xs: '25px', md: '30px' },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ position: 'absolute', width: '100%' }}
                >
                  <Typography
                    variant={'h4'}
                    sx={{
                      mt: { xs: '4px', md: '20.847px' },
                      fontSize: { xs: '13px', md: '33px' },
                      lineHeight: { xs: '14.4px', md: '24px' },
                      padding: { xs: '8px', md: '20px' },
                    }}
                    style={{
                      backgroundImage: `url(${home_elements_27})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  >
                    {t('sectionCostRefund.hospitalization.title')}
                  </Typography>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Typography
                      variant={'h6'}
                      style={{ lineHeight: '24px' }}
                    >
                      <br />
                      {t(
                        'sectionCostRefund.hospitalization.subtitle1',
                      )}{' '}
                      <br />
                      {t(
                        'sectionCostRefund.hospitalization.subtitle2',
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={{ xs: '73px', md: '145px' }}
            sx={{
              mt: { xs: '89px', md: '240px' },
            }}
          >
            <Box
              sx={{
                width: { xs: '108px', md: '551px' },
              }}
            >
              <Box
                sx={{
                  width: { xs: 104.7064, md: 350.5719 },
                  height: { xs: 84.2258, md: 282 },
                  margin: { xs: 'auto' },
                  mt: { xs: '14px', md: 'auto' },
                }}
              >
                <img alt="svg" src={home_elements_22} />
              </Box>
              <Box
                sx={{
                  position: { xs: 'relative' },
                  mt: { xs: '22px', md: '30px' },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ position: 'absolute', width: '100%' }}
                >
                  <Typography
                    variant={'h4'}
                    sx={{
                      mt: { xs: '4px', md: '20.847px' },
                      fontSize: { xs: '13px', md: '33px' },
                      lineHeight: { xs: '14.4px', md: '24px' },
                      padding: { xs: '8px', md: '20px' },
                    }}
                    style={{
                      backgroundImage: `url(${home_elements_28})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  >
                    {t('sectionCostRefund.exams.title')}
                  </Typography>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Typography
                      variant={'h6'}
                      style={{ lineHeight: '24px' }}
                    >
                      <br />
                      {t('sectionCostRefund.exams.subtitle1')} <br />
                      {t('sectionCostRefund.exams.subtitle2')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: { xs: '108px', md: '551px' },
              }}
            >
              <Box
                sx={{
                  width: { xs: 94.6987, md: 271.905 },
                  height: { xs: 104.3217, md: 299.5353 },
                  margin: { xs: 'auto' },
                }}
              >
                <img alt="svg" src={home_elements_23} />
              </Box>
              <Box
                sx={{
                  position: { xs: 'relative' },
                  mt: { xs: '16px', md: '15px' },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ position: 'absolute', width: '100%' }}
                >
                  <Typography
                    variant={'h4'}
                    sx={{
                      mt: { xs: '4px', md: '20.847px' },
                      fontSize: { xs: '13px', md: '33px' },
                      lineHeight: { xs: '14.4px', md: '24px' },
                      padding: { xs: '8px', md: '20px' },
                    }}
                    style={{
                      backgroundImage: `url(${home_elements_29})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  >
                    {t('sectionCostRefund.medicine.title')}
                  </Typography>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Typography
                      variant={'h6'}
                      style={{ lineHeight: '24px' }}
                    >
                      <br />
                      {t('sectionCostRefund.medicine.subtitle1')}{' '}
                      <br />
                      {t('sectionCostRefund.medicine.subtitle2')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Stack>

          <Box
            sx={{ display: { xs: 'block', md: 'none' }, mt: '100px' }}
          >
            {consultPrice}
          </Box>
        </Box>
      </section>

      <section
        id={'homeSectionVideoRefund'}
        className={classes.homeSectionVideo2}
        style={{ display: isAlcampo() ? 'none' : 'block' }}
      >
        <Typography
          variant={'h3'}
          style={{ marginTop: 30, lineHeight: '60px' }}
        >
          {t('sectionHowRefund.title')}
        </Typography>
        <Typography
          variant={'h5'}
          sx={{
            lineHeight: { xs: undefined, md: '40px' },
            marginBottom: '20px',
          }}
        >
          {t('sectionHowRefund.subtitle')}
        </Typography>
        <Box style={{ position: 'relative' }}>
          <iframe
            className={classes.videoDrooky}
            src="https://www.youtube-nocookie.com/embed/rw5iVaC4_XQ"
            title="Drooky refund"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
            allowFullScreen
          ></iframe>
        </Box>
      </section>
      <section id={'section1'} className={classes.homeSection1}>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <img
            style={{ zIndex: -1 }}
            alt="svg"
            src={home_level_1_transition_mobile}
            className={'home-img-transition-1-mobile'}
          />
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <div
            style={{ zIndex: -1 }}
            className={'home-img-transition-wrapper'}
          >
            <img
              alt="svg"
              src={home_level_1_transition}
              className={'home-img-transition'}
            />
          </div>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          sx={{ marginTop: { xs: '80px', md: '20px' } }}
        >
          <Grid
            item
            xs={12}
            md={5}
            display="flex"
            direction="column"
            alignItems="center"
          >
            <Box
              style={{ width: '100%' }}
              sx={{
                display: { xs: 'block', md: 'none' },
                mb: '16px',
              }}
            >
              <img
                alt="svg"
                src={home_elements_3_mobile}
                style={{
                  width: '30px',
                  cursor: 'pointer',
                }}
                onClick={() => scrollTo(2)}
              />
            </Box>
            <Typography
              color={'primary'}
              style={{
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
              sx={{
                fontSize: { xs: '15px', md: '42px' },
                lineHeight: { xs: '20px', md: '50px' },
                width: { xs: '260px', md: '680px' },
                height: { xs: '60px', md: '175px' },
                padding: { xs: '8px 0', md: '12px 0' },
                backgroundImage: {
                  xs: `url(${home_elements_2_mobile})`,
                  md: `url(${home_elements_2})`,
                },
              }}
            >
              {isAlcampo() ? 'Alcampo Mascotas ' : 'Drooky '}
              {t('sectionPayTheVet.title')}
            </Typography>

            <Typography
              color={'primary'}
              variant={'h5'}
              sx={{
                marginTop: { xs: '30px', md: '100px' },
                lineHeight: { xs: '14px', md: 'unset' },
              }}
            >
              {t('sectionPayTheVet.subtitle1')} <br />
              {t('sectionPayTheVet.subtitle2')}{' '}
              {!isAlcampo() && 'Drooky !'}
            </Typography>
            <Typography
              color={'primary'}
              variant={'h5'}
              sx={{
                marginTop: { xs: '20px', md: '100px' },
                lineHeight: { xs: '14px', md: 'unset' },
              }}
            >
              {t('sectionPayTheVet.text1')} <br />
              {t('sectionPayTheVet.text2')}
              <br />
              {t('sectionPayTheVet.text3')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
          >
            <div ref={ref2} />
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Lottie
                animationData={
                  isAlcampo() ? motionTwoEurosAlcampo : motion_2euros
                }
                play={isVisible2}
                loop={true}
                style={{ width: '100%', maxWidth: '1200px' }}
              />
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Lottie
                animationData={
                  isAlcampo() ? motionTwoEurosAlcampo : motion_2euros
                }
                play={isVisible2}
                loop={true}
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  marginTop: '-20px',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </section>

      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <section className={'home-section-4-1'}>
          <div className={'home-content'}>
            <Typography variant={'h3'} style={{ lineHeight: '60px' }}>
              {t('sectionWorldRecord.title1')}
              <br />
              {t('sectionWorldRecord.title2')}
            </Typography>
          </div>
        </section>
      </Box>
      <section id={'section4'} className={classes.homeSection4}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <div className={'home-img-transition-wrapper'}>
            <img
              src={home_level_4_transition}
              alt="transition"
              className={'home-img-transition-4'}
            />
          </div>

          <div className={'home-img-transition-wrapper'}>
            <img
              src={home_level_4_1_transition}
              alt="transition"
              className={'home-img-transition-4'}
            />
          </div>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <div>
            <img
              src={home_level_4_transition_mobile}
              alt="transition"
              className={'home-img-transition-4-mobile'}
            />
          </div>
        </Box>

        <div className={classes.contentSection4}>
          <Box
            className={clsx(classes.homeImg, classes.imgMobile)}
            sx={{
              top: { xs: 190, md: 123.609 },
              left: { xs: 44.7215, md: 288.0132 },
              width: { xs: '136.478px', md: '309.989px' },
            }}
          >
            <img src={home_elements_8} alt="transition" />
          </Box>

          <Box
            className={clsx(classes.homeImg, classes.imgMobile)}
            sx={{
              top: { xs: 497.958, md: 469.145 },
              left: { xs: 177.3884, md: 1327.533 },
              width: { xs: '136.478px', md: '309.989px' },
            }}
          >
            <img src={home_elements_9} alt="transition" />
          </Box>

          <Box
            className={clsx(classes.homeImg, classes.imgMobile)}
            sx={{
              top: { xs: 814.11, md: 976 },
              left: { xs: 44.3636, md: 287.2001 },
              width: { xs: '136.6662px', md: '310.4164px' },
            }}
          >
            <img src={home_elements_10} alt="transition" />
          </Box>

          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Typography
              variant={'h3'}
              style={{
                marginTop: 50,
                lineHeight: '20px',
              }}
            >
              {isAlcampo()
                ? t('sectionWorldRecord.mobile.alcampo.title1')
                : t('sectionWorldRecord.mobile.title1')}
              <br />
              {t('sectionWorldRecord.mobile.title2')}
            </Typography>
            <Typography
              variant={'h3'}
              style={{
                lineHeight: '18px',
              }}
            >
              <br />
            </Typography>

            <Typography
              variant={'h5'}
              style={{
                lineHeight: '14px',
              }}
            >
              {t('sectionWorldRecord.mobile.subtitle1')} <br />
              {t('sectionWorldRecord.mobile.subtitle2')} <br />
              {t('sectionWorldRecord.mobile.subtitle3')}
            </Typography>
            <Box
              sx={{ display: { xs: 'block', md: 'none' } }}
              style={{
                position: 'absolute',
                width: '100%',
                top: 15,
                zIndex: 100,
              }}
            >
              <img
                src={home_elements_10_mobile}
                alt="transition"
                style={{ width: '30px', cursor: 'pointer' }}
                onClick={() => scrollTo(5)}
              />
            </Box>
            <Box
              className={clsx(classes.homeImg, classes.imgMobile)}
              style={{
                left: 209,
                top: 263,
              }}
            >
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', width: 112 }}>
                  <img
                    src={home_elements_7_mobile}
                    alt="transition"
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: 9,
                    left: 13,
                    width: 107,
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    variant={'h4'}
                    color={'white'}
                    sx={{
                      lineHeight: { xs: '16px' },
                    }}
                  >
                    {t('sectionWorldRecord.step1.mobile.title1')}
                    <br />
                    {t('sectionWorldRecord.step1.mobile.title2')}
                    <br />
                    {t('sectionWorldRecord.step1.mobile.title3')}
                    <br />
                    {t('sectionWorldRecord.step1.mobile.title4')}
                  </Typography>
                </div>
              </div>
            </Box>

            <Box
              className={clsx(classes.homeImg, classes.imgMobile)}
              style={{
                left: 25.969,
                top: 597,
              }}
            >
              <div style={{ position: 'relative' }}>
                {/* <div style={{ position: "absolute", width: 133 }}>
                  <img src={home_elements_8_mobile} alt="transition" />
                </div>*/}
                <div
                  style={{
                    backgroundImage: `url(${home_elements_8_mobile})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    position: 'absolute',
                    padding: '10px',
                    top: 9,
                    left: -9,
                    width: 150,
                    textAlign: 'right',
                  }}
                >
                  <Typography
                    variant={'h4'}
                    sx={{
                      lineHeight: { xs: '16px' },
                    }}
                  >
                    {t('sectionWorldRecord.step2.mobile.title1')}{' '}
                    <br />
                    {t('sectionWorldRecord.step2.mobile.title2')}
                  </Typography>
                </div>
              </div>
            </Box>

            <Box
              className={clsx(classes.homeImg, classes.imgMobile)}
              style={{
                left: 208,
                top: 918,
              }}
            >
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', width: 113 }}>
                  <img
                    src={home_elements_9_mobile}
                    alt="transition"
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: 9,
                    left: 13,
                    width: 107,
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    variant={'h4'}
                    sx={{
                      lineHeight: { xs: '16px' },
                    }}
                  >
                    {t('sectionWorldRecord.step3.mobile.title1')}{' '}
                    <br />
                    {t('sectionWorldRecord.step3.mobile.title2')}{' '}
                    <br />
                    {t('sectionWorldRecord.step3.mobile.title3')}{' '}
                    <br />
                    {t('sectionWorldRecord.step3.mobile.title4')}
                  </Typography>
                </div>
              </div>
            </Box>

            <Box
              className={clsx(classes.homeImg, classes.imgMobile)}
              style={{
                left: 58,
                top: 1145.707,
              }}
            >
              {consultPrice}
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <div
              className={classes.contentSection5}
              style={{ top: 135 }}
            >
              <div
                style={{
                  width: '70px',
                  transform: 'translateX(-35px)',
                }}
              >
                <img
                  alt="svg"
                  src={home_elements_3}
                  style={{
                    width: '70px',
                    height: '39px',
                    cursor: 'pointer',
                  }}
                  onClick={() => scrollTo(5)}
                />
              </div>
            </div>

            <div
              className={'home-content'}
              style={{
                top: 240,
                left: 762.9647,
                textAlign: 'left',
                width: '900px',
              }}
            >
              <div style={{ position: 'relative' }}>
                <div style={{ width: '100%', position: 'absolute' }}>
                  <img
                    alt="svg"
                    src={home_elements_11}
                    style={{ width: '775px', height: '81px' }}
                  />
                </div>
                <div style={{ width: '100%', position: 'absolute' }}>
                  <Typography
                    color={'white'}
                    style={{
                      fontSize: '37px',
                      lineHeight: '42px',
                      padding: '20px',
                    }}
                  >
                    {t('sectionWorldRecord.step1.title')}
                  </Typography>

                  <Typography
                    variant={'h5'}
                    color={'primary'}
                    style={{ lineHeight: '30px', padding: '20px' }}
                  >
                    {t('sectionWorldRecord.step1.subtitle1')}
                    <br />
                    {t('sectionWorldRecord.step1.subtitle2')}
                  </Typography>
                </div>
              </div>
            </div>

            <div
              className={'home-img'}
              style={{ top: 715, left: 774.9647, width: 900 }}
            >
              <div style={{ position: 'relative' }}>
                <div className={classes.imgPhoneText}>
                  <Typography
                    color={'primary'}
                    style={{
                      fontSize: '37px',
                      lineHeight: '42px',
                      padding: '20px',
                      backgroundImage: `url(${home_elements_12})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right',
                    }}
                  >
                    {t('sectionWorldRecord.step2.title1')} <br />
                    {t('sectionWorldRecord.step2.title2')}
                  </Typography>

                  <Typography
                    variant={'h5'}
                    color={'white'}
                    style={{ lineHeight: '30px', padding: '20px' }}
                  >
                    {t('sectionWorldRecord.step2.subtitle1')} <br />
                    {t('sectionWorldRecord.step2.subtitle2')} <br />
                    {t('sectionWorldRecord.step2.subtitle3')} <br />
                    {t('sectionWorldRecord.step2.subtitle4')}{' '}
                    {isAlcampo() ? 'Alcampo Mascotas !' : 'Drooky !'}
                  </Typography>
                </div>
              </div>
            </div>

            <div
              className={'home-content'}
              style={{
                top: 1227.714,
                left: 764.9647,
                textAlign: 'left',
                width: 900,
              }}
            >
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute' }}>
                  <img
                    alt="svg"
                    src={home_elements_13}
                    style={{ width: '757px', height: '87px' }}
                  />
                </div>

                <div style={{ width: '100%', position: 'absolute' }}>
                  <Typography
                    color={'primary'}
                    style={{
                      fontSize: '37px',
                      lineHeight: '30px',
                      padding: '20px',
                      paddingTop: '32px',
                    }}
                  >
                    {t('sectionWorldRecord.step3.title')}
                  </Typography>

                  <Typography
                    variant={'h5'}
                    color={'primary'}
                    style={{
                      marginTop: '5px',
                      lineHeight: '30px',
                      padding: '20px',
                    }}
                  >
                    {t('sectionWorldRecord.step3.subtitle1')}
                    <br />
                    {t('sectionWorldRecord.step3.subtitle2')} <br />
                    {t('sectionWorldRecord.step3.subtitle3')} <br />
                    <br />
                    {t('sectionWorldRecord.step3.subtitle4')}
                    <br />
                    {t('sectionWorldRecord.step3.subtitle5')} <br />
                    {t('sectionWorldRecord.step3.subtitle6')}
                  </Typography>

                  <div
                    style={{ marginTop: '45px', marginLeft: '15px' }}
                  >
                    {consultPrice}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </section>
      <section id={'section6'} className={classes.homeSection6}>
        <div ref={ref4} />
        <Box
          sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
        >
          <div>
            <Lottie
              animationData={
                language === 'es' ? main_soins_alcampo : main_soins
              }
              play={isVisible4}
              loop={true}
              style={{
                width: '550px',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />

            <Lottie
              animationData={chien_pompe}
              play={isVisible4}
              loop={true}
              style={{
                position: 'absolute',
                width: '500px',
                bottom: '110px',
                left: 0,
              }}
            />
            <Lottie
              animationData={chat_ligote}
              play={isVisible4}
              loop={true}
              style={{
                position: 'absolute',
                width: '340px',
                bottom: '140px',
                left: '450px',
              }}
            />

            <Lottie
              animationData={bras_d}
              play={isVisible4}
              loop={true}
              style={{
                width: '550px',
                position: 'absolute',
                top: '130px',
                right: 0,
              }}
            />
            <Lottie
              animationData={chat_froid}
              play={isVisible4}
              loop={true}
              style={{
                width: '340px',
                position: 'absolute',
                bottom: '100px',
                right: '420px',
              }}
            />
            <img
              style={{
                maxWidth: '200px',
                maxHeight: '300px',
                position: 'absolute',
                bottom: '110px',
                right: '40px',
              }}
              src={chien_boxe}
              alt="cat"
            />
          </div>
          <div className={classes.contentSection6}>
            <Box sx={{ mt: '400px' }}>{consultPrice}</Box>
            <div
              style={{
                width: '70px',
                marginTop: '300px',
                zIndex: 100,
              }}
            >
              <img
                alt="svg"
                src={home_elements_3}
                style={{
                  width: '70px',
                  height: '39px',
                  cursor: 'pointer',
                }}
                onClick={() => scrollTo(8)}
              />
            </div>
          </div>
        </Box>

        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Lottie
            animationData={chien_pompe}
            play={isVisible4}
            loop={true}
            style={{
              maxWidth: '200px',
              maxHeight: '200px',
              position: 'absolute',
              bottom: 0,
              left: 0,
            }}
          />
          <Lottie
            animationData={chat_froid}
            play={isVisible4}
            loop={true}
            style={{
              position: 'absolute',
              maxWidth: '140px',
              maxHeight: '200px',
              bottom: 0,
              right: 0,
            }}
          />
        </Box>

        <div className={'home-content'}>
          <Box
            sx={{
              mt: { xs: '45px', md: '135px' },
            }}
            style={{ width: '100%' }}
          >
            <Typography
              variant={'h3'}
              color={'primary'}
              sx={{
                fontSize: { xs: '15px', md: '37px' },
                lineHeight: { xs: '20px', md: '60px' },
              }}
            >
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {t('sectionAllDifferent.title')}
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {t('sectionAllDifferent.mobile.title1')} <br />
                {t('sectionAllDifferent.mobile.title2')}
              </Box>
            </Typography>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Typography
                variant={'h3'}
                color={'primary'}
                sx={{
                  lineHeight: { xs: '18px', md: '40px' },
                }}
              >
                <br />
              </Typography>
            </Box>

            <Typography
              variant={'h5'}
              color={'primary'}
              sx={{
                lineHeight: { xs: '18px', md: '40px' },
              }}
            >
              {t('sectionAllDifferent.subtitle')}
            </Typography>
          </Box>
        </div>
      </section>
      <section id={'section7'} className={classes.homeSection7}>
        <div ref={ref5} />
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <div className={'home-img-transition-wrapper'}>
            <img
              src={home_level_7_transition}
              alt="transition"
              className={'home-img-transition'}
            />
          </div>

          <div className={'home-img'}>
            <div className={classes.imgLeft}>
              <img
                src={
                  language === 'es'
                    ? home_level_7_left_alcampo
                    : home_level_7_left
                }
                alt="dog"
              />
            </div>
          </div>

          <div className={'home-img'}>
            <div className={classes.imgRight}>
              <img src={home_level_7_right} alt="cat" />
            </div>
          </div>

          <Lottie
            animationData={palmier_g}
            style={{ position: 'absolute', left: 0, width: '380px' }}
            play={isVisible5}
            loop={true}
          />
          <Lottie
            animationData={palmier_d}
            style={{ position: 'absolute', right: 0, width: '420px' }}
            play={isVisible5}
            loop={true}
          />
          <Lottie
            animationData={enveloppe}
            style={{
              position: 'absolute',
              bottom: 140,
              right: 400,
              width: '350px',
            }}
            play={isVisible5}
            loop={true}
          />
          <Lottie
            animationData={chat_plage}
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '400px',
            }}
            play={isVisible5}
            loop={true}
          />
          <Lottie
            animationData={requin}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 50,
              width: '450px',
            }}
            play={isVisible5}
            loop={true}
          />
        </Box>

        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box display={isAlcampo && 'none'}>
            <img
              alt="svg"
              src={home_level_7_transition_mobile}
              className={'home-img-transition-1-mobile'}
            />
          </Box>

          <div
            className={clsx('home-img-mobile-7', classes.imgMobile)}
          >
            <img
              src={
                language === 'es'
                  ? home_level_7_mobile_alcampo
                  : home_level_7_mobile
              }
              alt="cat"
            />
          </div>
          <Lottie
            animationData={palmier_g}
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '200px',
            }}
            play={isVisible5}
            loop={true}
          />
          <Lottie
            animationData={requin}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 100,
              width: '250px',
            }}
            play={isVisible5}
            loop={true}
          />
          <Lottie
            animationData={enveloppe}
            style={{
              position: 'absolute',
              bottom: 40,
              right: 80,
              width: '180px',
            }}
            play={isVisible5}
            loop={true}
          />
        </Box>

        <div className={'home-content'}>
          <Box
            sx={{
              mt: { xs: '82px', md: '313.982px' },
            }}
            style={{ width: '100%' }}
          >
            <Typography
              variant={'h3'}
              color={'primary'}
              sx={{
                fontSize: { xs: '15px', md: '37px' },
                lineHeight: { xs: '20px', md: '60px' },
              }}
            >
              <Box
                sx={{
                  display: { xs: 'none', md: 'block' },
                  whiteSpace: 'pre-line',
                }}
              >
                {t('sectionAlreadyInsured.title')}
              </Box>
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    md: 'none',
                  },
                  whiteSpace: 'pre-line',
                }}
              >
                {t('sectionAlreadyInsured.mobile.title1')} <br />
                {t('sectionAlreadyInsured.mobile.title2')}
              </Box>
            </Typography>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Typography
                variant={'h3'}
                color={'primary'}
                sx={{
                  lineHeight: { xs: '18px' },
                }}
              >
                <br />
              </Typography>
            </Box>

            <Typography
              variant={'h5'}
              color={'primary'}
              sx={{
                lineHeight: { xs: '19px', md: '40px' },
              }}
            >
              {t('sectionAlreadyInsured.subtitle')}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: { xs: '47px', md: '177px' },
            }}
          >
            {consultPrice}
          </Box>
        </div>
      </section>
      <section
        id={'section8'}
        className={classes.homeSection8}
        style={{ display: isAlcampo() ? 'none' : 'block' }}
      >
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <div>
            <img
              alt="svg"
              src={home_level_8_transition_mobile}
              className={'home-img-transition-1-mobile'}
            />
          </div>

          <div
            className={clsx('home-img-mobile-8', classes.imgMobile)}
          >
            <img src={home_level_7_mobile} alt="cat" />
          </div>
        </Box>
        <Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <div className={'home-img-transition-wrapper'}>
              <img
                src={home_level_8_transition}
                alt="transition"
                className={'home-img-transition-2'}
              />
            </div>
          </Box>
          <div className={'home-content'}>
            <Box
              sx={{ marginTop: { xs: '100px', md: '300px' } }}
              style={{ width: '100%' }}
            >
              <Typography
                variant={'h3'}
                color={'primary'}
                style={{ lineHeight: '60px' }}
              >
                {t('sectionTestimonial.title')}
              </Typography>
            </Box>
            <Stack
              direction="row"
              alignItems="flex-start"
              style={{ marginTop: '60px' }}
              sx={{
                marginTop: { xs: '40px', md: '80px' },
                spacing: { xs: 0, md: '30px' },
                justifyContent: { xs: 'space-evenly', md: 'center' },
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                sx={{ maxWidth: { xs: '150px', md: '500px' } }}
              >
                <Box
                  style={{ display: 'table', position: 'relative' }}
                  sx={{ width: { xs: '144px', md: '360px' } }}
                >
                  <iframe
                    className={classes.videoInterview}
                    src="https://www.youtube.com/embed/aJVUMprWh1E"
                    title="Jonathan"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                    allowFullScreen
                  ></iframe>
                </Box>
                <Typography
                  style={{ marginTop: '10px' }}
                  variant={'h4'}
                  color={'primary'}
                >
                  {t('sectionTestimonial.jonathanComment.name')}
                </Typography>
                <Typography variant={'h5'} color={'primary'}>
                  "{t('sectionTestimonial.jonathanComment.text1')}
                  <span style={{ display: 'inline-block' }}>
                    {t('sectionTestimonial.jonathanComment.text2')}"
                  </span>
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                sx={{
                  marginLeft: { xs: 0, md: '240px' },
                  maxWidth: { xs: '150px', md: '500px' },
                }}
              >
                <Box
                  style={{ display: 'table', position: 'relative' }}
                  sx={{ width: { xs: '144px', md: '360px' } }}
                >
                  <iframe
                    className={classes.videoInterview}
                    src="https://www.youtube.com/embed/LYhj4Ow3Uj8"
                    title="Nicole"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                    allowFullScreen
                  ></iframe>
                </Box>
                <Typography
                  style={{ marginTop: '10px' }}
                  variant={'h4'}
                  color={'primary'}
                >
                  {t('sectionTestimonial.nicoleComment.name')}
                </Typography>
                <Typography variant={'h5'} color={'primary'}>
                  "{t('sectionTestimonial.nicoleComment.text1')}
                  <span style={{ display: 'inline-block' }}>
                    {t('sectionTestimonial.nicoleComment.text2')}"
                  </span>
                </Typography>
              </Box>
            </Stack>
          </div>
        </Box>
      </section>
      <Footer />
    </Box>
  );
}

export default HomePage;
