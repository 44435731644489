import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { isPhoneNumberValid } from '@operatech1/validators';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import pricing_asset_5 from '../../../../static/pricing/pricing-asset-5.svg';
import pricing_asset_mobile_1 from '../../../../static/pricing/pricing-asset-mobile-1.svg';
import pricing_asset_mobile_4 from '../../../../static/pricing/pricing-asset-mobile-4.svg';
import pricing_asset_mobile_emoticon from '../../../../static/pricing/pricing-asset-mobile-emoticon.svg';
import pricing_asset_phone from '../../../../static/pricing/pricing-asset-phone.svg';
import theme from '../../../../theme';
import ButtonCustom from '../../../atoms/Button';
import { selectGuard } from '../pricingSlice';

const schema = yup.object().shape({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  phone: yup
    .string()
    .test(
      'isPhoneNumberValid',
      '',
      (val) =>
        isPhoneNumberValid(val, 'FR') ||
        isPhoneNumberValid(val, 'ES'),
    ),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 20,
    paddingTop: (props) => (props.isAccount ? 0 : 60),
    [theme.breakpoints.down('sm')]: {
      paddingTop: (props) => (props.isAccount ? 0 : 10),
    },
  },
  title_text: {
    padding: 20,
    fontSize: '2.1rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_4})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  input_container: {
    marginTop: 15,
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${pricing_asset_mobile_1})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  text_field_label: {
    color: theme.palette.primary.main,
  },
  text_field: {
    color: theme.palette.primary.main,
    '&:before': {
      borderColor: theme.palette.primary.main,
    },
    '&:after': {
      borderColor: theme.palette.primary.main,
    },
  },
  text_field_label_error: {
    color: theme.palette.error.main + ' !important',
  },
  text_field_error: {
    color: theme.palette.error.main + ' !important',
    '&:before': {
      borderColor: theme.palette.error.main + ' !important',
    },
    '&:after': {
      borderColor: theme.palette.error.main + ' !important',
    },
  },
  subtitle_text: {
    fontSize: '1.5rem',
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
}));
function Guard({ step, handleNext, isAccount, isLoading }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.guard',
  });
  const id = useSelector((state) => state.pricing.formId);
  const animals = useSelector((state) => state.pricing.animals);
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = useState(null);
  const { control, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { isValid, errors } = formState;
  const onSubmit = (data) => {
    if (isLoading) return;
    setConfirmation(!!data);
    const formattedData = data
      ? {
          ...data,
          firstName: `${data?.firstName
            .charAt(0)
            .toUpperCase()}${data?.firstName.slice(1)}`,
          lastName: `${data?.lastName
            .charAt(0)
            .toUpperCase()}${data?.lastName.slice(1)}`,
        }
      : null;
    dispatch(selectGuard(formattedData));
    if (!formattedData)
      reset({ firstName: '', lastName: '', phone: '' });
    handleNext({ step, id, guard: formattedData });
  };
  const classes = useStyles({ isAccount });
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {isAccount ? null : (
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: 'absolute',
            zIndex: -1,
          }}
          className={'pricing_asset_class'}
        >
          <img
            alt="svg"
            src={pricing_asset_5}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      )}
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12} className={classes.title_container}>
          <Typography
            color={'primary'}
            variant={'h4'}
            className={classes.title_text}
          >
            {t('title1')}
            <br />
            {t('title2')}
            <span style={{ display: 'inline-block' }}>
              {animals.length > 1 ? t('animals') : t('animal')}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginTop: '10px' }}
          alignItems="center"
          display="flex"
        >
          <Typography
            className={classes.subtitle_text}
            color="primary"
          >
            {t('subtitle1')}
            <span
              style={{ display: 'inline-flex', alignItems: 'center' }}
            >
              {t('subtitle2')}
              <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
                <img
                  alt="emoticon"
                  style={{
                    width: '30px',
                    height: '30px',
                    marginLeft: '5px',
                  }}
                  src={pricing_asset_mobile_emoticon}
                />
              </Box>
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.input_container}>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box sx={{ width: { xs: '50px', sm: '60px' } }} />
            <Controller
              control={control}
              name="firstName"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  id="firstName"
                  sx={{ marginBottom: { xs: '10px', sm: '20px' } }}
                  variant="standard"
                  label={t('labelFirstName')}
                  InputLabelProps={{
                    className: errors.firstName
                      ? classes.text_field_label_error
                      : classes.text_field_label,
                  }}
                  InputProps={{
                    className: errors.firstName
                      ? classes.text_field_error
                      : classes.text_field,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box sx={{ width: { xs: '50px', sm: '60px' } }} />
            <Controller
              control={control}
              name="lastName"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  id="lastName"
                  variant="standard"
                  sx={{ marginBottom: { xs: '10px', sm: '20px' } }}
                  InputLabelProps={{
                    className: errors.lastName
                      ? classes.text_field_label_error
                      : classes.text_field_label,
                  }}
                  InputProps={{
                    className: errors.lastName
                      ? classes.text_field_error
                      : classes.text_field,
                  }}
                  label={t('labelLastName')}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box
              component="img"
              src={pricing_asset_phone}
              style={{ marginRight: '20px' }}
              sx={{ width: { xs: '30px', sm: '40px' } }}
            />
            <Controller
              control={control}
              name="phone"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  sx={{ marginBottom: { xs: '10px', sm: '20px' } }}
                  InputLabelProps={{
                    className: errors.phone
                      ? classes.text_field_label_error
                      : classes.text_field_label,
                  }}
                  InputProps={{
                    className: errors.phone
                      ? classes.text_field_error
                      : classes.text_field,
                  }}
                  variant="standard"
                  label={t('labelPhone')}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          direction="column"
          sx={{ marginTop: { xs: '30px', sm: '50px' } }}
        >
          <ButtonCustom
            type={'submit'}
            width={165}
            height={47}
            disabled={!isValid}
          >
            {!isLoading || !confirmation ? (
              t('nextButton')
            ) : (
              <CircularProgress
                sx={{
                  color: theme.palette.secondary.main,
                  display: 'block',
                }}
                thickness={5.0}
                size="25px"
              />
            )}
          </ButtonCustom>
          <Button
            style={{ marginTop: '15px', textDecoration: 'underline' }}
            width={165}
            height={47}
            onClick={() => onSubmit()}
          >
            {!isLoading || confirmation ? (
              t('nextTime')
            ) : (
              <CircularProgress
                sx={{
                  color: theme.palette.primary.main,
                  display: 'block',
                }}
                thickness={5.0}
                size="25px"
              />
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

Guard.componentName = 'stepLabels.responsible';

export default Guard;
