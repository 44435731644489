import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Carousel from 'react-elastic-carousel';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'inherit',
    flexDirection: 'row !important',
    // paddingRight: 10,
    // paddingLeft: 10
  },
}));

const breakPoints = [
  { width: 0, itemsToShow: 1, itemsToScroll: 1 },
  { width: 230, itemsToShow: 2, itemsToScroll: 2 },
  { width: 350, itemsToShow: 3, itemsToScroll: 3 },
  { width: 650, itemsToShow: 4, itemsToScroll: 4 },
];

export default function CustomCarousel({ children, ...props }) {
  const classes = useStyles();

  return (
    <Carousel
      className={classes.root}
      breakPoints={breakPoints}
      pagination={false}
    >
      {children}
    </Carousel>
  );
}
