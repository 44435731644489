import MuiAlert from '@mui/material/Alert';
import MuiSnackbar from '@mui/material/Snackbar';
import React from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  );
});

const Snackbar = ({
  openSnackbar,
  setOpenSnackbar,
  alertStatus,
  snackbarMessage,
}) => {
  const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';

  return (
    <MuiSnackbar
      open={openSnackbar}
      autoHideDuration={10000}
      onClose={() => setOpenSnackbar(false)}
      sx={{
        zIndex: 100,
        paddingTop: isMobileApp
          ? 'calc(48px + env(safe-area-inset-top))'
          : 'unset',
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert severity={alertStatus} color={alertStatus}>
        {snackbarMessage}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
