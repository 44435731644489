import { Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displayErrorMessage } from '../../../providers/snackbar';
import { getErrorTranslation } from '../../../utils/language';
import useQuery from '../../../utils/queryFunctions';
import axiosAPI from '../../axiosApi';
import Loading from '../../molecules/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% + 64px)',
    backgroundColor: 'white',
    marginTop: '-64px',
  },
}));

export default function Feedback() {
  const { t } = useTranslation(null, { keyPrefix: 'feedback' });

  const classes = useStyles();

  let query = useQuery();
  const request_token = query.get('request_token');
  const value = query.get('value');

  const sendVote = async ({ request_token, value }) => {
    try {
      const response = await axiosAPI.get(
        `feedback?request_token=${request_token}&value=${value}`,
      );
      if (response.status === 200) {
        setLoading(false);
      } else {
        displayErrorMessage(getErrorTranslation());
      }
    } catch (e) {
      displayErrorMessage(getErrorTranslation());
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!_.isEmpty(request_token)) {
      setLoading(true);
      sendVote({ request_token, value });
    }
  }, [request_token, value]);

  const [loading, setLoading] = useState(false);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      {loading ? (
        <Stack
          spacing={6}
          sx={{ maxHeight: '100%', alignItems: 'center', p: 2 }}
        >
          <Typography variant={'h3'}>{t('title1')}</Typography>
          <Loading size={60} />
        </Stack>
      ) : (
        <Stack
          spacing={2}
          sx={{ maxHeight: '100%', alignItems: 'center', p: 2 }}
        >
          <Typography variant={'h3'}>{t('title2')}</Typography>
        </Stack>
      )}
    </Grid>
  );
}
