import { Device } from '@capacitor/device';
import { StatusBar, Style } from '@capacitor/status-bar';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import { SnackbarProvider } from 'notistack';
import React, { useContext } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { createGlobalStyle } from 'styled-components';

import Intro from './components/atoms/Intro';
import IntroAlcampo from './components/atoms/IntroAlcampo';
import { LanguageContext } from './providers/context/language';
import useI18nextProvider from './providers/translation/useI18nextProvider';
import Router from './routes';
import store from './store';
import theme from './theme';
import { isAlcampo } from './utils/language';

const OverrideMobileAppGlobalStyle = createGlobalStyle`
  .x-hellosign-embedded {
    margin-top: env(safe-area-inset-top);
    height: calc(100% - env(safe-area-inset-top));
  }
`;

function App() {
  useI18nextProvider();
  const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';
  const isProd = process.env.NODE_ENV === 'production';
  const { language } = useContext(LanguageContext);
  React.useEffect(() => {
    if (!isMobileApp || !isProd) return;
    const fetchData = async () => {
      const deviceInfo = await Device.getInfo();
      const isAndroid = deviceInfo?.platform === 'android';
      if (isAndroid)
        StatusBar.setBackgroundColor({ color: '#f5f5f5' });
      // Common
      StatusBar.setStyle({ style: Style.Light });
    };
    try {
      fetchData();
    } catch (e) {
      console.log('error = ', e);
    }
  }, [isMobileApp, isProd]);

  let persistor = persistStore(store);

  const loader = isAlcampo() ? <IntroAlcampo /> : <Intro />;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={language === 'es' ? esLocale : frLocale}
    >
      <Provider store={store}>
        <PersistGate loading={loader} persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <SnackbarProvider maxSnack={3}>
                  {isMobileApp ? (
                    <OverrideMobileAppGlobalStyle />
                  ) : null}
                  <Router />
                </SnackbarProvider>
              </BrowserRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;
