import { Box, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'use-hooks';

import Footer from '../components/templates/home/Footer';
import Navbar from '../components/templates/home/Navbar';
import image1 from '../static/aboutUs/image1.jpg';
import image2 from '../static/aboutUs/image2.jpg';
import image3 from '../static/aboutUs/image3.png';
import image4 from '../static/aboutUs/image4.png';
import { blue_light } from '../theme';
import theme from '../theme/home';
import { isAlcampo } from '../utils/language';

const refSize = 1920;
const minZoomMobile = 400;
const minZoomDesktop = 1000;
const midZoomDesktop = 1500;
const maxZoomDesktop = 2100;
const zoomDesktop = midZoomDesktop / refSize;

const useStyles = makeStyles((theme) => ({
  zoomHome: {
    height: '100%',
    [theme.breakpoints.between(minZoomMobile, minZoomDesktop)]: {
      zoom: (props) => props.zoom,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': (props) => `scale(${props.zoom})`,
        '-moz-transform-origin': '0 0',
        width: (props) => `${100 * (1 / props.zoom)}vw`,
      },
    },
    [theme.breakpoints.between(minZoomDesktop, midZoomDesktop)]: {
      zoom: (props) => props.zoom,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': (props) => `scale(${props.zoom})`,
        '-moz-transform-origin': '0 0',
        width: (props) => `${100 * (1 / props.zoom)}vw`,
      },
    },
    [theme.breakpoints.between(midZoomDesktop, maxZoomDesktop)]: {
      zoom: zoomDesktop,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': `scale(${zoomDesktop})`,
        '-moz-transform-origin': '0 0',
        width: `${100 * (1 / zoomDesktop)}vw`,
      },
    },
  },
  htmlContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '150px',
    marginRight: '150px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '50px',
      marginRight: '50px',
    },
  },
  typo: {
    zIndex: 100,
    fontSize: 24,
    color: theme.palette.primary.main,
    zoom: 1,
    [theme.breakpoints.down('md')]: {
      zoom: 0.5,
    },
  },
}));

export default function AboutUsLayout(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'layouts.aboutUs',
  });
  const { width } = useWindowSize();
  const zoom =
    width < minZoomDesktop
      ? 1 + (width - minZoomMobile) / 900
      : width / refSize;
  const classes = useStyles({ zoom });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Navbar {...props} />
      <Box className={classes.zoomHome}>
        <section
          style={{
            background: blue_light,
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              paddingTop: '40px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography color="primary" variant="h2">
              {t('title')}
            </Typography>
          </div>
          <section
            className={classes.htmlContent}
            style={{ display: isAlcampo() ? 'none' : 'block' }}
          >
            <Typography variant={'h3'}>
              {t('whoAreWe.title')}
            </Typography>
            <Typography
              className={classes.typo}
              style={{ marginTop: 30 }}
            >
              {t('whoAreWe.text1')}
            </Typography>
            <Typography
              variant={'h3'}
              sx={{ marginTop: { xs: '50px', md: '100px' } }}
            >
              {t('reasonForBeing.title')}
            </Typography>
            <Typography
              className={classes.typo}
              style={{ marginTop: 30 }}
            >
              {t('reasonForBeing.text1')}
              <br />
              {t('reasonForBeing.text2')}
            </Typography>
            <Typography
              variant={'h3'}
              sx={{ marginTop: { xs: '50px', md: '100px' } }}
            >
              {t('weAreDifferent.title')}
            </Typography>
            <Typography
              className={classes.typo}
              style={{ marginTop: 30 }}
            >
              <div style={{ marginLeft: '50px' }}>
                <li>{t('weAreDifferent.text1')}</li>
                <br />
                <li>{t('weAreDifferent.text2')}</li>
                <br />
                <li>{t('weAreDifferent.text3')}</li>
                <br />
                <li>{t('weAreDifferent.text4')}</li>
                <br />
                <li>{t('weAreDifferent.text5')}</li>
              </div>
            </Typography>
            <Typography
              className={classes.typo}
              style={{ marginTop: 100 }}
            >
              {t('whyLaunch.text1')}
            </Typography>
            <Box
              style={{ marginTop: 30 }}
              justifyContent={'center'}
              sx={{ display: { xs: 'block', md: 'flex' } }}
            >
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <img
                  src={image3}
                  style={{
                    width: '600px',
                    height: '600px',
                    marginRight: '50px',
                  }}
                  alt=""
                />
                <img
                  src={image4}
                  style={{ width: '600px', height: '600px' }}
                  alt=""
                />
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <img
                  src={image3}
                  style={{
                    width: '200px',
                    height: '200px',
                    marginRight: '20px',
                  }}
                  alt=""
                />
                <img
                  src={image4}
                  style={{ width: '200px', height: '200px' }}
                  alt=""
                />
              </Box>
            </Box>
            <Typography
              className={classes.typo}
              sx={{ marginTop: { xs: '50px', md: '100px' } }}
            >
              {t('whyLaunch.text2')}
            </Typography>
            <Box
              justifyContent={'center'}
              sx={{
                marginTop: '30px',
                display: { xs: 'block', md: 'flex' },
              }}
            >
              <Box
                sx={{
                  display: { xs: 'none', md: 'block' },
                }}
              >
                <img
                  src={image1}
                  style={{
                    width: '600px',
                    height: '802px',
                    marginRight: '50px',
                  }}
                  alt="dog1"
                />
                <img
                  src={image2}
                  style={{ width: '600px', height: '600px' }}
                  alt="dog2"
                />
              </Box>
              <Box
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <img
                  src={image1}
                  style={{
                    width: '200px',
                    height: '',
                    marginRight: '20px',
                  }}
                  alt="dog3"
                />
                <img
                  src={image2}
                  style={{ width: '200px', height: '' }}
                  alt="dog4"
                />
              </Box>
            </Box>
          </section>
          <section
            className={classes.htmlContent}
            style={{ display: isAlcampo() ? 'block' : 'none' }}
          >
            <Typography
              className={classes.typo}
              style={{ marginTop: 30, whiteSpace: 'pre-line' }}
            >
              {t('whoAreWe.alcampo.text1')}
            </Typography>
          </section>
          <div style={{ width: '100%', paddingBottom: '50px' }}>
            <Footer />
          </div>
        </section>
      </Box>
    </ThemeProvider>
  );
}
