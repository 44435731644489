import { Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';
import Emoji from '../../../atoms/Emoji';
import ItemAnimal from '../ItemAnimal';

const useStyles = makeStyles((theme) => ({
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));

export default function Step(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.step2',
  });
  const classes = useStyles();

  return (
    <Box
      sx={{
        width: '90%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        color={'primary'}
        variant={'h4'}
        className={classes.title_text}
      >
        {!props.isQuote ? (
          <>
            {t('isNotQuote.title1')}
            <span style={{ display: 'inline-block' }}>
              {t('isNotQuote.title2')} <Emoji symbol="👨‍⚕️" />
            </span>
          </>
        ) : (
          <>{t('isQuoteTitle')}</>
        )}
      </Typography>
      <Box
        style={{
          height: '100%',
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        <ItemAnimal
          data={{
            img: 'accident',
            text: t('accident'),
            value: 'accident',
          }}
          key={1}
          onClick={(value) => props.setCare(value)}
          isActive={props.care === 'accident'}
        />
        <ItemAnimal
          data={{
            img: 'malade',
            text: t('illness'),
            value: 'illness',
          }}
          key={2}
          onClick={(value) => props.setCare(value)}
          isActive={props.care === 'illness'}
        />
      </Box>
      <Box
        style={{
          marginBottom: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          color={'primary'}
          sx={{
            marginRight: { xs: '5px', sm: '20px' },
            maxWidth: '356px',
            fontSize: { xs: 16, sm: 20 },
          }}
        >
          {!props.isQuote ? (
            <>
              {t('isNotQuote.subtitle1')}
              <span style={{ display: 'inline-block' }}>
                {t('isNotQuote.subtitle2')}{' '}
              </span>
            </>
          ) : (
            <>
              {t('isQuoteSubtitle.text1')}

              <span style={{ display: 'inline-block' }}>
                {t('isQuoteSubtitle.text2')}
              </span>
            </>
          )}
        </Typography>
        <Switch
          checked={props.isSurgery}
          onChange={(value) => {
            props.setIsSurgery(!props.isSurgery);
          }}
        />
      </Box>
    </Box>
  );
}
