import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useWindowSize } from 'use-hooks';

import cat_image from '../../../static/home/cat-image.jpg';
import transition from '../../../static/home/dog-cat-home-transition.svg';
import dog_image from '../../../static/home/dog-image.jpg';
import bftm_tv from '../../../static/home/logos/bfmtv.svg';
import france_3 from '../../../static/home/logos/france3.svg';
import france_bleu from '../../../static/home/logos/francebleu.svg';
import les_echos from '../../../static/home/logos/lesechos.svg';
import star from '../../../static/home/star.svg';
import Footer from './Footer';
import * as HomePage from './index';

const refSize = 1920;
const minZoomMobile = 400;
const minZoomDesktop = 1000;

const useStyles = makeStyles((theme) => ({
  image: {
    height: 'unset',
    width: '100%',
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
      height: '100vh',
      width: 'unset',
      marginLeft: (props) => `${props.isDog ? '-180' : '-280'}px`,
    },
  },
  homeSection: {
    display: 'flex',
    position: 'relative',
    height: 650,
    overflow: 'hidden',
    zIndex: 0,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 1100,
    },
  },
}));

export default function DogCatHomePage({ isDog }) {
  const { width } = useWindowSize();
  const zoom =
    width < minZoomDesktop
      ? 1 + (width - minZoomMobile) / 900
      : width / refSize;

  const homePageClasses = HomePage.useStyles({ zoom });
  const classes = useStyles({ isDog });
  const mediaLogos = [
    { src: bftm_tv },
    {
      src: france_3,
      xsHeight: '15px',
      ssHeight: '20px',
      mdHeight: '60px',
    },
    { src: france_bleu },
    {
      src: les_echos,
      xsHeight: '17px',
      ssHeight: '22px',
      mdHeight: '60px',
    },
  ];

  return (
    <Box className={homePageClasses.zoomHome}>
      <section id={'section0'} className={classes.homeSection}>
        <Box sx={{ zIndex: -10, backgroundColor: 'white' }}>
          <img
            src={isDog ? dog_image : cat_image}
            className={classes.image}
            alt="dog"
          />
        </Box>

        <div
          className={homePageClasses.homeContent}
          style={{
            marginTop: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              display: { xs: 'none', md: 'block' },
              backgroundColor: '#ffffffba',
              marginTop: { xs: '30px', md: '50px' },
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            La mutuelle santé pour les{' '}
            <span style={{ display: 'inline-block' }}>
              {isDog ? 'chiens' : 'chats'} !
            </span>
          </Typography>
          <Typography
            variant="h1"
            sx={{
              display: { xs: 'block', md: 'none' },
              backgroundColor: '#ffffffba',
              marginTop: { xs: '30px', md: '50px' },
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            La mutuelle santé
          </Typography>
          <Typography
            variant="h1"
            sx={{
              backgroundColor: '#ffffffba',
              margin: 0,
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            pour les{' '}
            <span style={{ display: 'inline-block' }}>
              {isDog ? 'chiens' : 'chats'} !
            </span>
          </Typography>
          <div style={{ marginTop: 50 }}>{HomePage.consultPrice}</div>
        </div>
      </section>
      <section
        id={'homeSectionVideo'}
        className={homePageClasses.homeSectionVideo1}
        style={{ backgroundColor: '#ffffff' }}
      >
        <Typography
          variant={'h3'}
          sx={{ marginTop: { xs: '40px', md: '30px' } }}
        >
          Ils parlent de nous
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingTop: '15px',
            paddingBottom: '15px',
            mt: { xs: '15px', md: '30px' },
            mb: { xs: '0px', md: '90px' },
          }}
        >
          {mediaLogos.map((logo) => (
            <Box
              sx={{
                height: {
                  xs: logo.xsHeight || '30px',
                  ss: logo.ssHeight || '40px',
                  md: logo.mdHeight || '100px',
                },
                marginRight: {
                  xs: '12px',
                  ss: '15px',
                  sm: '25px',
                  md: '80px',
                },
                marginLeft: {
                  xs: '12px',
                  ss: '15px',
                  sm: '25px',
                  md: '80px',
                },
              }}
            >
              <img className={'logo-item'} src={logo.src} alt="" />
            </Box>
          ))}
        </Box>
        <Typography
          variant={'h3'}
          style={{ marginTop: 30, lineHeight: '60px' }}
        >
          Vous allez nous aimer !
        </Typography>
        <Typography
          variant={'h5'}
          sx={{
            lineHeight: { xs: undefined, md: '40px' },
            marginBottom: '20px',
          }}
        >
          Drooky a une super note sur Google
          <br />
          Parce que nous gérons tout nous-mêmes !
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '40px',
          }}
        >
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
              marginRight: { xs: '10px', md: '20px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
              marginRight: { xs: '10px', md: '20px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
              marginRight: { xs: '10px', md: '20px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
              marginRight: { xs: '10px', md: '20px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '50px', md: '150px' },
              height: { xs: '50px', md: '150px' },
            }}
          >
            <img
              alt="svg"
              src={star}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </Box>
        <Box style={{ position: 'relative' }}>
          <iframe
            className={homePageClasses.videoDrooky}
            src="https://www.youtube-nocookie.com/embed/dq0odpv70-Q"
            title="Drooky song"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
            allowFullScreen
          ></iframe>
        </Box>
      </section>
      <div style={{ marginBottom: '-8px' }}>
        <img src={transition} alt="transition" />
      </div>
      <Footer />
    </Box>
  );
}
