import { loadStripe as _loadStripe } from '@stripe/stripe-js';
import pRetry from 'p-retry';

export const loadStripe = (publishableKey, options) =>
  pRetry(() => _loadStripe(publishableKey, options), options);

export const loadStripeWakam = async () =>
  await loadStripe(process.env.REACT_APP_STRIPE_API_KEY_WAKAM, {
    onFailedAttempt: (error) => {
      console.log(
        `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`,
      );
    },
    retries: 5,
  });
export const loadStripeOney = async () =>
  await loadStripe(process.env.REACT_APP_STRIPE_API_KEY_ONEY, {
    onFailedAttempt: (error) => {
      console.log(
        `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`,
      );
    },
    retries: 5,
  });

export const methods = {
  loadStripeWakam,
  loadStripeOney,
};

export default methods;
