import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';

import cat from '../../../../static/cat.svg';
import dog from '../../../../static/dog.svg';
import { blue } from '../../../../theme';

const useStyles = makeStyles((theme) => ({
  buttons: {
    justifyContent: 'space-around',
    display: 'flex',
    marginTop: '50px',
    marginBottom: '50px',
  },
  content: {
    height: '100%',
    color: blue,
    '& li': {
      listStyle: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '4px',
      marginTop: '4px',
      alignItems: 'center',
    },
    '& ul': {
      padding: 0,
    },
  },
  title: {
    color: blue,
    margin: 0,
  },
  typo: {
    alignSelf: 'center',
    wordBreak: 'break-word',
  },
  img: {
    height: '50px',
    width: '70px',
  },
  button: {
    width: '118px',
  },
}));

export default function MobileGaranties(props) {
  const classes = useStyles();
  const { contracts, confirmCancel, confirmResiliate, t } = props;

  const TYPE = {
    health: t('health'),
    prev: t('prev'),
    guard: t('guard'),
  };

  return (
    <>
      <h2 className={classes.title}>{t('title')}</h2>
      <div className={classes.content}>
        <ul>
          {contracts.items.map((item, index) => (
            <li
              style={{
                borderBottom: '1px solid #EAF2F8',
                paddingBottom: '5px',
                paddingTop: '5px',
              }}
            >
              <img
                className={classes.img}
                src={item.animal.breed.species === 'dog' ? dog : cat}
                alt={item.animal.name}
              ></img>
              <Typography
                variant={'h4'}
                color={'primary'}
                className={classes.typo}
              >
                {item.animal.name} ({TYPE[item.contractItemType]})
                <br />
                {Number.isInteger(item.price)
                  ? item.price
                  : item.price.toFixed(2)}
                €
                {item.resiliated_at && (
                  <Typography variant={'h6'} color="gray">
                    {t('end')}
                    {new Date(
                      item.resiliated_at,
                    ).toLocaleDateString()}
                  </Typography>
                )}
              </Typography>
              {item.resiliated_at ? (
                new Date(item.resiliated_at) <
                new Date(Date.now() - 864e5) ? (
                  <Typography
                    style={{ width: '118px' }}
                    variant={'h6'}
                    color="gray"
                  >
                    {t('endedOn')}&nbsp;
                    {new Date(
                      item.resiliated_at,
                    ).toLocaleDateString()}
                  </Typography>
                ) : (
                  <div style={{ width: '90px' }}>
                    <Button
                      variant={'contained'}
                      style={{
                        width: 'unset',
                        height: 'unset',
                        padding: '6px 10px',
                        clipPath:
                          'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
                        borderRadius: 0,
                      }}
                      onClick={() =>
                        confirmCancel(item.id, item.resiliated_at)
                      }
                    >
                      {t('cancelButton')}
                    </Button>
                  </div>
                )
              ) : (
                <div>
                  <Button
                    variant={'contained'}
                    style={{
                      width: 'unset',
                      height: 'unset',
                      padding: '6px 10px',
                      clipPath:
                        'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
                      borderRadius: 0,
                    }}
                    onClick={() => confirmResiliate(item.id)}
                  >
                    {t('resiliateButton')}
                  </Button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
