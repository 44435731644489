// import { Device } from '@capacitor/device';
import i18next from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const FALLBACK_LOCALE = process.env.REACT_APP_LANGUAGE;
// const DETECTION_OPTIONS = {
// order: ['localStorage'],
// caches: ['localStorage'],
// };

const i18nWebProvider = (resources) =>
  i18next
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      // detection: DETECTION_OPTIONS,
      fallbackLng: FALLBACK_LOCALE,
      debug: false,
      react: {
        useSuspense: false,
      },
      interpolation: {
        escapeValue: false,
      },
    });
// const i18nMobileProvider = (resources) =>
//   Device.getLanguageCode()
//     .then((res) => {
//       return i18next.use(initReactI18next).init({
//         resources,
//         fallbackLng:
//           ['fr', 'es'].indexOf(res?.value) !== -1
//             ? res?.value
//             : FALLBACK_LOCALE,
//         debug: false,
//         react: {
//           useSuspense: false,
//         },
//         interpolation: {
//           escapeValue: false,
//         },
//       });
//     })
//     .catch((e) =>
//       i18next.use(initReactI18next).init({
//         resources,
//         fallbackLng: FALLBACK_LOCALE,
//         debug: false,
//         react: {
//           useSuspense: false,
//         },
//         interpolation: {
//           escapeValue: false,
//         },
//       }),
//     );
export default function createI18next(resources) {
  // if (process.env.REACT_APP_IS_MOBILE_APP === 'true') {
  //   return i18nMobileProvider(resources);
  // }
  return i18nWebProvider(resources);
}
