import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';

const useStyles = makeStyles((theme) => ({
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));

export default function Step(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.step3',
  });
  const classes = useStyles();

  const handleChange = (event) =>
    props.setCost(parseFloat(event.target.value));

  return (
    <Box
      style={{
        width: '90%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        color={'primary'}
        variant={'h4'}
        className={classes.title_text}
      >
        {!props.isQuote ? (
          <>
            {t('isNotQuote.title1')}
            <span style={{ display: 'inline-block' }}>
              {t('isNotQuote.title2')}
            </span>
          </>
        ) : (
          <>
            {t('isQuote.title1')}
            <span style={{ display: 'inline-block' }}>
              {t('isQuote.title2')}
            </span>
          </>
        )}
      </Typography>
      <TextField
        id="outlined-number"
        onChange={handleChange}
        label={t('labelAmount')}
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              € {t('TTC')}
            </InputAdornment>
          ),
        }}
        sx={{ display: 'flex', width: '250px', margin: 'auto' }}
      />
    </Box>
  );
}
