import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LanguageContext } from '../../../../providers/context/language';
import { displayErrorMessage } from '../../../../providers/snackbar';
import pricing_asset_mobile_2 from '../../../../static/pricing/pricing-asset-mobile-2.svg';
import { getErrorTranslation } from '../../../../utils/language';
import axiosAPI from '../../../axiosApi';
import MultipleAnimal from '../animalBreed/MultipleAnimal';
import SingleAnimal from '../animalBreed/SingleAnimal';
import { GUARD, MULTIPLE } from '../constants';
import { selectAnimals } from '../pricingSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    paddingTop: (props) => (props.isAccount ? 0 : 80),
    paddingRight: 10,
    paddingLeft: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: (props) => (props.isAccount ? 0 : 20),
      backgroundColor: 'inherit',
    },
    paddingBottom: 10,
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

function AnimalBreed({ handleNext, step, isAccount, isLoading }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalBreed',
  });

  const classes = useStyles({ isAccount });
  const dispatch = useDispatch();
  const pricing = useSelector((state) => state.pricing);

  const { formId, animals, animalType } = pricing;
  const animal = _.first(animals) || {};

  const animalName = animal?.name
    ? `${animal.name.charAt(0).toUpperCase()}${animal.name.slice(1)}`
    : '';

  const multipleTemplate =
    (animalType === MULTIPLE || animalType === GUARD) &&
    animals?.length > 1;

  const handleNextStep = (animals) => {
    if (isLoading) return;
    dispatch(selectAnimals(animals));
    handleNext({ step, id: formId, animals });
  };

  const [breedList, setBreedList] = useState();
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosAPI.get(
          `breeds/?language=${language}&offer=${process.env.REACT_APP_OFFER}`,
        );
        setBreedList(response.data);
      } catch (e) {
        displayErrorMessage(getErrorTranslation(e));
      }
    }
    fetchData();
  }, [animal.species, language]);

  return (
    <section className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.title_container}>
          <Typography
            color={'primary'}
            variant={'h4'}
            className={classes.title_text}
          >
            {!multipleTemplate ? (
              <>
                {t('title1')}
                <span style={{ display: 'inline-block' }}>
                  {t('title2', { animalName })}
                </span>
              </>
            ) : (
              <>
                {t('multiple.title1')}
                <span style={{ display: 'inline-block' }}>
                  {t('multiple.title2')}
                </span>
              </>
            )}
          </Typography>
        </Grid>
        {multipleTemplate ? (
          <MultipleAnimal
            handleNextStep={handleNextStep}
            isLoading={isLoading}
            breedList={breedList}
          />
        ) : (
          <SingleAnimal
            handleNextStep={handleNextStep}
            isLoading={isLoading}
            breedList={breedList}
          />
        )}
      </Grid>
    </section>
  );
}

AnimalBreed.componentName = 'stepLabels.breed';

export default AnimalBreed;
