import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { Outlet } from 'react-router-dom';

import Navbar from '../components/templates/pricing/Navbar';

export default function SigningLayout(props) {
  const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';

  return !isMobileApp ? (
    <Navbar {...props}>
      <section style={{ height: '100vh', display: 'flex' }}>
        <Outlet />
      </section>
    </Navbar>
  ) : (
    <>
      <CssBaseline />
      <section
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#ffffff',
        }}
      >
        <Outlet />
      </section>
    </>
  );
}
