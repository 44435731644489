import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import theme from '../../../../theme';
import ButtonCustom from '../../../atoms/Button';
import {
  GUARD,
  MIN_ANIMAL_BIRTHDAY_MONTHS,
  MULTIPLE,
} from '../constants';
import { selectAnimals } from '../pricingSlice';
import MultipleAnimal from './MultipleAnimal';
import SingleAnimal from './SingleAnimal';
import { calculateMaxAnimalAge } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: (props) => (props.isAccount ? 0 : 60),
    [theme.breakpoints.down('sm')]: {
      paddingTop: (props) => (props.isAccount ? 0 : 10),
    },
  },
  text_root: {
    maxWidth: 700,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.2rem',
    },
  },
  text_root_sub: {
    marginTop: 20,
    maxWidth: 700,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1rem',
    },
  },
  text_1: {
    marginBottom: 40,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 20,
      fontSize: '1.8rem',
    },
  },
  img: {
    maxHeight: '30vh',
  },
  date: {
    justifyContent: 'center',
  },
  formWrapper: {
    margin: theme.spacing(3),
    marginTop: 0,
  },
}));

let hasResetValues = true;

const isWakam = process.env.REACT_APP_PROVIDER === 'wakam';

function AnimalBirthday({
  provider,
  handleNext,
  step,
  activeStep,
  isAccount,
  isLoading,
}) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalBirthday',
  });

  const pricing = useSelector((state) => state.pricing);
  const { formId, firstName, animals, animalType } = pricing;

  const dispatch = useDispatch();

  const schemaYup = animals.reduce((dict, a, index) => {
    dict[`animalBirthday_${index}`] = yup
      .date()
      .required()
      .max(new Date())
      .min(new Date('1950-01-01'));
    return dict;
  }, {});

  const schema = yup.object().shape(schemaYup);

  const { control, handleSubmit, register, getValues, reset } =
    useForm({
      resolver: yupResolver(schema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    });
  if (!hasResetValues && activeStep === 2) {
    hasResetValues = true;
    const values = getValues();
    let resetValues = {};
    for (const property in values) {
      resetValues[property] = null;
    }
    reset(resetValues);
  } else {
    hasResetValues = false;
  }
  const isValid = !animals.length
    ? false
    : !animals
        .map((animal) => {
          const birthday = new Date(animal?.birthday);
          const maxDate = new Date();
          const minDate = isWakam
            ? new Date()
            : new Date('1980-01-01');
          if (isWakam) {
            const maxAnimalBirthdayMonths =
              calculateMaxAnimalAge(animal);
            maxDate.setMonth(
              maxDate.getMonth() - MIN_ANIMAL_BIRTHDAY_MONTHS,
              1,
            );
            minDate.setMonth(
              minDate.getMonth() - maxAnimalBirthdayMonths,
              1,
            );
            minDate.setHours(0, 0, 0, 0);
          }
          return (
            birthday instanceof Date &&
            birthday >= minDate &&
            birthday <= maxDate
          );
        })
        .includes(false);
  const onSubmit = (data) => {
    if (isLoading) return;
    const animalValues = animals.map((an, index) => ({
      ...an,
      birthday: data[`animalBirthday_${index}`]
        .toISOString()
        .slice(0, 10),
    }));
    dispatch(selectAnimals(animalValues));
    handleNext({ step, id: formId, animals: animalValues });
  };

  const classes = useStyles({ isAccount });
  const multipleTemplate =
    (animalType === MULTIPLE || animalType === GUARD) &&
    animals?.length > 1;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        className={classes.root}
        spacing={0}
      >
        {multipleTemplate ? (
          <MultipleAnimal
            firstName={firstName}
            animals={animals}
            control={control}
            register={register}
            provider={provider}
            t={t}
          />
        ) : (
          <SingleAnimal
            firstName={firstName}
            animals={animals}
            control={control}
            register={register}
            provider={provider}
            t={t}
          />
        )}
        <Grid
          item
          xs={12}
          sx={{
            marginTop: { xs: '30px', sm: '50px' },
            marginBottom: '20px',
          }}
        >
          <ButtonCustom
            type="submit"
            width={122}
            height={47}
            disabled={!isValid}
          >
            {!isLoading ? (
              t('nextButton')
            ) : (
              <CircularProgress
                sx={{
                  color: theme.palette.secondary.main,
                  display: 'block',
                }}
                thickness={5.0}
                size="25px"
              />
            )}
          </ButtonCustom>
        </Grid>
      </Grid>
    </form>
  );
}

AnimalBirthday.componentName = 'stepLabels.birthday';

export default AnimalBirthday;
