import { Paper, Typography } from '@mui/material';
import React from 'react';

export const ImageRadio = ({
  src,
  alt,
  classes,
  isChecked,
  label,
}) => {
  return (
    <div
      className={isChecked ? classes.checked : ''}
      style={{ width: '100%', height: '100%' }}
    >
      <Paper elevation={0} className={classes.paper}>
        <img className={classes.img} src={src} alt={alt} />
        <Typography
          style={{ display: label?.length ? 'block' : 'none' }}
          variant={'h4'}
          color={'primary'}
          className={classes.typo}
        >
          {label}
        </Typography>
      </Paper>
    </div>
  );
};
