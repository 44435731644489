import { Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MobileDatePicker } from '@mui/x-date-pickers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isAlcampo } from '../../../../utils/language';
import { MIN_ANIMAL_BIRTHDAY_MONTHS } from '../constants';
import { calculateMaxAnimalAge } from './utils';

const useStyles = makeStyles((theme) => ({
  date_picker_container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 25,
    },
  },
  date_picker: {
    width: 270,
    color: theme.palette.primary.main,
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '& input': {
      cursor: 'pointer',
    },
  },
  text_field_label: {
    color: theme.palette.primary.main,
  },
}));

export default function InputWithIcon(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalBirthday',
  });
  const { label, src, field, iconClass, animal } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const onDateChanged = (date) => {
    if (
      !(date instanceof Date) ||
      isNaN(date) ||
      date < new Date('1980-01-01') ||
      date > new Date()
    )
      return;
    field.onChange(date);
  };

  const maxDate = new Date();
  const minDate = new Date();
  if (animal) {
    if (props.provider === 'wakam') {
      const maxAnimalBirthdayMonths = calculateMaxAnimalAge(animal);
      minDate.setMonth(
        minDate.getMonth() - maxAnimalBirthdayMonths,
        1,
      );
    } else {
      minDate.setMonth(
        minDate.getMonth() - (isAlcampo() ? 72 : 95),
        1,
      );
    }
    maxDate.setMonth(
      maxDate.getMonth() - MIN_ANIMAL_BIRTHDAY_MONTHS,
      1,
    );
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: src ? 'block' : 'none' }}
        className={iconClass}
      >
        <img
          style={{ width: '100%', height: '100%' }}
          src={src}
          alt={'img'}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        className={classes.date_picker_container}
      >
        <MobileDatePicker
          views={['year', 'month']}
          slots={{
            textField: (props) => (
              <TextField
                {...props}
                InputLabelProps={{
                  className: classes.text_field_label,
                }}
                label={label}
              />
            ),
          }}
          onOpen={() => setIsOpen(true)}
          cancelText={t('cancel')}
          open={isOpen}
          okText={'Ok'}
          onAccept={() => setIsOpen(false)}
          onClose={() => setIsOpen(false)}
          onMonthChange={(date) => {
            onDateChanged(date);
            setIsOpen(false);
          }}
          onYearChange={(date) => onDateChanged(date)}
          onChange={(date) => onDateChanged(date)}
          showToolbar={false}
          minDate={minDate}
          maxDate={maxDate}
          {...field}
          InputProps={{ className: classes.date_picker }}
        />
      </Grid>
    </Grid>
  );
}
